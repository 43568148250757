import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';

import { DataNode, TreeProps } from 'gazprom-ui-lib';

import { DeclarationParamType, ProcessParamType } from 'types/documents.types';

import { ProcessParamMapType } from '../../tabParameters.utils';
import LeafRenderer from '../leaf-renderer';
import ParentRenderer from '../parent-renderer';

interface Props {
  disabled?: boolean;
  processParameters: ProcessParamType[];
  defaultParams: DeclarationParamType[] | undefined;
  setParamValues: Dispatch<SetStateAction<Map<string, ProcessParamMapType>>>;
}

const useTreeData = (props: Props) => {
  const { processParameters, defaultParams, setParamValues, disabled } = props;

  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);

  useEffect(() => {
    if (defaultParams) {
      setCheckedKeys(
        (defaultParams ?? [])
          .map((el) => {
            return String(el.value) === 'true' ? el.code : null;
          })
          .filter((value): value is string => typeof value === 'string'),
      );
    }
  }, [defaultParams]);

  useEffect(() => {
    // заполняем дефолтными значениями из данных процесса
    if (!defaultParams && processParameters) {
      const processParamMap = new Map<string, ProcessParamMapType>();

      traverse(processParameters, processParamMap);
      setParamValues(processParamMap);
    }
  }, [defaultParams, processParameters, setParamValues]);

  useEffect(() => {
    // снять галки у детей, если сняли у родителя
    setParamValues((prevParamValues) => {
      const updatedParamValues = new Map<string, ProcessParamMapType>(prevParamValues);

      updatedParamValues.forEach((param) => {
        const key = param.code;
        const type = param.type;

        if (type === 'BOOL') {
          param.value = checkedKeys.includes(key) ? 'true' : 'false';
        }

        updatedParamValues.set(key, param);
      });

      return updatedParamValues;
    });
  }, [checkedKeys, setParamValues]);

  const handleChangeTree: TreeProps['onCheck'] = (_, { node }) => {
    const nodeKey = node.key as string;

    let updatedCheckedKeys: string[];
    if (node.checked) {
      const descendantKeys = getDescendantKeys(node);
      updatedCheckedKeys = checkedKeys.filter((key) => !descendantKeys.includes(key as string));
    } else {
      updatedCheckedKeys = [...checkedKeys, nodeKey];
    }

    setCheckedKeys(updatedCheckedKeys);
  };

  const renderTree = useCallback(
    (nodes: ProcessParamType[], isLeaf: boolean = false): DataNode[] =>
      nodes?.map((node) => {
        const { isStage, code, name, type, children } = node;

        const title = isLeaf ? (
          <LeafRenderer title={name} type={type} name={code} disabled={disabled} />
        ) : (
          <ParentRenderer
            name={code}
            title={name}
            isStage={isStage}
            type={type}
            disabled={disabled}
          />
        );

        return {
          title: title,
          key: code,
          children: children ? renderTree(children, true) : [],
          isLeaf,
          checkable: type === 'BOOL',
          type,
        };
      }),
    [disabled],
  );

  const treeData = useMemo(() => renderTree(processParameters), [processParameters, renderTree]);

  return { checkedKeys, treeData, handleChangeTree };
};

const getDescendantKeys = (node: DataNode): string[] => {
  const keys: string[] = [];
  const traverse = (n: DataNode) => {
    keys.push(n.key as string);
    n.children?.forEach((child) => traverse(child));
  };
  traverse(node);
  return keys;
};

const traverse = (
  paramArray: ProcessParamType[],
  mapInstance: Map<string, ProcessParamMapType>,
) => {
  paramArray.forEach((param) => {
    const { code, type } = param;
    const value = type === 'STRING' ? null : 'false';

    mapInstance.set(param.code, {
      code,
      type,
      value: value,
      numberOfDays: 0,
    });

    if (param.children && param.children.length > 0) {
      traverse(param.children, mapInstance);
    }
  });
};
export default useTreeData;
