import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import { COMMUNITY_MEETS_FORM_NAME } from '../../communityMeetForm.utils';

interface Props {
  isCreatingOrUpdating: boolean;
}

const MeetsFormActions = (props: Props) => {
  const { isCreatingOrUpdating } = props;
  const navigate = useNavigate();
  const handleClose = () => navigate(-1);

  return (
    <Flex gap="8">
      <Button htmlType="button" type="secondary" onClick={handleClose} fullWidth>
        {t('common_close')}
      </Button>
      <Button
        leftIcon="ok"
        fullWidth
        type="primary"
        htmlType="submit"
        form={COMMUNITY_MEETS_FORM_NAME}
        loading={isCreatingOrUpdating}>
        {t('common_save')}
      </Button>
    </Flex>
  );
};

export default MeetsFormActions;
