import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import { Progress, Typography } from 'gazprom-ui-lib';

import UniversalLoader from 'components/universal-loader';

import s from './ImageModalUploadLoader.module.scss';

const ImageModalUploadLoader = () => {
  const [percent, setPercent] = useState<number>(0);
  useEffect(() => {
    setInterval(() => {
      setPercent((prevState: number) => {
        if (prevState < 90) {
          return prevState + 10;
        }

        return prevState;
      });
    }, 400);
  }, []);

  return (
    <div className={s.wrapper}>
      <UniversalLoader className={s.loader} />
      <div>
        <Typography.Text size="14" type="primary" weight="500">
          {t('common_loading')}
        </Typography.Text>
        <Progress percent={percent} showInfo={true} className={s.progress} />
      </div>
    </div>
  );
};

export default ImageModalUploadLoader;
