import { memo, useCallback, useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import { useGetGuideCategoriesQuery } from 'services/settlement/settlementApiService';
import { GetGuideCategoriesResponseType } from 'services/settlement/settlementApiService.types';

import { Button, Modal, Select, Typography } from 'gazprom-ui-lib';

import s from './GuideHandbookDetailsInstructionsCategoriesModal.module.scss';

interface Props {
  handleClose: () => void;
  handleSubmit: (category: { value: string; label: string; withEtaps: boolean }) => void;
  open: boolean;
  alreadySelectedHandbookCategories?: string[];
}

const GuideHandbookDetailsInstructionsCategoriesModal = (props: Props) => {
  const { open, handleClose, handleSubmit, alreadySelectedHandbookCategories } = props;

  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const selectFromResult = useCallback(
    ({ data, ...other }: { data?: GetGuideCategoriesResponseType }) => ({
      data:
        data?.items?.map(({ name, id, withEtaps }) => ({
          value: id,
          label: name,
          withEtaps,
          disabled: alreadySelectedHandbookCategories?.includes(id),
        })) ?? [],
      ...other,
    }),
    [alreadySelectedHandbookCategories],
  );

  const { data } = useGetGuideCategoriesQuery(
    { size: 500, page: 1 },
    { selectFromResult, skip: !alreadySelectedHandbookCategories },
  );

  const onSubmit = () => {
    handleSubmit(
      data.find((option) => option.value === selectedCategory) ?? {
        value: '',
        label: '',
        withEtaps: false,
      },
    );
    handleClose();
  };

  useEffect(() => {
    if (!open) {
      setSelectedCategory('');
    }
  }, [open]);

  return (
    <Modal open={open} onCancel={handleClose} width={420} closable>
      <Typography.Title level={4}>{t('guide_handbook_select_category')}</Typography.Title>
      <div className={s.selectWrapper}>
        <Typography.Text size="14" weight="500" type="primary">
          {t('common_category')}
        </Typography.Text>
        <Select
          disabled={!alreadySelectedHandbookCategories}
          size="large"
          options={data}
          value={selectedCategory}
          onChange={setSelectedCategory}
          placeholder={t('common_select')}
        />
      </div>
      <div className={s.actions}>
        <Button fullWidth leftIcon="ok" disabled={!selectedCategory} onClick={onSubmit}>
          {t('common_confirm')}
        </Button>
        <Button fullWidth ghost onClick={handleClose}>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(GuideHandbookDetailsInstructionsCategoriesModal);
