import { useState } from 'react';

import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { formRules } from 'utils/formRules';

import { Flex, Form, Typography } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';

import { COMMUNITY_MANAGEMENT_FORM_ENUM } from '../communityManagementForm.utils';

type CommunityManagementFormMediaProps = {
  fieldName: COMMUNITY_MANAGEMENT_FORM_ENUM.AVATAR | COMMUNITY_MANAGEMENT_FORM_ENUM.COVER | string;
  label: string;
};

const CommunityManagementFormMedia = ({ fieldName, label }: CommunityManagementFormMediaProps) => {
  const form = Form.useFormInstance();
  const media: string = Form.useWatch(fieldName);

  const [showUploadModal, setShowUploadModal] = useState(false);
  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };
  const onImgUpload = (url: string) => {
    form.setFieldValue(fieldName, url);
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([fieldName]);
  };

  return (
    <Flex vertical gap="8">
      <Typography.Text weight="500" size="16">
        {label}
      </Typography.Text>

      <Form.Item rules={[formRules.required]} name={fieldName}>
        {media ? (
          <ImgPreview type="image" url={createMediaDownloadUrl(media)} resetImage={resetImage} />
        ) : (
          <UploadImitation handleClick={handleModalOpenClose} />
        )}
      </Form.Item>
      <ImageModal isOpen={showUploadModal} onSubmit={onImgUpload} onClose={handleModalOpenClose} />
    </Flex>
  );
};

export default CommunityManagementFormMedia;
