import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiService from 'services/1c/cApiService';
import { createKedoArchiveDocUrl } from 'services/kedo-archive-doc/utils';

import {
  EditKedoArchiveDocPropsType,
  EditKedoArchiveDocResponseType,
  GenerateKedoArchiveXmlPropsType,
  GenerateKedoArchiveXmlResponseType,
  GetKedoArchiveDocsPropsType,
  GetKedoArchiveDocsResponseType,
} from './kedoArchiveDocApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

const kedoArchiveDocApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getKedoArchiveDocs: builder.query<GetKedoArchiveDocsResponseType, GetKedoArchiveDocsPropsType>({
      query: (props) => ({
        url: createKedoArchiveDocUrl(`${prepareQueryParams(props)}`),
      }),
      providesTags: [C_SERVICE_TAGS.GET_KEDO_ARCHIVE_DOCS],
    }),
    editKedoArchiveDoc: builder.mutation<
      EditKedoArchiveDocResponseType,
      EditKedoArchiveDocPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createKedoArchiveDocUrl(`/${id}`),
        method: 'PUT',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_KEDO_ARCHIVE_DOCS],
    }),
    generateXmlDoc: builder.mutation<
      GenerateKedoArchiveXmlResponseType,
      GenerateKedoArchiveXmlPropsType
    >({
      query: (body) => ({
        url: createKedoArchiveDocUrl('/generate/xml'),
        method: 'POST',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_KEDO_ARCHIVE_DOCS],
    }),
  }),
});

export const {
  useGetKedoArchiveDocsQuery,
  useEditKedoArchiveDocMutation,
  useGenerateXmlDocMutation,
} = kedoArchiveDocApiService;
