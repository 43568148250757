import { t } from 'tools/i18n';

import { Button, ModalDefault, Typography } from 'gazprom-ui-lib';

import { transformCN } from 'pages/settings/settings-certification-authorities/settingsCertificationAuthorities.utils';

import { AuthorityType } from 'types/authority.types';

interface Props {
  open: boolean;
  handleCloseModal: () => void;
  data?: AuthorityType;
  message?: string;
}

const ModalSuccess = (props: Props) => {
  const { open, data, handleCloseModal, message } = props;

  return (
    <ModalDefault open={open} onCancel={handleCloseModal} width={520} closable>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('certification_authorities_has_access')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('certification_authorities_has_access_description', {
          name: transformCN(data?.subject),
        })}
        {message && (
          <Typography.Paragraph size="14" type="danger">
            {message}
          </Typography.Paragraph>
        )}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button ghost fullWidth onClick={handleCloseModal}>
          {t('common_close')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalSuccess;
