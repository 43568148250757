import { useDebounce } from 'utils/useDebounce.hook';

import {
  useGetCommunityInvitesQuery,
  useGetCommunitySubscribersQuery,
} from 'services/communities/communitiesApiService';
import { GetCommunitySubscribersPropsType } from 'services/communities/communityApiService.types';

import { SORT_ENUM } from 'types/sort.types';

import {
  FormResponseType,
  SharedQueryParamsType,
} from '../../communityManagementSubscribers.utils';
import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';

type UseGetTabDataProps = {
  tabType: CommunityManagementSubscribersEnumType;
  requestBody: FormResponseType;
  communityId: string;
};

type TabDefaultsValue = {
  defaultFilters: GetCommunitySubscribersPropsType['params'];
  defaultSort: { order: SORT_ENUM; property: string };
};

// * Дефолтные фильтры для табов
const TAB_DEFAULTS_MAP: Record<CommunityManagementSubscribersEnumType, TabDefaultsValue> = {
  SUBSCRIBERS: {
    defaultFilters: { isBlocked: false },
    defaultSort: {
      order: SORT_ENUM.DESC,
      property: 'updatedAt',
    },
  },
  BLOCKED: {
    defaultFilters: { isBlocked: true },
    defaultSort: {
      order: SORT_ENUM.DESC,
      property: 'updatedAt',
    },
  },
  INVITES: {
    defaultFilters: {},
    defaultSort: {
      order: SORT_ENUM.DESC,
      property: 'sendDate',
    },
  },
};

// * Получаем нужный RTK-query хук для таба
export const useGetTabDataQueryHook = ({
  tabType,
}: {
  tabType: CommunityManagementSubscribersEnumType;
}) => {
  if (tabType === 'SUBSCRIBERS' || tabType === 'BLOCKED') {
    return useGetCommunitySubscribersQuery;
  } else {
    return useGetCommunityInvitesQuery;
  }
};

export const useGetTabTableData = ({ tabType, requestBody, communityId }: UseGetTabDataProps) => {
  const { defaultFilters, defaultSort } = TAB_DEFAULTS_MAP[tabType];
  const useGetTabTableDataQuery = useGetTabDataQueryHook({ tabType });

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);
  const { search, property, order, filters, ...clearedRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: SharedQueryParamsType = {
    ...clearedRequestBody,
    ...(search && { search }),
    ...{ sort: [{ order: defaultSort.order, property: defaultSort.property }] },
    ...(property && order && { sort: [{ order, property }] }),
    ...defaultFilters,
    ...filters,
  };

  const {
    data: { items: dataSource = [], totalCount = 0 } = {},
    isLoading,
    isUninitialized,
    isFetching,
  } = useGetTabTableDataQuery(
    {
      id: communityId,
      params: actualRequestBody,
    },
    { skip: !actualRequestBody?.page },
  );

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!actualRequestBody?.search?.trim();

  return {
    isInitialLoading,
    loading,
    dataSource,
    total: totalCount,
    hasFiltersApplied,
  };
};
