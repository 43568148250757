import { memo } from 'react';

import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useSocialRequestCorrection1cMutation } from 'services/1c/cApiService';

import { Button, Form, Icon, Modal, TextArea, Typography, handleRequest } from 'gazprom-ui-lib';

import s from '../../Actions.module.scss';

interface Props {
  open: boolean;
  onCancel: () => void;
}

const RevisionModal = (props: Props) => {
  const { open, onCancel } = props;
  const params = useParams<{ id: string }>();

  const [sendToCorrection, { isLoading }] = useSocialRequestCorrection1cMutation();

  const [form] = Form.useForm();

  const onSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const handleCorrection = (formResponse: { comment: string }) => {
    if (params.id) {
      const correctionProps = { taskId: params.id, comment: formResponse.comment };

      sendToCorrection(correctionProps).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const submitDisabled: boolean = !Form.useWatch('comment', form);

  return (
    <Modal open={open} onCancel={onCancel} closable>
      <Form form={form} className={s.modalWrapper} onFinish={handleCorrection} onReset={onCancel}>
        <div className={cn(s.iconWrapper, s.warning)}>
          <Icon name="return" />
        </div>
        <Typography.Title level={3}>
          {t('social_program_return_to_revision_title')}
        </Typography.Title>
        <Typography.Title level={5} className={s.description} strong={false} type="secondary">
          {t('social_program_return_to_revision_description')}
        </Typography.Title>
        <Form.Item
          name="comment"
          rules={[formRules.required]}
          normalize={(e: string) => e.trimStart()}>
          <TextArea placeholder={t('common_enter_text')} />
        </Form.Item>
        <div className={s.actions}>
          <Button
            leftIcon="ok"
            fullWidth
            loading={isLoading}
            htmlType="submit"
            disabled={submitDisabled}>
            {t('common_confirm')}
          </Button>
          <Button ghost fullWidth onClick={onCancel} htmlType="reset">
            {t('common_close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default memo(RevisionModal);
