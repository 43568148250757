import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CommunityMeetParticipantType } from 'types/communityMeet.types';

import Filters from './containers';
import { DEFAULT_FORM_STATE, FormResponseType } from './participants.utils';
import { useColumns } from './useColumns.hook';
import { useRequest } from './useRequest.hook';

const Participants = () => {
  const [form] = Form.useForm<FormResponseType>();

  const requestBody: FormResponseType = Form.useWatch([], form);
  const requestData = useRequest({ requestBody });

  const columns = useColumns();
  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <Filters />
      <FiltersV2.Table<CommunityMeetParticipantType, FormResponseType>
        {...requestData}
        columns={columns}
      />
    </FiltersV2>
  );
};

export default Participants;
