import dayjs from 'dayjs';
import { SURVEYS_SETTLEMENT_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import { Button, Flex, Icon, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { SURVEY_SETTLEMENT_FORM_NAME } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form/surveySettlementForm.utils';

import { useGetSurvey } from '../../useGetSurvey.hook';
import s from './Header.module.scss';
import PublishStatus from './containers/publish-status';

interface Props {
  isSubmittingForm: boolean;
}

const Header = (props: Props) => {
  const { isSubmittingForm } = props;

  const { survey } = useGetSurvey();
  const isUpdate = !!survey;

  return (
    <Flex vertical gap="8" className={s.wrapper}>
      <ContentHeader align="start">
        <ContentHeader.Title goBackLink={SURVEYS_SETTLEMENT_ROUTE}>
          {isUpdate ? survey.name : t('surveys_settlement_add')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          {isUpdate ? (
            <PublishStatus isPublish={survey.isPublish} />
          ) : (
            <Button
              loading={isSubmittingForm}
              form={SURVEY_SETTLEMENT_FORM_NAME}
              htmlType="submit"
              leftIcon="plus">
              {t('common_save')}
            </Button>
          )}
        </ContentHeader.Actions>
      </ContentHeader>

      {survey && (
        <Flex gap="8" align="center">
          <Typography.Text type="secondary" size="14" weight="400">
            {t('common_created')}:
          </Typography.Text>
          <Typography.Text type="secondary" size="14" weight="400">
            <Flex gap="8">
              <Icon name="calendar" />
              {dayjs(survey.createdAt).format('DD MMMM YYYY, hh:mm')}
            </Flex>
          </Typography.Text>
        </Flex>
      )}
    </Flex>
  );
};

export default Header;
