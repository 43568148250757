import { t } from 'tools/i18n';

import { Form, Tabs } from 'gazprom-ui-lib';

import CommunityComments from 'containers/community-comments';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import { getCommunityEntityKind } from '../../../tabs.utils';
import { useSuggestedFormActions } from '../../../useSuggestedFormActions.hook';
import NewsForm from '../form';
import s from './NewsDetails.module.scss';
import Header from './containers/header';
import useFormData from './useFormData';
import useGetData from './useGetData';

const NewsDetails = () => {
  const [form] = Form.useForm();

  const { news, isLoading } = useGetData();
  const { isLoading: isUpdateLoading, handleFinish } = useFormData({ news, form });

  const triggerSubmit = () => form.submit();

  const communityEntityKind = getCommunityEntityKind({
    sourceType: news?.sourceType,
    status: news?.status,
  });

  const actions = useSuggestedFormActions({
    communityEntityKind,
    triggerSubmit,
    isLoading: isUpdateLoading,
    form,
  });

  const headerActions = useSuggestedFormActions({
    communityEntityKind,
    triggerSubmit,
    isLoading: isUpdateLoading,
    isMainFormActions: false,
    form,
  });

  const TABS = [
    {
      key: '1',
      label: t('news_edit'),
      children: (
        <>
          <NewsForm />
          <div className={s.container}>{actions}</div>
        </>
      ),
    },
    {
      key: '2',
      label: t('common_comments'),
      children: <CommunityComments communityEntity="news" />,
    },
    {
      key: '3',
      label: t('common_removed'),
      children: <CommunityComments communityEntity="news" isRemoved />,
    },
  ];

  // Когда sourceType: MOBILE && status: MODERATION
  // Рендерим форму редактирования без табов сверху
  const formWithoutTabs = TABS[0].children;

  if (isLoading || !news) {
    return <WithLoader isLoading={isLoading} />;
  }

  return (
    <Form form={form} onFinish={handleFinish} className={s.form}>
      <WithOverflow>
        <Header
          communityEntityKind={communityEntityKind}
          headerActions={headerActions}
          news={news}
        />
        <div className={s.wrapper}>
          {communityEntityKind === 'mobileModeration' ? (
            formWithoutTabs
          ) : (
            <Tabs type="card" defaultActiveKey="NEWS" items={TABS} className={s.tabs} />
          )}
        </div>
      </WithOverflow>
    </Form>
  );
};

export default NewsDetails;
