import { useEffect } from 'react';

import cn from 'classnames';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { HandbookType } from 'types/guide.types';

import s from './HandbookBaseForm.module.scss';
import HandbookBaseFormCoordinates from './handbook-base-form-coordinates';
import HandbookBaseFormMap from './handbook-base-form-map';
import HandbookBaseFormMemo from './handbook-base-form-memo';
import HandbookBaseFormName from './handbook-base-form-name';
import HandbookBaseFormPublish from './handbook-base-form-publish';
import HandbookBaseFormQrButton from './handbook-base-form-qr-button';
import HandbookBaseFormRegion from './handbook-base-form-region';
import HandbookBaseFormTgChanel from './handbook-base-form-tg-chanel';
import {
  HANDBOOK_BASE_FORM_ENUM,
  HANDBOOK_BASE_FORM_NAME,
  HandbookBaseFormResponse,
} from './handbookBaseForm.utils';

interface HandbookBaseFormProps {
  onSubmit: (form: HandbookBaseFormResponse) => void;
  handbook?: HandbookType;
  className?: string;
  form?: FormInstance;
}

const HandbookBaseForm = (props: HandbookBaseFormProps) => {
  const { onSubmit, handbook, className, form: formFromProps } = props;

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  useEffect(() => {
    if (handbook) {
      formToUse.setFieldsValue({
        [HANDBOOK_BASE_FORM_ENUM.NAME]: handbook.name,
        [HANDBOOK_BASE_FORM_ENUM.COORDINATES]: `${handbook.coordinates.x}, ${handbook.coordinates.y}`,
        [HANDBOOK_BASE_FORM_ENUM.MAP]: handbook.fileMap,
        [HANDBOOK_BASE_FORM_ENUM.MEMO]: handbook.fileInstruction,
        [HANDBOOK_BASE_FORM_ENUM.TG_CHANEL]: handbook.telegramId,
        [HANDBOOK_BASE_FORM_ENUM.PUBLISH]: handbook.isPublish,
        [HANDBOOK_BASE_FORM_ENUM.REGION]: handbook?.region?.id,
      });
    }
  }, [formToUse, handbook]);

  return (
    <Form
      form={formToUse}
      name={HANDBOOK_BASE_FORM_NAME}
      className={cn(s.wrapper, className)}
      onFinish={onSubmit}>
      <HandbookBaseFormName />
      <HandbookBaseFormCoordinates />
      <HandbookBaseFormRegion />
      <HandbookBaseFormMap />
      <HandbookBaseFormMemo />
      <HandbookBaseFormQrButton />
      <HandbookBaseFormTgChanel />
      <HandbookBaseFormPublish />
    </Form>
  );
};

export default HandbookBaseForm;
