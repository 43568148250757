import { useParams } from 'react-router-dom';

import { Form, Tabs } from 'gazprom-ui-lib';

import {
  COMMUNITY_MEETS_FORM_NAME,
  CommunityMeetsFormResponse,
} from 'containers/community-meet-form/communityMeetForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import { getCommunityEntityKind } from '../../../tabs.utils';
import { useSuggestedFormActions } from '../../../useSuggestedFormActions.hook';
import Header from './containers/header';
import { useFormData } from './useFormData.hook';
import { useGetTabs } from './useGetTabs.hook';
import { useHandleFinish } from './useHandleFinish.hook';

const MeetsEdit = () => {
  const { id: communityId, meetingId } = useParams() as {
    id: string;
    meetingId: string;
  };

  const { handleFinish, isUpdatingCommunityMeet } = useHandleFinish({
    communityId,
    meetingId,
  });

  const [form] = Form.useForm<CommunityMeetsFormResponse>();
  const { communityMeet, isLoadingCommunityMeet } = useFormData({ communityId, meetingId, form });

  const triggerSubmit = () => form.submit();

  const communityEntityKind = getCommunityEntityKind({
    sourceType: communityMeet?.sourceType,
    status: communityMeet?.status,
  });

  const actions = useSuggestedFormActions({
    communityEntityKind,
    isLoading: isUpdatingCommunityMeet,
    triggerSubmit,
    form,
  });

  const headerActions = useSuggestedFormActions({
    communityEntityKind,
    isLoading: isUpdatingCommunityMeet,
    triggerSubmit,
    form,
    isMainFormActions: false,
  });

  const tabs = useGetTabs({ actions });

  // Когда sourceType: MOBILE && status: MODERATION
  // Рендерим форму редактирования без табов сверху
  const formWithoutTabs = tabs[0].children;

  if (!communityMeet || isLoadingCommunityMeet) {
    return <WithLoader isLoading={isLoadingCommunityMeet} />;
  }

  return (
    <Form form={form} onFinish={handleFinish} name={COMMUNITY_MEETS_FORM_NAME}>
      <Header
        communityId={communityId}
        communityMeet={communityMeet}
        communityEntityKind={communityEntityKind}
        headerActions={headerActions}
      />
      {communityEntityKind === 'mobileModeration' ? (
        formWithoutTabs
      ) : (
        <Tabs items={tabs} type="card" />
      )}
    </Form>
  );
};

export default MeetsEdit;
