import { getFiltersCount } from 'utils/filter.utils';

import { Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCollapse from 'containers/filters/filter-collapse';
import { FiltersResponseType } from 'containers/filters/filters.utils';

import { FormFiltersType } from '../../salaryProject.utils';
import useFilterItems from './useFilterItems.hook';

const Filters = () => {
  const items = useFilterItems();
  const filters = Form.useWatch('filters');

  return (
    <FiltersV2.TableFilters>
      <FiltersV2.Search />
      <FiltersContainer<FormFiltersType>
        showClearAll
        initialValues={filters as FiltersResponseType | null}
        overlay={<FilterCollapse defaultActiveKey={['employerIds']} items={items} />}
        filtersCount={getFiltersCount(filters)}
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
