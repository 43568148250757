import { t } from 'tools/i18n';

import { Button, ModalDefault, Typography } from 'gazprom-ui-lib';

import { AuthorityType } from 'types/authority.types';

interface Props {
  open: boolean;
  handleCloseModal: () => void;
  data?: AuthorityType;
  message?: string;
}

const ModalError = (props: Props) => {
  const { open, data, handleCloseModal, message } = props;

  return (
    <ModalDefault open={open} onCancel={handleCloseModal} width={520} closable>
      <ModalDefault.Icon name="info" type="danger" />
      <ModalDefault.Title>{t('certification_authorities_no_access')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('certification_authorities_no_access_description', { name: data?.subject })}
        {message && (
          <Typography.Paragraph size="14" type="danger">
            {message}
          </Typography.Paragraph>
        )}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button fullWidth onClick={handleCloseModal}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ModalError;
