import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetCommunityMeetParticipantsQuery } from 'services/communities/communitiesApiService';
import { GetCommunityMeetParticipantsPropsType } from 'services/communities/communityApiService.types';

import { FormResponseType } from './participants.utils';

interface Props {
  requestBody: FormResponseType;
}

export const useRequest = (props: Props) => {
  const { requestBody } = props;

  const { id: communityId, meetingId: meetId } = useParams() as {
    id: string;
    meetingId: string;
  };

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { search, ...remainingRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetCommunityMeetParticipantsPropsType['params'] = {
    ...remainingRequestBody,
    ...(search && { text: search }),
  };

  const {
    data: { items: dataSource = [], totalCount: total = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetCommunityMeetParticipantsQuery(
    {
      communityId,
      meetId,
      params: { ...actualRequestBody },
    },
    { skip: !actualRequestBody.page },
  );

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!search?.trim();

  return { isInitialLoading, loading, dataSource, total, hasFiltersApplied };
};
