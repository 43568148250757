import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  open: boolean;
  handleOpenClose: () => void;
  loading: boolean;
  handleClick: () => void;
  multiple?: boolean;
}

const DeleteModal = (props: Props) => {
  const { open, loading, handleOpenClose, handleClick, multiple = false } = props;

  return (
    <ModalDefault open={open} onCancel={handleOpenClose} width={320} closable>
      <ModalDefault.Icon name="bin" type="danger" />
      <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t(multiple ? 'telegram_delete_multiple_description' : 'telegram_delete_description')}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          danger
          type="secondary"
          size="large"
          loading={loading}
          fullWidth
          onClick={handleClick}>
          {t('common_delete')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default DeleteModal;
