import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetPowerOfAttorneyQuery } from 'services/power-of-attorney-private/powerOfAttorneyPrivateApiService';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './documentsMachineAuthorities.utils';

interface UseGetDataProps {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProps) => {
  const { form } = props;

  const formData = Form.useWatch([], form);

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  const { order, property } = debouncedFormData ?? {};

  const sort = order && property ? [{ order, property }] : undefined;

  const { data: powersOfAttorney, isFetching: powersOfAttorneyFetching } =
    useGetPowerOfAttorneyQuery(
      debouncedFormData
        ? {
            sort,
            page: debouncedFormData.page,
            size: debouncedFormData.size,
          }
        : skipToken,
    );

  return {
    powersOfAttorney,
    powersOfAttorneyFetching: powersOfAttorneyFetching || isDebouncing,
  };
};

export default useGetData;
