import { useState } from 'react';

import { t } from 'tools/i18n';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';
import { formRules } from 'utils/formRules';

import { Form } from 'gazprom-ui-lib';

import ImgPreview from 'components/img-preview';
import UploadImitation from 'components/upload-imitation';
import ImageModal from 'containers/image-modal';

import { FORM_ENUM } from '../../salaryProjectForm.utils';
import s from './Cover.module.scss';

const Cover = () => {
  const form = Form.useFormInstance();

  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const cover: string = Form.useWatch(FORM_ENUM.COVER);

  const handleModalOpenClose = () => {
    setShowUploadModal((prevState) => !prevState);
  };
  const onImgUpload = (url: string) => {
    form.setFieldValue(FORM_ENUM.COVER, url);
    handleModalOpenClose();
  };

  const resetImage = () => {
    form.resetFields([FORM_ENUM.COVER]);
  };

  return (
    <>
      <Form.Item
        name={FORM_ENUM.COVER}
        label={t('salary_project_banner')}
        rules={[formRules.required]}>
        {cover ? (
          <ImgPreview type="image" url={createMediaDownloadUrl(cover)} resetImage={resetImage} />
        ) : (
          <UploadImitation handleClick={handleModalOpenClose} className={s.upload} />
        )}
      </Form.Item>
      <ImageModal isOpen={showUploadModal} onSubmit={onImgUpload} onClose={handleModalOpenClose} />
    </>
  );
};

export default Cover;
