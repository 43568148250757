import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { getFio } from 'utils/getFio.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployees1cQuery } from 'services/1c/cApiService';
import { useGetCommunityCategoriesQuery } from 'services/communities/communitiesApiService';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

const DEFAULT_PAGINATION = { page: 1, size: 100 };

export const useFilterItems = () => {
  const [categorySearch, setCategorySearch] = useState('');
  const { debouncedValue: debouncedCategorySearch } = useDebounce(categorySearch);

  const [moderatorSearch, setModeratorSearch] = useState('');
  const { debouncedValue: debouncedModeratorSearch } = useDebounce(moderatorSearch);

  const { data: { items: communityCategories = [] } = {} } = useGetCommunityCategoriesQuery({
    ...(debouncedCategorySearch && { name: debouncedCategorySearch }),
  });

  const preparedCategories: { value: string; label: string }[] = communityCategories.map(
    ({ id, name }) => ({ value: id, label: name }),
  );

  const { data: { employees = [] } = {} } = useGetEmployees1cQuery({
    ...DEFAULT_PAGINATION,
    fio: debouncedModeratorSearch,
  });

  const preparedEmployees: { value: string; label: string }[] = employees.map(
    ({ id, person: { firstName, lastName, patronymic } }) => {
      const label = getFio({ firstName, lastName, patronymic, type: 'full' });
      return { value: id, label };
    },
  );

  return useMemo(
    () => [
      {
        key: 'categoryIds',
        label: <FilterHeader valueKey="categoryIds">{t('common_categories')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            valueKey="categoryIds"
            options={preparedCategories}
            showSearch
            searchProps={{
              placeholder: t('common_search'),
              value: categorySearch,
              onChange: (e) => setCategorySearch(e.target.value),
            }}
          />
        ),
      },
      {
        key: 'moderatorIds',
        label: (
          <FilterHeader valueKey="moderatorIds">{t('community_moderator_filters')}</FilterHeader>
        ),
        children: (
          <FilterCheckboxGroup
            valueKey="moderatorIds"
            options={preparedEmployees}
            showSearch
            searchProps={{
              placeholder: t('common_search'),
              value: moderatorSearch,
              onChange: (e) => setModeratorSearch(e.target.value),
            }}
          />
        ),
      },
    ],
    [preparedCategories, categorySearch, moderatorSearch, preparedEmployees],
  );
};
