import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { Form } from 'gazprom-ui-lib';

import DiscussionCreateEditModal from 'containers/discussion-create-edit-modal';
import FiltersV2 from 'containers/filters-v2';

import { CommunityDiscussionType } from 'types/community.types';
import { COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

import s from './SuggestedTab.module.scss';
import Filters from './containers/filters';
import {
  CommunitySuggestedEntityType,
  DEFAULT_FORM_STATE,
  FormResponseType,
  SuggestedTabSegments,
  getOnRowFn,
} from './suggestedTab.utils';
import { useColumns } from './useColumns.hook';
import { useDiscussionModalConfig } from './useDiscussionModalConfig.hook';
import { useRequest } from './useRequest.hook';

const SuggestedTab = () => {
  const { id: communityId } = useParams() as { id: string };
  const navigate = useNavigate();
  const [form] = Form.useForm<FormResponseType>();
  const requestBody: FormResponseType = Form.useWatch([], form);
  const [discussion, setDiscussion] = useState<null | CommunityDiscussionType>(null);

  const { disussionModalOpened, handleClose, handleOpen } = useDiscussionModalConfig({
    setDiscussion,
  });

  const [activeSegment, setActiveSegment] = useState<SuggestedTabSegments>('news');

  const requestData = useRequest({ communityId, requestBody, segment: activeSegment });
  const columns = useColumns();

  const onRow = (entity: CommunitySuggestedEntityType) => {
    const onRowFn = getOnRowFn(activeSegment);
    const { communityId, id, status } = entity;

    // Кейс, когда тип discussion, status: MODERATION
    const shouldOpenDiscussionsModal =
      activeSegment === 'discussions' && status === COMMUNITY_ENTITY_STATUS.MODERATION;

    return {
      onClick: () =>
        shouldOpenDiscussionsModal
          ? handleOpen(entity as CommunityDiscussionType)
          : navigate(onRowFn(communityId, id)),
    };
  };

  return (
    <>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters activeSegment={activeSegment} setActiveSegment={setActiveSegment} />
        <FiltersV2.Table<CommunitySuggestedEntityType, FormResponseType>
          {...requestData}
          onRow={onRow}
          columns={columns}
          className={s.table}
        />
      </FiltersV2>
      {discussion && (
        <DiscussionCreateEditModal
          open={disussionModalOpened}
          onClose={handleClose}
          edit
          discussion={discussion}
          communityId={communityId}
          isSuggestion
        />
      )}
    </>
  );
};

export default SuggestedTab;
