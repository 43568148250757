import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import CommunityComments from 'containers/community-comments';
import CommunityMeetForm from 'containers/community-meet-form';
import Docs from 'containers/event-form/event-form-main/containers/docs';

import Participants from './containers/participants';

interface Props {
  actions: ReactNode;
}

export const useGetTabs = (props: Props) => {
  const { actions } = props;
  return [
    {
      key: MEET_EDIT,
      children: <CommunityMeetForm actions={actions} />,
      label: t('events_edit'),
      forceRender: true,
    },
    {
      key: MEET_COMMENTS,
      children: <CommunityComments communityEntity="meets" />,
      label: t('common_comments'),
    },
    {
      key: MEET_REMOVED_COMMENTS,
      children: <CommunityComments communityEntity="meets" isRemoved />,
      label: t('common_removed_comments'),
    },
    {
      key: MEET_PARTICIPANTS,
      children: <Participants />,
      label: t('common_participants'),
    },
    {
      key: MEET_DOCS,
      children: <Docs />,
      label: t('common_documents'),
      disabled: true,
    },
  ];
};

export const MEET_EDIT = 'MEET_EDIT';
export const MEET_COMMENTS = 'MEET_COMMENTS';
export const MEET_REMOVED_COMMENTS = 'MEET_REMOVED_COMMENTS';
export const MEET_PARTICIPANTS = 'MEET_PARTICIPANTS';
export const MEET_DOCS = 'MEET_DOCS';
