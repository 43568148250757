import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import SurveySettlementForm from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form';

import { SurveySettlementFormResponse } from './containers/survey-settlement-form/surveySettlementForm.utils';
import SurveySettlementStats from './containers/survey-settlement-stats';

interface Props {
  handleFinish: (formResponse: SurveySettlementFormResponse) => void;
  formActions: ReactNode;
}

export const useGetTabs = (props: Props) => {
  const { handleFinish, formActions } = props;

  return [
    {
      key: SURVEY_SETTLEMENT_TABS_KEYS.STATS,
      label: t('surveys_stats'),
      children: <SurveySettlementStats />,
    },
    {
      key: SURVEY_SETTLEMENT_TABS_KEYS.FORM,
      label: t('surveys_settings'),
      children: <SurveySettlementForm handleFinish={handleFinish} formActions={formActions} />,
    },
  ];
};

export enum SURVEY_SETTLEMENT_TABS_KEYS {
  STATS = 'STATS',
  FORM = 'FORM',
}
