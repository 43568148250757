import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetCommentByCommunityNewsIdQuery } from 'services/communities/communitiesApiService';
import { GetCommentByCommunityNewsIdPropsType } from 'services/communities/communityApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './communityComments.utils';

type CommunityCommentsEntities = 'news' | 'meets';

interface Props {
  form: FormInstance<FormResponseType>;
  communityEntity?: CommunityCommentsEntities;
  visible?: boolean;
}

const useRequest = (props: Props) => {
  const { form, visible = true, communityEntity } = props;

  const params = useParams();
  const id = params.id;
  const communityEntityId = communityEntity === 'news' ? params.newsId : params.meetingId;

  const requestBody: FormResponseType | undefined = Form.useWatch([], form);

  const [getComments, { isFetching, data, isLoading }] = useLazyGetCommentByCommunityNewsIdQuery();

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody && debouncedRequestBody.size) {
      const preparedBody: GetCommentByCommunityNewsIdPropsType = {
        communityId: id!,
        newsId: communityEntityId!,
        page: debouncedRequestBody.page,
        size: debouncedRequestBody.size,
        visible,
      };

      if (debouncedRequestBody.search) {
        preparedBody.queryString = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        preparedBody.sort = [
          {
            property: debouncedRequestBody.property,
            order: debouncedRequestBody.order,
          },
        ];
      }

      getComments(preparedBody);
    }
  }, [debouncedRequestBody, getComments, id, communityEntityId, visible]);

  return {
    isLoading: isLoading,
    isFetching: isFetching || isDebouncing,
    data,
  };
};

export default useRequest;
