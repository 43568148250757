import { COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

export enum COMMUNITY_NEWS_FORM_ENUM {
  TITLE = 'title',
  TEXT = 'text',
  COVER = 'cover',
  CATEGORIES = 'categories',
  PUBLISH = 'isPublish',
  STATUS = 'status',
}

export type CommunityNewsFormResponse = {
  [COMMUNITY_NEWS_FORM_ENUM.TITLE]: string;
  [COMMUNITY_NEWS_FORM_ENUM.TEXT]: string;
  [COMMUNITY_NEWS_FORM_ENUM.COVER]: string;
  [COMMUNITY_NEWS_FORM_ENUM.CATEGORIES]: string[];
  [COMMUNITY_NEWS_FORM_ENUM.PUBLISH]: boolean;
  [COMMUNITY_NEWS_FORM_ENUM.STATUS]?: COMMUNITY_ENTITY_STATUS;
};
