
import initialApiService from 'services/initialApiService';

import {
  GetKedoDocTypesPropsType,
  GetKedoDocTypesResponseType,
} from './publicApiService.types';

import { createPublicUrl } from './utils';

const recruitApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getKedoDocTypes: builder.query<GetKedoDocTypesResponseType,
GetKedoDocTypesPropsType>({
      query: () => ({
        url: createPublicUrl(`/kedo-doc-type`),
      }),
    }),
    
  }),
});

export const {
  useGetKedoDocTypesQuery
} = recruitApiService;
