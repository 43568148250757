import { memo, useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import useUploadFile from 'utils/useUploadFile.hook';

import {
  useUpdateMapHandbookMutation,
  useUpdateMemoHandbookMutation,
} from 'services/settlement/settlementApiService';

import { Button, Form, RcFile, handleRequest } from 'gazprom-ui-lib';

import s from './UploadActions.module.scss';
import ModalUpload from './containers/modal-upload';
import {
  AVAILABLE_MAP_TYPES,
  AVAILABLE_MEMO_TYPES,
  CoverType,
  MAX_SIZE,
  ModalType,
} from './uploadActions.utils';

interface Props {
  selectedRowKeys: string[];
}

const GuideHandbookFiltersUpload = (props: Props) => {
  const { selectedRowKeys } = props;

  const [form] = Form.useForm();

  const [modalConfig, setModalConfig] = useState<null | ModalType>(null);
  const [cover, setCover] = useState<null | CoverType>(null);

  const [updateMap] = useUpdateMapHandbookMutation();
  const [updateMemo] = useUpdateMemoHandbookMutation();

  const handleModalClose = () => {
    setModalConfig(null);
  };

  const resetImage = () => {
    setCover(null);
  };

  const onSuccess = () => {
    handleModalClose();
    resetImage();
  };

  const handleUpdate = () => {
    if (modalConfig && cover) {
      const mutation = modalConfig?.type === 'memo' ? updateMemo : updateMap;
      const mutationProps = {
        file: {
          name: cover.name ?? '',
          size: cover.size ?? 0,
          link: cover.url ?? '',
        },
        ids: selectedRowKeys,
      };

      mutation(mutationProps).then(
        handleRequest({
          onSuccess,
        }),
      );
    }
  };

  const setErrors = (errors: string[]) =>
    form.setFields([
      {
        name: 'upload',
        errors,
      },
    ]);
  const { isLoading, fileData, customRequest } = useUploadFile({ setErrors });

  useEffect(() => {
    if (fileData?.name) {
      setCover(fileData);
    }
  }, [fileData]);

  const beforeUpload = (file: RcFile) => {
    const isCorrectFormat = modalConfig?.availableTypes?.includes(file.type);
    const moreThanLimit = file.size / 1024 / 1024 > MAX_SIZE;

    if (!isCorrectFormat) {
      setErrors([t('validation_file_incorrect_extension')]);
      return false;
    } else if (moreThanLimit) {
      setErrors([t('validation_file_max_size', { size: `${MAX_SIZE} MB` })]);
      return false;
    } else {
      setErrors([]);
      return file;
    }
  };

  const areRowsSelected = !!selectedRowKeys.length;

  return (
    <>
      <div className={s.wrapper}>
        <Button
          onClick={() => {
            setModalConfig({
              type: 'map',
              title: t('guide_handbook_map_upload'),
              availableTypes: AVAILABLE_MAP_TYPES,
              description: t('validation_upload_an_image_restrictions'),
            });
          }}
          leftIcon="upload"
          size="small"
          type="link"
          disabled={!areRowsSelected}>
          {t('guide_handbook_map_upload')}
        </Button>
        <Button
          onClick={() => {
            setModalConfig({
              type: 'memo',
              title: t('guide_handbook_memo_upload'),
              availableTypes: AVAILABLE_MEMO_TYPES,
              description: t('validation_upload_a_doc_restrictions'),
            });
          }}
          leftIcon="upload"
          size="small"
          type="link"
          disabled={!areRowsSelected}>
          {t('guide_handbook_memo_upload')}
        </Button>
      </div>
      <ModalUpload
        modalConfig={modalConfig}
        handleModalClose={handleModalClose}
        handleUpdate={handleUpdate}
        isLoading={isLoading}
        beforeUpload={beforeUpload}
        customRequest={customRequest}
        form={form}
        cover={cover}
        resetImage={resetImage}
      />
    </>
  );
};

export default memo(GuideHandbookFiltersUpload);
