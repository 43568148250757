import { memo, useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import {
  useCreateGuideCategoryMutation,
  useUpdateGuideCategoryMutation,
} from 'services/settlement/settlementApiService';

import {
  Button,
  Checkbox,
  Input,
  InputProps,
  Modal,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { GuideCategoryType } from 'types/guide.types';

import s from './GuideCategoriesAddEditModal.module.scss';

interface Props {
  open: boolean;
  handleClose: () => void;
  selectedCategory: null | GuideCategoryType;
}

const GuideCategoriesAddEditModal = (props: Props) => {
  const { open, handleClose, selectedCategory } = props;

  const [categoryName, setCategoryName] = useState<string>('');
  const [divideInformation, setDivideInformation] = useState<boolean>(false);

  const [createCategory, { isLoading: createIsLoading }] = useCreateGuideCategoryMutation();
  const [updateCategory, { isLoading: updateIsLoading }] = useUpdateGuideCategoryMutation();

  useEffect(() => {
    if (open && selectedCategory) {
      setCategoryName(selectedCategory.name);
      setDivideInformation(selectedCategory.withEtaps);
    } else {
      setCategoryName('');
      setDivideInformation(false);
    }
  }, [open, selectedCategory]);

  const isEdit = !!selectedCategory;
  const isLoading = createIsLoading || updateIsLoading;

  const saveButtonDisabled =
    !categoryName.trim().length ||
    (!!selectedCategory &&
      categoryName === selectedCategory.name &&
      divideInformation === selectedCategory.withEtaps);

  const handleCheckboxChange = () => {
    setDivideInformation((prevState) => !prevState);
  };
  const handleInputChange: InputProps['onChange'] = (e) => {
    setCategoryName(e.target.value);
  };

  const handleSubmit = () => {
    const body = { name: categoryName, withEtaps: divideInformation, num: 1 };

    if (!selectedCategory) {
      createCategory(body).then(
        handleRequest({
          onSuccess: handleClose,
        }),
      );
    } else {
      updateCategory({ ...body, id: selectedCategory.id }).then(
        handleRequest({
          onSuccess: handleClose,
        }),
      );
    }
  };

  return (
    <Modal open={open} onCancel={handleClose} closable>
      <Typography.Title level={4}>
        {t(isEdit ? 'common_edit_category' : 'common_add_new_category')}
      </Typography.Title>
      <div>
        <div className={s.title}>
          <Typography.Text type="primary" size="14" weight="500">
            {t('common_category_name')}
          </Typography.Text>
          <Typography.Text type="secondary">
            {t('common_symbol_count', { current: categoryName.length, total: 30 })}
          </Typography.Text>
        </div>
        <Input
          maxLength={30}
          placeholder={t('common_enter_text')}
          value={categoryName}
          onChange={handleInputChange}
        />
        <Checkbox
          checked={divideInformation}
          onChange={handleCheckboxChange}
          className={s.checkbox}>
          {t('guide_divide_info_inside_category')}
        </Checkbox>
      </div>
      <div className={s.actions}>
        <Button
          onClick={handleSubmit}
          disabled={saveButtonDisabled}
          htmlType="button"
          loading={isLoading}
          leftIcon={isEdit ? 'ok' : 'plus'}
          fullWidth>
          {t(isEdit ? 'common_save' : 'common_add')}
        </Button>
        <Button htmlType="button" type="secondary" onClick={handleClose} fullWidth>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default memo(GuideCategoriesAddEditModal);
