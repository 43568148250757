import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetNotificationTemplatesQuery } from 'services/notification-template/notificationTemplateApiService';
import { GetNotificationTemplatesPropsType } from 'services/notification-template/notificationTemplateApiService.types';

import { Button, Flex, Form, FormInstance, Icon, Input, Modal, Typography } from 'gazprom-ui-lib';

import {
  NOTIFICATION_NEWSLETTER_FORM_ENUM,
  NOTIFICATION_NEWSLETTER_FORM_NAME,
  NotificationNewsletterFormResponse,
} from '../../notificationNewsletterForm.utils';
import s from './TemplateModal.module.scss';

interface Props {
  form: FormInstance<NotificationNewsletterFormResponse>;
  isOpen?: boolean;
  isTemplateCreating?: boolean;
  handleClose?: () => void;
}

const INITIAL_REQUEST_BODY = {
  page: 1,
  size: 500,
  search: '',
};

const TemplateModal = (props: Props) => {
  const { form, isOpen, isTemplateCreating, handleClose } = props;

  const [templateNameExists, setTemplateNameExists] = useState(true);

  const [getTemplates, { data: templates, isLoading, isFetching }] =
    useLazyGetNotificationTemplatesQuery();

  const templateName = Form.useWatch(NOTIFICATION_NEWSLETTER_FORM_ENUM.TEMPLATE_NAME, form);

  const { debouncedValue: debouncedTemplateName } = useDebounce(templateName);

  useEffect(() => {
    const requestBody: GetNotificationTemplatesPropsType = {
      ...INITIAL_REQUEST_BODY,
      search: debouncedTemplateName,
    };

    getTemplates(requestBody);
  }, [getTemplates, debouncedTemplateName]);

  useEffect(() => {
    if (templates && templateName) {
      const foundTemplate = templates.items.find((template) => template.name === templateName);

      setTemplateNameExists(!!foundTemplate);
    }
  }, [templates, templateName]);

  const loading = isLoading || isFetching;
  const showTemplateNameExists = templateNameExists && templateName && !loading;

  return (
    <Modal open={isOpen} className={s.modal} onCancel={handleClose}>
      <div className={s.header}>
        <Typography.Title level={4}>{t('notification_create_template')}</Typography.Title>
        <button className={s.close} onClick={handleClose}>
          <Icon name="delete" size={16} />
        </button>
      </div>

      <div className={s.content}>
        <Typography.Text size="14" weight="500" type="primary">
          {t('common_template_name')}
        </Typography.Text>
        <Form.Item
          name={NOTIFICATION_NEWSLETTER_FORM_ENUM.TEMPLATE_NAME}
          rules={[formRules.required]}>
          <Input showCount placeholder={t('common_enter_text')} maxLength={80} />
        </Form.Item>
        {showTemplateNameExists && (
          <Flex align="center" gap="8">
            <Icon name="info" color="var(--color-warning)" />
            <Typography.Text type="secondary" size="14">
              {t('notification_save_as_template_exists')}
            </Typography.Text>
          </Flex>
        )}
      </div>

      <div className={s.buttonContainer}>
        <Button
          disabled={templateNameExists}
          htmlType="submit"
          form={NOTIFICATION_NEWSLETTER_FORM_NAME}
          type="primary"
          leftIcon="ok"
          fullWidth
          loading={loading || isTemplateCreating}>
          {t('common_save')}
        </Button>
        <Button type="secondary" fullWidth onClick={handleClose}>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default TemplateModal;
