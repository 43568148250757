import { useParams } from 'react-router-dom';

import { useGetCommunitySubscribersEmployersQuery } from 'services/communities/communitiesApiService';

export const useGetFiltersData = () => {
  const { id } = useParams() as { id: string };

  const { data: employers = [] } = useGetCommunitySubscribersEmployersQuery({ id });

  const subscribersEmployers = employers.map(({ id, name }) => ({ value: id, label: name }));

  return { subscribersEmployers };
};
