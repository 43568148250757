import { useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import {
  useCreateFaqCategoryMutation,
  useUpdateFaqCategoryMutation,
} from 'services/faq/faqApiService';

import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import useData from 'containers/faq-category-modal/useData.hook';

import { FaqCategoryType } from 'types/faq.types';

import s from './FaqCategoryModal.module.scss';
import { FORM_ENUM, FormResponseType } from './faqCategoryModal.utils';

interface Props {
  open: boolean;
  onClose: () => void;
  onFinish?: (res?: FaqCategoryType) => void;
  data?: {
    id: string;
    name: string;
    isPublish: boolean;
    parent: string | null;
  };
}

const FaqCategoryModal = (props: Props) => {
  const { open, onClose, data, onFinish } = props;

  const [form] = Form.useForm();

  const [createFaqCategory, { isLoading: createIsLoading }] = useCreateFaqCategoryMutation();
  const [updateFaqCategory, { isLoading: updateIsLoading }] = useUpdateFaqCategoryMutation();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleOnCreate = (res?: FaqCategoryType) => {
    if (onFinish) {
      onFinish(res);
    }
    handleClose();
  };

  const handleFinish = (formResponse: FormResponseType) => {
    const defaultRequestBody = { ...formResponse, isPublish: !!formResponse[FORM_ENUM.PUBLISHED] };

    if (data) {
      const requestBody = {
        ...defaultRequestBody,
        id: data.id,
      };
      updateFaqCategory(requestBody).then(
        handleRequest({
          onSuccess: handleClose,
        }),
      );
    } else {
      createFaqCategory(defaultRequestBody).then(
        handleRequest({
          onSuccess: handleOnCreate,
        }),
      );
    }
  };

  const parentCategoryLabel = (
    <Flex gap="4" align="center">
      {t('common_parent_category')}
      <Typography.Text type="secondary">{t('common_optional')}</Typography.Text>
    </Flex>
  );

  const { options, faqCategoriesIsFetching } = useData({ currentId: data?.id });

  useEffect(() => {
    if (data) {
      form.setFields([
        { name: FORM_ENUM.NAME, value: data.name },
        { name: FORM_ENUM.PARENT, value: data.parent },
        { name: FORM_ENUM.PUBLISHED, value: data.isPublish },
      ]);
    }
  }, [data, form]);

  const isLoading = updateIsLoading || createIsLoading;

  return (
    <Modal open={open} onCancel={handleClose} closable>
      <Typography.Title level={4}>{t('common_add_category')}</Typography.Title>
      <Form form={form} onFinish={handleFinish} onReset={handleClose} className={s.formWrapper}>
        <Form.Item
          name={FORM_ENUM.NAME}
          rules={[formRules.required]}
          label={t('common_category_name')}>
          <Input placeholder={t('common_enter_text')} showCount maxLength={255} />
        </Form.Item>
        <Form.Item name={FORM_ENUM.PARENT} label={parentCategoryLabel}>
          <Select
            allowClear
            options={options}
            placeholder={t('common_select_from_list')}
            loading={faqCategoriesIsFetching}
          />
        </Form.Item>
        <Form.Item valuePropName="checked" name={FORM_ENUM.PUBLISHED}>
          <Checkbox>{t('common_published')}</Checkbox>
        </Form.Item>
        <Flex gap="8">
          <Button leftIcon="ok" htmlType="submit" fullWidth loading={isLoading}>
            {t('common_save')}
          </Button>
          <Button ghost htmlType="reset" fullWidth>
            {t('common_cancel')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default FaqCategoryModal;
