import { FC, useEffect, useState } from 'react';

import { handleTableChange } from 'utils/table.utils';

import {
  useLazyGetEmployer1cQuery,
  useUpdateEmployer1cByIdMutation,
} from 'services/1c/cApiService';
import { CEmployerType } from 'services/1c/cApiService.types';
import { useUpdateEmployerSettingsMutation } from 'services/employer-settings/employerSettingsApiService';

import { Table, TableProps } from 'gazprom-ui-lib';

import { CInstanceType } from 'types/cInstances.types';

import { INITIAL_REQUEST_BODY, RequestBody } from './importedOrganizationsTable.utils';
import useColumns from './useColumns.hook';

interface ImportedOrganizationsTableProps {
  cInstance?: CInstanceType;
}

const ImportedOrganizationsTable: FC<ImportedOrganizationsTableProps> = (props) => {
  const { cInstance } = props;

  const [requestBody, setRequestBody] = useState<RequestBody>({
    ...INITIAL_REQUEST_BODY,
  });

  const [updateEmployer1cById] = useUpdateEmployer1cByIdMutation();
  const [updateEmployerSettings] = useUpdateEmployerSettingsMutation();

  const columns = useColumns({
    updateEmployer1cById,
    updateEmployerSettings,
  });

  const [getEmployer1c, { data, isLoading }] = useLazyGetEmployer1cQuery();

  useEffect(() => {
    if (cInstance)
      getEmployer1c({
        instance1cId: cInstance.id,
        ...requestBody,
      });
  }, [cInstance, getEmployer1c, requestBody]);

  const pagination: TableProps<CEmployerType>['pagination'] = {
    defaultCurrent: requestBody.page,
    defaultPageSize: requestBody.size,
    total: data && data?.totalCount,
    current: requestBody.page,
  };

  return (
    <Table
      dataSource={data?.employers}
      columns={columns}
      loading={isLoading}
      rowKey="id"
      pagination={pagination}
      onChange={handleTableChange(setRequestBody)}
    />
  );
};

export default ImportedOrganizationsTable;
