import { FC } from 'react';

import { useLocation } from 'react-router-dom';
import { ACCESS_CONTROL_USERS_ROUTE } from 'routes/access-control/list';
import { t } from 'tools/i18n';

import { GetPersonByIdResponseType } from 'services/person/personApiService.types';

import { Icon, Tag } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import LastChange from 'containers/last-change';

import s from './Header.module.scss';

interface HeaderProps {
  person?: GetPersonByIdResponseType;
}

const Header: FC<HeaderProps> = (props) => {
  const { person } = props;

  const location = useLocation();

  const { ssoRegistered, confirm, updatedAt, patronymic, lastName, firstName } = person ?? {};

  const isActive = ssoRegistered;
  const name = `${lastName} ${firstName} ${patronymic}`;

  return (
    <ContentHeader align="flex-start">
      <ContentHeader.Title
        goBackLink={ACCESS_CONTROL_USERS_ROUTE}
        goBackState={{ ...location.state }}
        description={
          <LastChange
            date={updatedAt}
            // editorId={editor?.id}
            // editorName={`${editor?.lastName} ${editor?.firstName}`}
          />
        }>
        {name}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Tag type={confirm ? 'success' : 'warning'} className={s.tag}>
          <Icon name={confirm ? 'ok' : 'time'} />
          <span>{confirm ? t('common_confirmed') : t('common_not_confirmed')}</span>
        </Tag>
        <Tag type={isActive ? 'success' : 'warning'} className={s.tag}>
          <Icon name={isActive ? 'ok' : 'time'} />
          <span>{isActive ? t('common_active') : t('common_not_active')}</span>
        </Tag>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
