import { SORT_ENUM } from 'types/sort.types';

export const C_INSTANCE_SETTINGS_TAB = 'C_INSTANCE_SETTINGS_TAB';
export const C_INSTANCE_IMPORTED_ORGANIZATIONS_TAB = 'C_INSTANCE_IMPORTED_ORGANIZATIONS_TAB';
export const C_INSTANCE_SYNCHRONIZATION_HISTORY_TAB = 'C_INSTANCE_SYNCHRONIZATION_HISTORY_TAB';

export type RequestBody = {
  page: number;
  size: number;
  property: string;
  order: SORT_ENUM;
};

export const DEFAULT_REQUEST_BODY: RequestBody = {
  page: 1,
  size: 10,
  property: 'syncDate',
  order: SORT_ENUM.DESC,
};
