import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';

import { Flex, Form, Icon, Tooltip, Typography } from 'gazprom-ui-lib';

import ImageModalUpload from 'containers/image-modal/image-modal-upload';
import ImageModalUrl from 'containers/image-modal/image-modal-url';
import { FORM_IMAGE_ENUM, IMPORT, TABS, URL } from 'containers/image-modal/imageModal.utils';

import s from './FileItem.module.scss';

interface Props {
  uploadDescription?: string;
  availableTypes?: string[];
  uploadTitle?: string;
  maxLimitSizeMB?: number;
  kedoReviewTaskId?: string | null;
  handleCreateTaskId?: () => void;
}

const FileItem = (props: Props) => {
  const {
    uploadDescription,
    uploadTitle,
    availableTypes,
    maxLimitSizeMB,
    kedoReviewTaskId,
    handleCreateTaskId,
  } = props;

  const [activeTab] = useState<string>(DEFAULT_ACTIVE_TAB);

  const form = Form.useFormInstance();

  const content = useMemo(() => {
    switch (activeTab) {
      case URL:
        return <ImageModalUrl form={form} />;
      case IMPORT:
        return (
          <ImageModalUpload
            handleCreateTaskId={handleCreateTaskId}
            kedoReviewTaskId={kedoReviewTaskId}
            maxLimitSizeMB={maxLimitSizeMB}
            form={form}
            title={uploadTitle}
            description={uploadDescription}
            availableTypes={availableTypes}
            convert
          />
        );
    }
  }, [
    activeTab,
    availableTypes,
    form,
    handleCreateTaskId,
    kedoReviewTaskId,
    maxLimitSizeMB,
    uploadDescription,
    uploadTitle,
  ]);

  return (
    <>
      <Tooltip
        overlayClassName={s.tooltip}
        placement="bottomLeft"
        align={{ offset: [33, 16] }}
        title={t('common_pfd_a1_convert_file_description')}>
        <Flex align="center" gap="4">
          <Typography.Text weight="500" size="16">
            {t('common_file')}
          </Typography.Text>

          <Icon name="info" color="var(--color-primary-6)" />
        </Flex>
      </Tooltip>
      <Form.Item name={FORM_IMAGE_ENUM.SHOW_PREVIEW} noStyle />
      <Form.Item name={FORM_IMAGE_ENUM.FULL_INFO} noStyle />
      {content}
    </>
  );
};

const DEFAULT_ACTIVE_TAB = TABS[0]?.value;

export default FileItem;
