import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';
import { removeDuplicateOptions } from 'utils/removeDuplicateOptions.utils';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useRoleModelContent from 'utils/useRoleModelContent.hook';

import {
  Checkbox,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  Select,
  Typography,
} from 'gazprom-ui-lib';

import MultiTagsSelect from 'components/form/multi-tags-select';
import File from 'containers/kedo-review-task-form/file';

import { KedoReviewTaskType } from 'types/review-task.types';
import { GLOBAL_ROLES } from 'types/roles.types';

import s from './KedoReviewTaskFrom.module.scss';
import EmployeeGroupTree from './containers/employee-group-tree';
import {
  KEDO_REVIEW_TASK_FORM_ENUM,
  KEDO_REVIEW_TASK_FORM_NAME,
  KedoReviewTaskFormResponse,
  prepareEmployees,
} from './kedoReviewTaskForm.utils';
import { useKedoReviewTaskFormData } from './useKedoReviewTaskFormData';

interface Props {
  onSubmit: (form: KedoReviewTaskFormResponse) => void;
  kedoReviewTask?: KedoReviewTaskType;
  kedoReviewTaskId?: string;
  disabled?: boolean;
  form?: FormInstance;
}

const KedoReviewTaskForm = (props: Props) => {
  const { onSubmit, kedoReviewTask, kedoReviewTaskId, disabled, form: formFromProps } = props;

  const hasEmployees = !!kedoReviewTask?.employees?.length;
  const [showEmployees, setShowEmployees] = useState(hasEmployees);
  const toggleShowEmployees = () => setShowEmployees((prev) => !prev);

  const hasDivisions = !!kedoReviewTask?.divisions?.length;
  const [showDivisionsTree, setShowDivisionsTree] = useState(hasDivisions);
  const toggleShowDivisionsTree = () => setShowDivisionsTree((prev) => !prev);

  const [form] = Form.useForm();

  const formToUse = formFromProps ?? form;

  useEffect(() => {
    setShowEmployees(hasEmployees);
  }, [hasEmployees]);

  useEffect(() => {
    setShowDivisionsTree(hasDivisions);
  }, [hasDivisions]);

  useEffect(() => {
    if (kedoReviewTask) {
      const date = kedoReviewTask.docDate ? dayjs(kedoReviewTask.docDate) : '';
      formToUse.setFieldsValue({
        [KEDO_REVIEW_TASK_FORM_ENUM.NAME]: kedoReviewTask.name,
        [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME]: kedoReviewTask.docName,
        [KEDO_REVIEW_TASK_FORM_ENUM.INSTANCE_1C]: kedoReviewTask.instance1c?.id,
        [KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED]: kedoReviewTask.needEmployeeSign,
        [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NUMBER]: kedoReviewTask.docNumber,
        [KEDO_REVIEW_TASK_FORM_ENUM.DOC_DATE]: date,
        [KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYER]: kedoReviewTask?.employer?.id,
        [KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEES]:
          kedoReviewTask?.employees?.map(({ id }) => id) ?? [],
        [KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS]:
          kedoReviewTask?.divisions?.map(({ id }) => `${id}.DIVISION`) ?? [],
      });

      if (kedoReviewTask?.files?.length) {
        formToUse.setFieldsValue({
          [KEDO_REVIEW_TASK_FORM_ENUM.FILE]: {
            link: kedoReviewTask?.files[0]?.fileKey,
            name: kedoReviewTask?.files[0]?.fileName,
          },
        });
      }
    }
  }, [formToUse, kedoReviewTask]);

  const [selectedEmployee] = useFullSelectedEmployee();
  const initialEmployerId = selectedEmployee?.employer.id;
  const currentEmployerId = Form.useWatch(KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYER, formToUse);
  const canChangeEmployer = useRoleModelContent({
    roles: [GLOBAL_ROLES.ADMIN],
    content: true,
    fallbackValue: false,
  });

  const resetEmployeeAndDivisionsFields = () => {
    formToUse.setFieldValue(KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEES, []);
    formToUse.setFieldValue(KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS, []);
  };

  const {
    preparedEmployees,
    setEmployeeSearch,
    employeeSearch,
    preparedEmployers,
    isFetchingEmployers,
  } = useKedoReviewTaskFormData({ employerId: currentEmployerId });

  const employeeOptions = removeDuplicateOptions({
    options: [...preparedEmployees, ...prepareEmployees({ employees: kedoReviewTask?.employees })],
  });

  return (
    <Form
      disabled={disabled}
      form={formToUse}
      name={KEDO_REVIEW_TASK_FORM_NAME}
      className={s.wrapper}
      onFinish={onSubmit}>
      <Form.Item
        label={t('common_organization')}
        name={KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYER}
        rules={[formRules.required]}
        initialValue={initialEmployerId}>
        <Select
          onChange={resetEmployeeAndDivisionsFields}
          loading={isFetchingEmployers}
          placeholder={t('common_select_organization')}
          disabled={!canChangeEmployer || disabled}
          options={preparedEmployers}
        />
      </Form.Item>

      <Form.Item
        label={t('kedo_review_tasks_name')}
        className={s.formItemWithLabel}
        normalize={normalizeTextField}
        name={KEDO_REVIEW_TASK_FORM_ENUM.NAME}
        rules={[formRules.required, { max: 80 }]}
        extra={t('kedo_review_tasks_name_subtitle')}>
        <Input disabled={disabled} placeholder={t('common_enter_text')} maxLength={80} showCount />
      </Form.Item>

      <Form.Item
        label={t('kedo_review_tasks_document_name')}
        className={s.formItemWithLabel}
        normalize={normalizeTextField}
        name={KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME}
        rules={[formRules.required, { max: 80 }]}
        extra={t('kedo_review_tasks_document_name_subtitle')}>
        <Input disabled={disabled} placeholder={t('common_enter_text')} maxLength={240} showCount />
      </Form.Item>

      <Flex gap="16" justify="space-between" className={s.document}>
        <Form.Item
          label={t('common_document_number')}
          className={s.formItemWithLabel}
          name={KEDO_REVIEW_TASK_FORM_ENUM.DOC_NUMBER}>
          <Input disabled={disabled} placeholder={t('common_enter_text')} />
        </Form.Item>
        <Form.Item
          label={t('common_document_date')}
          className={s.formItemWithLabel}
          name={KEDO_REVIEW_TASK_FORM_ENUM.DOC_DATE}>
          <DatePicker
            disabled={disabled}
            placeholder={t('common_select_date')}
            format="DD.MM.YYYY"
          />
        </Form.Item>
      </Flex>

      <File
        kedoReviewTaskId={kedoReviewTaskId ?? kedoReviewTask?.id}
        showDelete={!disabled}
        showDownload={!!kedoReviewTask}
      />

      <Form.Item name={KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED} valuePropName="checked">
        <Checkbox>{t('kedo_review_tasks_sign_required')}</Checkbox>
      </Form.Item>

      <Flex vertical gap="24" className={s.marginBottom}>
        <Typography.Text weight="500" size="18">
          {t('documents_task_for')}
        </Typography.Text>

        <Checkbox checked={showEmployees} onChange={toggleShowEmployees}>
          {t('documents_task_for_employees')}
        </Checkbox>

        {showEmployees && (
          <MultiTagsSelect
            disabled={disabled}
            name={KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEES}
            searchValue={employeeSearch}
            onSearch={setEmployeeSearch}
            title=""
            placeholder={t('suggestions_commission_head_choose_placeholder')}
            options={employeeOptions}
            isRequired={false}
          />
        )}

        <Checkbox checked={showDivisionsTree} onChange={toggleShowDivisionsTree}>
          {t('documents_task_for_divisions')}
        </Checkbox>

        {showDivisionsTree && currentEmployerId && (
          <EmployeeGroupTree disabled={disabled} employerId={currentEmployerId} form={formToUse} />
        )}
      </Flex>
    </Form>
  );
};

export default KedoReviewTaskForm;
