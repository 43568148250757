import { useNavigate } from 'react-router-dom';
import { getSalaryProjectEditWithIdRoute } from 'routes/salary-project/list';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { SalaryProjectType } from 'types/salaryProject.types';

import Filters from './containers/filters';
import Header from './containers/header';
import { DEFAULT_FORM_STATE, FormResponseType } from './salaryProject.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const SalaryProject = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const columns = useColumns();

  const { data, isLoading, isFetching } = useGetData({ form });

  const onRow = ({ id }: SalaryProjectType) => ({
    onClick: () => {
      navigate(getSalaryProjectEditWithIdRoute(id));
    },
  });

  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <FiltersV2.Table<SalaryProjectType, FormResponseType>
          isInitialLoading={isLoading}
          rowKey="id"
          dataSource={data}
          total={data?.length}
          loading={isFetching}
          onRow={onRow}
          columns={columns}
          pagination={false}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default SalaryProject;
