import { useCallback, useEffect, useRef, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useParams } from 'react-router-dom';

import { useGetDeclarationAttributeTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { DocumentsDeclarationsAdditionalSectionType } from 'types/documents.types';

import Label from './containers/label';
import Section from './containers/section';
import { getMaxNum } from './documentsDeclarationsAdditional.utils';

const useSectionHandler = () => {
  const { id } = useParams();
  const defaultRef = useRef<boolean>(true);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [newSections, setNewSections] = useState<DocumentsDeclarationsAdditionalSectionType[]>([]);
  const [defaultOpenedId, setDefaultOpenedId] = useState<string>('');

  const { data: attributesData, isLoading } = useGetDeclarationAttributeTypeQuery(id ?? skipToken);

  const lastNum = getMaxNum(newSections);

  const closeModalHandler = useCallback(() => setIsModalOpen(false), []);

  const removeSectionHandler = useCallback(
    (id: number | string) => () => {
      setNewSections((prev) => prev.filter((section) => section.key !== id));
      setIsModalOpen(true);
      defaultRef.current = false;
    },
    [],
  );

  useEffect(() => {
    if (attributesData?.length) {
      const initialAttributesData = [...attributesData];
      const initialSections = initialAttributesData
        .sort((a, b) => a.num - b.num)
        .map((sectionData) => {
          return {
            key: sectionData.id,
            num: sectionData.num,
            isInitial: true,
            label: (
              <Label
                attributeId={sectionData.id}
                title={sectionData.name}
                onClick={removeSectionHandler(sectionData.id)}
              />
            ),
            children: (
              <Section
                id={sectionData.id}
                num={sectionData.num}
                setSections={setNewSections}
                sectionData={sectionData}
                removeHandler={removeSectionHandler(sectionData.id)}
              />
            ),
          };
        });

      setNewSections((prev) => {
        const initialNums = initialSections.map(({ num }) => num);
        const prevAddedSections = prev.filter(
          ({ isInitial, num }) => !isInitial && !initialNums.includes(num),
        );

        return [...initialSections, ...prevAddedSections];
      });
    } else if (!attributesData?.length && defaultRef.current && !isLoading) {
      setDefaultOpenedId('1');
      setNewSections([
        {
          key: '1',
          num: 1,
          isInitial: false,
          label: <Label onClick={removeSectionHandler('1')} />,
          children: (
            <Section
              id="1"
              num={1}
              setSections={setNewSections}
              removeHandler={removeSectionHandler('1')}
            />
          ),
        },
      ]);
    }
  }, [attributesData, closeModalHandler, isLoading, removeSectionHandler]);

  const addSectionHandler = () => {
    setNewSections((prev) => {
      const id = Math.random().toString(36).slice(2, 11);
      const newNum = lastNum + 1;

      return [
        ...prev,
        {
          key: id,
          num: newNum,
          isInitial: false,
          label: <Label onClick={removeSectionHandler(id)} />,
          children: (
            <Section
              id={id.toString()}
              num={newNum}
              setSections={setNewSections}
              removeHandler={removeSectionHandler(id)}
            />
          ),
        },
      ];
    });
  };

  return {
    isModalOpen,
    isLoading,
    sections: newSections,
    addSectionHandler,
    closeModalHandler,
    defaultOpenedId,
  };
};

export default useSectionHandler;
