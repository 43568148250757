import { t } from 'tools/i18n';

import { useGetDeclarationTypesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { useGetEmployerSocialProgramQuery } from 'services/social-program-category/socialProgramCategoryApiService';

import { Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';

import { SalaryProjectType } from 'types/salaryProject.types';

const useColumns = (): TableProps<SalaryProjectType>['columns'] => {
  const { data: employersOptions } = useGetEmployerSocialProgramQuery({ page: 1, size: 500 });
  const { data: declarationOptions } = useGetDeclarationTypesQuery({
    page: 1,
    size: 500,
    isPublished: ['true'],
    creatorType: 'APP',
  });

  const nameRenderer =
    (type: 'employerId' | 'kedoStatementId') =>
    (instance: SalaryProjectType['employerId'] | SalaryProjectType['kedoStatementId']) => {
      let name = instance;
      if (type === 'employerId') {
        const employeeName = employersOptions?.find((item) => item.value === instance)?.label;
        if (employeeName) name = employeeName;
      } else {
        const declarationName = declarationOptions?.items?.find(
          (item) => item.id === instance,
        )?.name;
        if (declarationName) name = declarationName;
      }

      return (
        <Typography.Text type="secondary" weight="500" size="12">
          {name}
        </Typography.Text>
      );
    };

  const dateRenderer = (date: string) => <TableDate date={date} />;

  return [
    {
      title: t('common_program_name'),
      dataIndex: 'name',
    },
    {
      title: t('common_statement_type'),
      dataIndex: 'kedoStatementId',
      render: nameRenderer('kedoStatementId'),
    },
    {
      title: t('common_organization'),
      dataIndex: 'employerId',
      render: nameRenderer('employerId'),
    },
    {
      title: t('common_is_publish'),
      align: 'center' as const,
      width: '80px',
      dataIndex: 'isPublish',
      render: (isPublish: boolean) => {
        return (
          <Icon
            name="okFilled"
            color={`var(${isPublish ? '--color-success' : '--color-placeholder-25'})`}
          />
        );
      },
    },
    {
      title: t('common_created'),
      sorter: true,
      align: 'center' as const,
      dataIndex: 'createdAt',
      render: dateRenderer,
      defaultSortOrder: 'descend',
    },
    {
      title: t('common_updated'),
      sorter: true,
      align: 'center' as const,
      dataIndex: 'updatedAt',
      render: dateRenderer,
    },
  ];
};

export default useColumns;
