import { useEffect, useState } from 'react';

import { t } from 'tools/i18n';

import {
  useGetEmployeeGroupTreeQuery,
  useLazyGetEmployeeGroupTreeQuery,
} from 'services/employee-groups-new/employeeGroupsNewApiService';

import { DataNode, Form, FormInstance, Tree } from 'gazprom-ui-lib';

import { Empty } from 'components/empty';
import { KEDO_REVIEW_TASK_FORM_ENUM } from 'containers/kedo-review-task-form/kedoReviewTaskForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import { OnLoadArguments } from 'types/employeeGroups.types';

import s from './Tree.module.scss';

type EmployeeGroupTreeProps = {
  employerId: string;
  form: FormInstance;
  disabled: boolean | undefined;
};
type UpdateNodeProps = {
  nodes: DataNode[];
  key: string;
  children: DataNode[];
};

const EmployeeGroupTree = ({ employerId, form, disabled }: EmployeeGroupTreeProps) => {
  const { data: initTreeData = [], isFetching: initTreeDataIsFetching } =
    useGetEmployeeGroupTreeQuery({
      parentId: employerId,
      type: 'EMPLOYER',
    });

  const treeIsEmpty = !initTreeData.length;

  const [getTree] = useLazyGetEmployeeGroupTreeQuery();
  const [treeData, setTreeData] = useState<DataNode[]>(initTreeData);
  const defaultCheckedKeys = Form.useWatch(KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS, form);

  useEffect(() => {
    setTreeData(initTreeData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initTreeDataIsFetching]);

  const updateNode = ({ nodes, key, children }: UpdateNodeProps): DataNode[] =>
    nodes.map((node) => {
      if (node.key === key) {
        return { ...node, children };
      }
      if (node.children) {
        return { ...node, children: updateNode({ nodes: node.children, key, children }) };
      }
      return node;
    });

  const handleLoadData = async (treeNode: DataNode) => {
    if (treeNode.children) return;

    const { id, key, type } = treeNode as OnLoadArguments & {
      type: string;
      id: string;
      key: string;
    };

    const childNodes = await getTree({
      parentId: id,
      type,
    }).unwrap();

    setTreeData((prevData) => updateNode({ nodes: prevData, key, children: childNodes }));
  };

  const handleCheck = (checked: string[]) => {
    form.setFieldValue(KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS, checked);
  };

  if (treeIsEmpty && !initTreeDataIsFetching) {
    return <Empty title={t('common_empty_title')} />;
  }

  return (
    <WithLoader isLoading={initTreeDataIsFetching}>
      <Form.Item name={KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS}>
        <Tree
          disabled={disabled}
          className={s.tree}
          checkable
          treeData={treeData}
          defaultCheckedKeys={defaultCheckedKeys}
          checkedKeys={defaultCheckedKeys}
          loadData={handleLoadData}
          onCheck={(checked) => {
            handleCheck(checked as string[]);
          }}
          key="employeeGroupTreeStructure"
          selectable={false}
        />
      </Form.Item>
    </WithLoader>
  );
};

export default EmployeeGroupTree;
