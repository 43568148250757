import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';

import { Flex, Tree, Typography } from 'gazprom-ui-lib';

import { DeclarationParamType, ProcessParamType } from 'types/documents.types';

import { ProcessParamMapType } from '../../tabParameters.utils';
import s from './ParametersTree.module.scss';
import useTreeData from './useTreeData';

interface Props {
  disabled?: boolean;
  processParameters: ProcessParamType[];
  defaultParams: DeclarationParamType[] | undefined;
  setParamValues: Dispatch<SetStateAction<Map<string, ProcessParamMapType>>>;
}

const ParametersTree = (props: Props) => {
  const { processParameters, defaultParams, setParamValues, disabled } = props;

  const { treeData, checkedKeys, handleChangeTree } = useTreeData({
    processParameters,
    defaultParams,
    setParamValues,
    disabled,
  });

  return (
    <Flex vertical gap="16">
      <Typography.Title level={4}>
        {t('documents_declarations_params_stage_title')}
      </Typography.Title>

      <Tree
        disabled={disabled}
        treeData={treeData}
        defaultExpandAll
        selectable={false}
        switcherIcon={false}
        showLine={false}
        checkable
        onCheck={handleChangeTree}
        key="bpmnTree"
        className={s.tree}
        checkedKeys={checkedKeys}
        checkStrictly
      />
    </Flex>
  );
};

export default ParametersTree;
