import { t } from 'tools/i18n';
import { createPersonalFileDownloadUrl } from 'utils/createDownloadUrl.utils';
import { formRules } from 'utils/formRules';

import { Flex, Icon, Tooltip, Typography } from 'gazprom-ui-lib';

import FileField from 'containers/file-field';

import { KEDO_REVIEW_TASK_FORM_ENUM } from '../kedoReviewTaskForm.utils';
import s from './File.module.scss';

interface Props {
  showDownload?: boolean;
  showDelete?: boolean;
  kedoReviewTaskId?: string;
}

const File = (props: Props) => {
  const { showDelete, showDownload, kedoReviewTaskId } = props;

  const fileLabel = (
    <Tooltip
      overlayClassName={s.tooltip}
      placement="bottom"
      align={{ offset: [210, 16] }}
      title={t('common_pfd_a1_convert_file_description')}>
      <Flex className={s.title} align="center" gap="4">
        <Typography.Text weight="500" size="16">
          {t('kedo_review_tasks_documents')}
        </Typography.Text>

        <Icon name="info" color="var(--color-primary-6)" />
      </Flex>
    </Tooltip>
  );

  return (
    <FileField
      maxSize={100}
      kedoReviewTaskId={kedoReviewTaskId}
      getDownloadLink={createPersonalFileDownloadUrl}
      availableTypes={AVAILABLE_TYPES}
      formItemConfig={{
        label: fileLabel,
        name: KEDO_REVIEW_TASK_FORM_ENUM.FILE,
        rules: [formRules.required],
      }}
      previewConfig={{
        downloadWithAuth: true,
        iconName: 'docTypePdf',
        showDelete,
        showDownload,
      }}
      uploadConfig={{
        description: t('validation_upload_a_kedo_review_task_doc_restrictions'),
      }}
      convert
    />
  );
};

const AVAILABLE_TYPES = ['application/pdf'];

export default File;
