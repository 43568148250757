import { FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { getUsersByIdRoute } from 'routes/access-control/list';

import { GetPersonsResponseType } from 'services/person/personApiService.types';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { RolesPersonType } from 'types/person.types';

import { FormResponseType } from '../../accessControlUsers.utils';
import s from './Table.module.scss';
import useColumns from './useColumns.hook';

interface AccessControlUsersTableProps {
  dataSource?: GetPersonsResponseType;
  loading: boolean;
  isInitialLoading: boolean;
  handleOpenAccessCodeModal: ({ personId }: { personId: string }) => void;
}

const Table: FC<AccessControlUsersTableProps> = (props) => {
  const { dataSource, loading, isInitialLoading, handleOpenAccessCodeModal } = props;
  const navigate = useNavigate();

  const columns = useColumns({ handleOpenAccessCodeModal: handleOpenAccessCodeModal });

  const requestBody = Form.useWatch([]);

  const onRow = (record: RolesPersonType) => ({
    onClick: () => navigate(getUsersByIdRoute(record.id), { state: { requestBody } }),
  });

  return (
    <>
      <FiltersV2.Table<RolesPersonType, FormResponseType>
        isInitialLoading={isInitialLoading}
        total={dataSource?.totalCount}
        dataSource={dataSource?.items}
        columns={columns}
        onRow={onRow}
        loading={loading}
        rowKey="id"
        className={s.table}
      />
    </>
  );
};

export default Table;
