import { useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';

import { Button, Flex, FormInstance, IconType } from 'gazprom-ui-lib';

import { COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

import { COMMUNITY_ENTITY_STATUS_FIELD, CommunityEntityKind } from './tabs.utils';

interface Props {
  communityEntityKind: CommunityEntityKind;
  isLoading: boolean;
  triggerSubmit: () => void;
  isMainFormActions?: boolean;
  form: FormInstance;
}

interface CommonButtonsProps {
  rejectApproveLabel: string;
  rejectApproveDanger: boolean;
  rejectApproveStatus: COMMUNITY_ENTITY_STATUS;
  rejectApproveIcon: IconType;
  saveStatus?: COMMUNITY_ENTITY_STATUS;
  saveLabel: string;
}

// Для отображения actions в формах news и meet, чтобы хэндлить UI для предложки (sourceType: MOBILE)
// ! Для формы discussions отдельный хук, т.к. там логика отличается
export const useSuggestedFormActions = (props: Props) => {
  const { communityEntityKind, isLoading, triggerSubmit, isMainFormActions = true, form } = props;

  const navigate = useNavigate();
  const handleClose = () => navigate(-1);

  const getButtons = (props: CommonButtonsProps) => {
    const {
      rejectApproveLabel,
      rejectApproveDanger,
      rejectApproveIcon,
      rejectApproveStatus,

      saveLabel,
      saveStatus,
    } = props;
    return (
      <Flex gap="8">
        <Button
          disabled={isLoading}
          fullWidth={isMainFormActions}
          type="secondary"
          danger={rejectApproveDanger}
          onClick={() => {
            form.setFieldValue(COMMUNITY_ENTITY_STATUS_FIELD, rejectApproveStatus);
            triggerSubmit();
          }}
          leftIcon={rejectApproveIcon}>
          {t(rejectApproveLabel)}
        </Button>
        <Button
          fullWidth={isMainFormActions}
          type="primary"
          leftIcon="ok"
          onClick={() => {
            saveStatus && form.setFieldValue('status', saveStatus);
            triggerSubmit();
          }}
          loading={isLoading}>
          {t(saveLabel)}
        </Button>
      </Flex>
    );
  };

  switch (communityEntityKind) {
    case 'mobileModeration':
      return getButtons({
        rejectApproveLabel: 'thank_you_reject',
        rejectApproveDanger: true,
        rejectApproveIcon: 'delete',
        rejectApproveStatus: COMMUNITY_ENTITY_STATUS.REJECTED,

        saveLabel: 'community_suggested_save_approve',
        saveStatus: COMMUNITY_ENTITY_STATUS.ACCEPTED,
      });
    case 'mobileAccepted':
      return getButtons({
        rejectApproveLabel: 'thank_you_reject',
        rejectApproveDanger: true,
        rejectApproveIcon: 'delete',
        rejectApproveStatus: COMMUNITY_ENTITY_STATUS.REJECTED,

        saveLabel: 'common_save',
      });
    case 'mobileRejected':
      return getButtons({
        rejectApproveLabel: 'thank_you_approve',
        rejectApproveDanger: false,
        rejectApproveIcon: 'publish',
        rejectApproveStatus: COMMUNITY_ENTITY_STATUS.ACCEPTED,

        saveLabel: 'common_save',
      });
    // Default, когда sourceType: ADMIN (не предложка)
    default:
      if (isMainFormActions) {
        return (
          <Flex gap="8">
            <Button type="secondary" onClick={handleClose} fullWidth>
              {t('common_close')}
            </Button>

            <Button
              type="primary"
              leftIcon="ok"
              onClick={triggerSubmit}
              loading={isLoading}
              fullWidth>
              {t('common_save')}
            </Button>
          </Flex>
        );
      } else {
        return (
          <Button type="primary" leftIcon="ok" onClick={triggerSubmit} loading={isLoading}>
            {t('common_save')}
          </Button>
        );
      }
  }
};
