import { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';

import { GetKedoReviewTasksPropsType } from 'services/kedo-review-tasks/kedoReviewTasksApiService.types';

import { PaginationPropsType } from 'types/pagination.types';
import { REVIEW_TASK_TYPES_ENUM } from 'types/review-task.types';
import { SORT_ENUM } from 'types/sort.types';

export const getKedoReviewTaskDetailsTitle = (kedoReviewTaskStatus?: string): string => {
  if (kedoReviewTaskStatus === REVIEW_TASK_TYPES_ENUM.REVIEW_CREATED) {
    return t('kedo_review_tasks_details_edit_title');
  }

  return t('kedo_review_tasks_details_title');
};

export type FiltersType = Pick<GetKedoReviewTasksPropsType, 'status'>;

export type FormResponseType = PaginationPropsType & {
  order?: SORT_ENUM;
  property?: string | null;
  search?: string;
  range?: [Dayjs, Dayjs];
  filters?: {
    status?: string[];
  };
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'sentDate',
};

export const getDivisionIds = (checked: string[] = []) =>
  checked.reduce<string[]>((acc, divisionKey) => {
    if (!divisionKey) return acc;
    const divisionId = divisionKey.split('.')[0];
    if (divisionId) acc.push(divisionId);
    return acc;
  }, []);
