import { useParams } from 'react-router-dom';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useSaveAndSendRecruitByIdMutation,
  useSaveRecruitByIdMutation,
} from 'services/recruit/recruitApiService';
import { SaveRecruitByIdPropsType } from 'services/recruit/recruitApiService.types';

import { RecruitDocumentType } from 'types/recruit.types';

import { ADDITIONAL_DOCUMENTS_ENUM, AddDocumentFormResponse } from '../../utils';

interface Props {
  docInfo?: RecruitDocumentType | null;
  onCancel: () => void;
}

const useSave = (props: Props) => {
  const { docInfo, onCancel } = props;
  const { id } = useParams<{ id: string }>();

  const [save, { isLoading: isSaveLoading }] = useSaveRecruitByIdMutation();
  const [saveAndSend, { isLoading: isSaveAndSendLoading }] = useSaveAndSendRecruitByIdMutation();

  const isLoading = isSaveLoading || isSaveAndSendLoading;

  const handleAfterSave = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleSave = (values: AddDocumentFormResponse, isStartBpmnProcess: 'true' | 'false') => {
    const { link, name } = values[ADDITIONAL_DOCUMENTS_ENUM.FILE] ?? {};

    const body: SaveRecruitByIdPropsType['body'] = {
      name: values[ADDITIONAL_DOCUMENTS_ENUM.NAME],
      docNumber: values[ADDITIONAL_DOCUMENTS_ENUM.DOC_NUMBER],
      docDate: values[ADDITIONAL_DOCUMENTS_ENUM.DOC_DATE],
      docType: values[ADDITIONAL_DOCUMENTS_ENUM.DOC_TYPE],
      signerId: values[ADDITIONAL_DOCUMENTS_ENUM.SGNER_ID],
      file: { fileName: name, fileKey: link },
      kedoDocumentId: values[ADDITIONAL_DOCUMENTS_ENUM.KEDO_DOCUMENT_UUID],
    };

    if (id) {
      save({
        id,
        body,
        params: {
          isStartBpmnProcess,
        },
      })
        .then(handleAfterSave)
        .then(onCancel);
    }
  };

  const handleSaveAndSend = (values: AddDocumentFormResponse) => {
    const kedoDocumentId = docInfo?.document.id;
    const recruitLinkKedoDocumentId = docInfo?.id;

    const { link, name } = values[ADDITIONAL_DOCUMENTS_ENUM.FILE] ?? {};

    const body: SaveRecruitByIdPropsType['body'] = {
      name: values[ADDITIONAL_DOCUMENTS_ENUM.NAME],
      docNumber: values[ADDITIONAL_DOCUMENTS_ENUM.DOC_NUMBER],
      docDate: values[ADDITIONAL_DOCUMENTS_ENUM.DOC_DATE],
      docType: values[ADDITIONAL_DOCUMENTS_ENUM.DOC_TYPE],
      signerId: values[ADDITIONAL_DOCUMENTS_ENUM.SGNER_ID],
      file: { fileName: name, fileKey: link },
      kedoDocumentId: kedoDocumentId!,
    };

    if (id && recruitLinkKedoDocumentId && kedoDocumentId) {
      saveAndSend({
        id,
        body,
        params: {
          kedoDocumentId: kedoDocumentId,
          recruitLinkKedoDocumentId,
          isStartBpmnProcess: 'true',
        },
      })
        .then(handleAfterSave)
        .then(onCancel);
    }
  };

  return {
    handleSave,
    handleSaveAndSend,
    isLoading,
  };
};

export default useSave;
