import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import useRoleModelContent from 'utils/useRoleModelContent.hook';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import {
  editCommonDeclarationRoles,
  editLocalDeclarationRoles,
} from 'permissions/declarations.types';

interface Props {
  type?: DECLARATIONS_TYPE_TAB_ENUM;
}

const useDeclarationRoleModel = (props: Props) => {
  const { type } = props;
  const { id, declarationTypeTab } = useParams<{
    id: string;
    declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM;
  }>();
  const { data } = useGetDeclarationTypeQuery(id ?? skipToken);

  const typeToUse = type ?? declarationTypeTab;

  const isAdmin = useRoleModelContent({
    content: true,
    fallbackValue: false,
    roles: editCommonDeclarationRoles,
  });
  const isLocalAdmin = useRoleModelContent({
    content: true,
    fallbackValue: false,
    roles: editLocalDeclarationRoles,
  });
  const isEditMode = !!id;

  if (typeToUse === DECLARATIONS_TYPE_TAB_ENUM.COMMON) {
    if (isEditMode) {
      return {
        orgDisabled: !isAdmin,
        commonDisabled: !isAdmin,
        parametersDisabled: !isAdmin,
        additionalFieldsDisabled: !isAdmin,
        templateDisabled: !isAdmin,
      };
    } else {
      return {
        orgDisabled: false,
        commonDisabled: false,
        parametersDisabled: false,
        additionalFieldsDisabled: false,
        templateDisabled: false,
      };
    }
  } else {
    if (isEditMode) {
      if (data?.defaultStatementId) {
        return {
          orgDisabled: true,
          commonDisabled: true,
          parametersDisabled: !isLocalAdmin,
          additionalFieldsDisabled: !isLocalAdmin,
          templateDisabled: !isLocalAdmin,
        };
      } else {
        return {
          orgDisabled: true,
          commonDisabled: !isLocalAdmin,
          parametersDisabled: !isLocalAdmin,
          additionalFieldsDisabled: !isLocalAdmin,
          templateDisabled: !isLocalAdmin,
        };
      }
    } else {
      return {
        orgDisabled: false,
        commonDisabled: false,
        parametersDisabled: false,
        additionalFieldsDisabled: false,
        templateDisabled: false,
      };
    }
  }
};

export default useDeclarationRoleModel;
