import { Dispatch, SetStateAction } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useUpdateCommunityDiscussionMutation } from 'services/communities/communitiesApiService';

import { Button, Dropdown, DropdownProps, HandleItemClickType } from 'gazprom-ui-lib';

import { CommunityDiscussionType } from 'types/community.types';
import { COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

import { CommunityEntityKind } from '../../../../../tabs.utils';

interface Props {
  communityEntityKind: CommunityEntityKind;
  discussion: CommunityDiscussionType | undefined;
  setIsCreateCommentModalVisible: Dispatch<SetStateAction<boolean>>;
}

enum DROPDOWN_ACTIONS {
  REJECT = 'REJECT',
  CLOSE = 'CLOSE',
}

const getDropdownItems = (canComment: boolean): DropdownProps['items'] => [
  { key: DROPDOWN_ACTIONS.REJECT, label: t('community_discussion_reject') },
  {
    key: DROPDOWN_ACTIONS.CLOSE,
    label: t(canComment ? 'community_discussion_close' : 'community_discussion_open'),
  },
];

export const useActions = ({
  communityEntityKind,
  discussion,
  setIsCreateCommentModalVisible,
}: Props) => {
  const { id } = useParams() as { id: string };
  const [update, { isLoading }] = useUpdateCommunityDiscussionMutation();

  if (!discussion) return null;

  const createUpdatePayload = (updates: Partial<typeof discussion>) => ({
    communityId: id,
    discussionId: discussion.id,
    title: discussion.title,
    isPublish: discussion.isPublish,
    canComment: discussion.canComment,
    status: discussion.status,
    ...updates,
  });

  const handleDropdownClick: HandleItemClickType = (value) => {
    const { key } = value as { key: DROPDOWN_ACTIONS };
    const payload =
      key === DROPDOWN_ACTIONS.REJECT
        ? createUpdatePayload({ status: COMMUNITY_ENTITY_STATUS.REJECTED })
        : createUpdatePayload({ canComment: !discussion.canComment });
    update(payload);
  };

  const commentsBtn = (
    <Button type="primary" leftIcon="sms" onClick={() => setIsCreateCommentModalVisible(true)}>
      {t('community_discussion_comment')}
    </Button>
  );

  const dropdown = (
    <Dropdown handleItemClick={handleDropdownClick} items={getDropdownItems(discussion.canComment)}>
      <Button onlyIcon type="secondary" leftIcon="moreHorizontal" />
    </Dropdown>
  );

  switch (communityEntityKind) {
    case 'mobileAccepted':
      return (
        <>
          {commentsBtn}
          {dropdown}
        </>
      );

    case 'mobileRejected':
      return (
        <Button
          loading={isLoading}
          onClick={() => update(createUpdatePayload({ status: COMMUNITY_ENTITY_STATUS.ACCEPTED }))}
          leftIcon="publish">
          {t('common_approve')}
        </Button>
      );

    default:
      return (
        <>
          <Button
            type="secondary"
            danger={discussion.canComment}
            leftIcon={discussion.canComment ? 'delete' : 'ok'}
            onClick={() => update(createUpdatePayload({ canComment: !discussion.canComment }))}>
            {discussion.canComment
              ? t('community_discussion_close')
              : t('community_discussion_open')}
          </Button>
          {commentsBtn}
        </>
      );
  }
};
