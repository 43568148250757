import { t } from 'tools/i18n';

export const formRules = {
  required: {
    required: true,
    message: t('validation_field_not_empty'),
  },
  email: {
    pattern: /\S+@\S+\.\S+/,
    message: t('validation_field_email'),
    validateTrigger: ['onBlur'],
  },
  url: {
    pattern: /^(https?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/,
    message: t('validation_field_url'),
    validateTrigger: ['onBlur'],
  },
};

export const normalizeTextField = (text: string) => text.trimStart();

export const normalizeToNumbers = (text: string) => text.replace(/[^+\d]/g, '');

export const normalizeNumberInput = (maxValue?: number, round?: number) => (e: string | number) => {
  let normalizedValue = String(e);
  if (!round) {
    normalizedValue = normalizedValue.split('.').shift()!;
  }

  const numberAmount = Number(normalizedValue);

  if (numberAmount < 0) {
    return 0;
  }

  if (maxValue) {
    return maxValue < numberAmount ? maxValue : numberAmount;
  }

  return round ? numberAmount : numberAmount.toFixed(round);
};
