import { EmployeeType } from './employee.types';
import { FileType } from './file.types';
import { KedoPersonType } from './person.types';

export enum REVIEW_TASK_TYPES_ENUM {
  REVIEW_CREATED = 'REVIEW_CREATED',
  REVIEW_ASSIGNED = 'REVIEW_ASSIGNED',
  REVIEW_CANCELED = 'REVIEW_CANCELED',
  REVIEW_READY = 'REVIEW_READY',
  LNA_CREATED = 'LNA_CREATED',
  LNA_CANCELED = 'LNA_CANCELED',
  LNA_VALID = 'LNA_VALID',
}

export type ReviewTaskFileType = {
  fileKey: string;
  fileName: string;
};

export type KedoReviewTaskType = {
  id: string;
  name: string;
  docName: string;
  docDate: string;
  docNumber: string;
  creator: EmployeeType;
  editor: EmployeeType;
  createDate: string;
  updateDate: string;
  sentDate: string;
  status: {
    code: string;
    description: null | string;
    isFilter: boolean;
    name: string;
    nameOwner: string;
    state: string;
    type: string;
  };
  needEmployeeSign: boolean;
  files: ReviewTaskFileType[];
  instance1c?: { id: string; name: string };
  employer: { id: string; name: string };
  employees?: EmployeeType[];
  divisions?: { id: string; name: string }[];
};

export type ExecutorType = {
  id: string;
  person: KedoPersonType;
  staff: {
    id: string;
    name: string;
  };
  position: {
    id: string;
    name: string;
  };
  division: {
    id: string;
    name: string;
    parentId: string;
  };
  employer: {
    id: string;
    name: string;
  };
  employmentType: string;
  employmentDate: string;
  isHead: boolean;
};

export enum KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM {
  WAITING = 'WAITING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED',
}

export type ReviewTaskAssignmentType = {
  id: string;
  num: number | null;
  type: string;
  createdAt: string;
  status: KEDO_REVIEW_TASK_ASSIGNEE_STATUS_ENUM;
  finishDate: string | null;
  taskId: string | null;
  kedoReviewTaskId: string;
  comment: string | null;
  title: string;
  executor: ExecutorType | null;
  files: FileType[];
  assignmentId?: string;
};
