import { useState } from 'react';

import { useVisibility } from 'utils/useVisibility.hook';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { CommunityInviteType } from 'types/communityInvite.types';
import { CommunitySubscriberType } from 'types/communitySubscriber.types';

import { FormResponseType } from '../../communityManagementSubscribers.utils';
import BlockModal from '../block-modal';
import SubscriberDetailsModal from '../subscriber-details-modal';
import {
  COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_MAP,
  CommunityManagementSubscribersEnumType,
} from '../tabs/tabs.utils';
import UnblockModal from '../unblock-modal';
import s from './Table.module.scss';
import { useGetTabTableColumns } from './useGetTabTableColumns.hook';

type TableProps = {
  dataSource?: CommunitySubscriberType[] | CommunityInviteType[];
  loading?: boolean;
  totalCount?: number;
  isInitialLoading: boolean;
  hasFiltersApplied: boolean;
  tabType: CommunityManagementSubscribersEnumType;
  communityId: string;
};

type ModalType = 'block' | 'unblock';

export type HandleOpenBlockUnblockModalProps = {
  subscriber: CommunitySubscriberType | CommunityInviteType;
  modalType: ModalType;
};

const Table = ({ tabType, communityId, ...rest }: TableProps) => {
  const [selectedSubscriber, setSelectedSubscriber] = useState<
    CommunitySubscriberType | CommunityInviteType | null
  >(null);
  const [currentModal, setCurrentModal] = useState<ModalType | null>(null);
  const [blockUnblockModalOpened, { open: openBlockUnblockModal, close: closeBlockUnblockModal }] =
    useVisibility();

  const [
    subscriberDetailsModalOpened,
    { open: openSubscriberDetailsModal, close: closeSubscriberDetailsModal },
  ] = useVisibility();

  const handleCloseBlockUnblockModal = () => {
    closeBlockUnblockModal();
    setCurrentModal(null);
    !blockUnblockModalOpened && setSelectedSubscriber(null);
  };

  const handleOpenBlockUnblockModal = ({
    modalType,
    subscriber,
  }: HandleOpenBlockUnblockModalProps) => {
    !selectedSubscriber && setSelectedSubscriber(subscriber);
    setCurrentModal(modalType);
    openBlockUnblockModal();
  };

  const handleOpenSubscriberDetailsModal = (
    subscriber: CommunitySubscriberType | CommunityInviteType,
  ) => {
    setSelectedSubscriber(subscriber);
    openSubscriberDetailsModal();
  };

  const handleCloseSubscriberDetailsModal = () => {
    closeSubscriberDetailsModal();
    setSelectedSubscriber(null);
  };

  // Закрываем все модалки при block/unblock мутации
  const onSuccessBlockUnblockMutation = () => {
    closeBlockUnblockModal();
    closeSubscriberDetailsModal();
    setCurrentModal(null);
    setSelectedSubscriber(null);
  };

  const handleOnRow = (subscriber: CommunitySubscriberType | CommunityInviteType) => ({
    onClick: () => {
      handleOpenSubscriberDetailsModal(subscriber);
    },
  });

  const columns = useGetTabTableColumns({
    tabType,
    handleOpenBlockUnblockModal,
  });

  const locale = {
    emptyText: () => (
      <Empty
        title={COMMUNITY_MANAGEMENT_SUBSCRIBERS_TABS_MAP[tabType].getEmptyTitle(
          rest.hasFiltersApplied,
        )}
        description={`${
          rest.hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''
        }`}
      />
    ),
  };

  return (
    <>
      <FiltersV2.Table<CommunitySubscriberType | CommunityInviteType, FormResponseType>
        className={s.table}
        onRow={handleOnRow}
        rowKey="id"
        columns={columns}
        locale={locale}
        {...rest}
      />
      {selectedSubscriber && (
        <>
          {currentModal === 'block' && (
            <BlockModal
              open={blockUnblockModalOpened}
              close={handleCloseBlockUnblockModal}
              subscriberId={selectedSubscriber.id}
              communityId={communityId}
              onSuccessBlockUnblockMutation={onSuccessBlockUnblockMutation}
            />
          )}
          {currentModal === 'unblock' && (
            <UnblockModal
              open={blockUnblockModalOpened}
              close={handleCloseBlockUnblockModal}
              subscriberId={selectedSubscriber.id}
              communityId={communityId}
              onSuccessBlockUnblockMutation={onSuccessBlockUnblockMutation}
            />
          )}
          <SubscriberDetailsModal
            tabType={tabType}
            openBlockUnblockModal={handleOpenBlockUnblockModal}
            open={subscriberDetailsModalOpened}
            close={handleCloseSubscriberDetailsModal}
            subscriber={selectedSubscriber}
          />
        </>
      )}
    </>
  );
};

export default Table;
