import { useState } from 'react';

import { Tabs } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import Header from './containers/header';
import { useFormActions } from './useFormActions.hook';
import { useFormHandlers } from './useFormHandlers.hook';
import { useGetSurvey } from './useGetSurvey.hook';
import { SURVEY_SETTLEMENT_TABS_KEYS, useGetTabs } from './useGetTabs.hook';

const SurveysSettlementAddUpdate = () => {
  const [activeTab, setActiveTab] = useState<string>(SURVEY_SETTLEMENT_TABS_KEYS.STATS);
  const { survey, isLoading } = useGetSurvey();

  const {
    isSubmittingForm,
    handleFinish,
    isPublishModalOpen,
    handlePublish,
    handleClosePublishModal,
    handleOpenPublishModal,
  } = useFormHandlers();

  const formActions = useFormActions({
    isSubmittingForm,
    handleClosePublishModal,
    handleOpenPublishModal,
    handlePublish,
    isPublishModalOpen,
  });

  const items = useGetTabs({ handleFinish, formActions });

  const shouldRenderWithStats = survey && survey.isPublish;

  const contents = shouldRenderWithStats ? (
    <Tabs items={items} type="card" onTabClick={setActiveTab} activeKey={activeTab} />
  ) : (
    items[1].children
  );

  return (
    <WithLoader isLoading={isLoading}>
      <Header isSubmittingForm={isSubmittingForm} />
      {contents}
    </WithLoader>
  );
};

export default SurveysSettlementAddUpdate;
