import { t } from 'tools/i18n';

import FileField from 'containers/file-field';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

const Cover = () => {
  return (
    <FileField
      formItemConfig={{
        label: FORM_LABELS[EVENT_FORM_ENUM.COVER],
        name: EVENT_FORM_ENUM.COVER,
      }}
      previewConfig={{
        showDelete: true,
        showDownload: true,
      }}
      uploadConfig={{
        description: t('validation_upload_an_image_restrictions'),
      }}
    />
  );
};

export default Cover;
