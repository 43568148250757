import dayjs from 'dayjs';
import { t } from 'tools/i18n';
import { getFio } from 'utils/getFio.utils';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import { CommunityEntityKind } from '../../tabs.utils';
import { CommunitySuggestedEntityType } from '../suggested/suggestedTab.utils';
import StatusBadge from './containers/status-badge';

interface Props {
  entity: CommunitySuggestedEntityType;
  communityEntityKind: CommunityEntityKind;
}

const SuggestedHeaderInfo = (props: Props) => {
  const { entity, communityEntityKind } = props;

  if (communityEntityKind === 'admin') return null;

  const {
    createdAt,
    creator: {
      person: { firstName, lastName },
    },
  } = entity;

  return (
    <Flex gap="16">
      <StatusBadge communityEntityKind={communityEntityKind} />
      <Flex gap="16">
        <Flex gap="8" align="center">
          <Typography.Text type="secondary" size="14" weight="400">
            {t('community_suggested')}:
          </Typography.Text>
          <Typography.Text type="secondary" size="14" weight="400">
            <Flex gap="8">
              <Icon name="calendar" />
              {dayjs(createdAt).format('DD MMMM YYYY, hh:mm')}
            </Flex>
          </Typography.Text>
        </Flex>

        <Typography.Text type="secondary" size="14" weight="400">
          <Flex gap="8">
            <Icon name="accountCircle" />
            {getFio({ firstName, lastName, type: 'firstNameLastName' })}
          </Flex>
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default SuggestedHeaderInfo;
