import { t } from 'tools/i18n';

import { Flex, Form, Icon, Select, Typography } from 'gazprom-ui-lib';

import {
  COMMUNITY_MEETS_FORM_ENUM,
  REPEAT_INTERVAL_OPTIONS,
} from 'containers/community-meet-form/communityMeetForm.utils';

import s from './RepeatInterval.module.scss';

const RepeatInterval = () => {
  const form = Form.useFormInstance();
  const value = Form.useWatch(COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL, form);
  return (
    <Form.Item
      label={
        <Flex gap="4" align="center">
          <Typography.Text size="16" weight="500">
            {t('community_meets_repeat_interval')}
          </Typography.Text>
          <Typography.Text type="secondary">{t('common_optional')}</Typography.Text>
        </Flex>
      }
      name={COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL}>
      <Select
        value={value}
        allowClear
        // Without beubg controlled dependent fields don't react
        onChange={(value) => form.setFieldValue(COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL, value)}
        placeholder={t('common_choose_interval')}
        options={REPEAT_INTERVAL_OPTIONS}
      />
      <Flex gap="4" className={s.wrapper} align="end">
        <Icon name="info" color="var(--color-primary-6)" />
        <Typography.Text type="secondary">
          {t('community_meets_repeat_description')}
        </Typography.Text>
      </Flex>
    </Form.Item>
  );
};

export default RepeatInterval;
