import cn from 'classnames';
import { createMediaDownloadUrl } from 'utils/createMediaUrl.utils';

import { Icon } from 'gazprom-ui-lib';

import s from './Cover.module.scss';

interface Props {
  alt: string;
  src?: string;
  className?: string;
}

const Cover = (props: Props) => {
  const { className, src, alt } = props;

  if (!src) {
    return (
      <div className={cn(s.wrapper, s.noData, className)}>
        <Icon name="noImage" size={40} color="var(--color-primary-5)" />
      </div>
    );
  }

  return <img src={createMediaDownloadUrl(src)} alt={alt} className={cn(s.wrapper, className)} />;
};

export default Cover;
