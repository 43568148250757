import { prepareQueryParams } from 'utils/prepareQueryParams';

import initialApiService from 'services/initialApiService';

import {
  CreateAttributesPropsType,
  CreateDeclarationTypesPropsType,
  GetBPMNProcessesResponseType,
  GetCategoriesMappedType,
  GetCategoriesPropsType,
  GetCategoriesResponseType,
  GetDeclarationAttributePropsType,
  GetDeclarationAttributeResponseType,
  GetDeclarationAttributeVariablesPropsType,
  GetDeclarationAttributeVariablesResponseType,
  GetDeclarationPropsType,
  GetDeclarationResponseType,
  GetDeclarationsPropsTypes,
  GetDeclarationsResponseTypes,
  GetDeclarationsWithOrgPropsTypes,
  GetDeclarationsWithOrgResponseTypes,
  GetEmployeePropsType,
  GetEmployeeResponseType,
  PatchDeclarationTypesRequestType,
  PostBPMNProcessPropsType,
  RemoveAttributeResponseType,
  RemoveTemplatePropsType,
  UpdateDeclarationTypesPropsType,
  UploadTemplateType,
  removeAttributePropsType,
} from './documentsDeclarationTypesApiService.types';
import { SERVICE_TAGS } from 'constants/serviceTags';
import { DeclarationsTypes } from 'types/documents.types';

const documentsDeclarationTypesApiService = initialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getDeclarationTypes: builder.query<GetDeclarationsResponseTypes, GetDeclarationsPropsTypes>({
      query: (params) => ({
        url: `kedo/statement${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_DECLARATION_TYPES],
    }),
    getDeclarationTypesWithOrg: builder.query<
      GetDeclarationsWithOrgResponseTypes,
      GetDeclarationsWithOrgPropsTypes
    >({
      query: (params) => ({
        url: `kedo/statement-with-org${prepareQueryParams(params)}`,
      }),
      providesTags: [SERVICE_TAGS.GET_DECLARATION_TYPES_WITH_ORG],
    }),
    createDeclarationTypes: builder.mutation<DeclarationsTypes, CreateDeclarationTypesPropsType>({
      query: (body) => {
        return {
          url: `kedo/statement`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [SERVICE_TAGS.GET_DECLARATION_TYPES],
    }),
    patchDeclarationTypes: builder.mutation<DeclarationsTypes, PatchDeclarationTypesRequestType>({
      query: ({ id, body }) => {
        return {
          url: `kedo/statement/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [SERVICE_TAGS.GET_DECLARATION_TYPE_BY_ID],
    }),
    updateDeclarationTypes: builder.mutation<DeclarationsTypes, UpdateDeclarationTypesPropsType>({
      query: ({ id, body }) => {
        return {
          url: `kedo/statement/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        SERVICE_TAGS.GET_DECLARATION_TYPES,
        SERVICE_TAGS.GET_DECLARATION_TYPE_BY_ID,
      ],
    }),
    getCategories: builder.query<GetCategoriesMappedType, GetCategoriesPropsType>({
      query: (params) => ({
        url: `kedo/category${prepareQueryParams(params)}`,
      }),
      transformResponse: (response: GetCategoriesResponseType) => {
        return response.map((category) => ({ label: category.name, value: category.id }));
      },
      providesTags: [SERVICE_TAGS.GET_DECLARATION_TYPES],
    }),
    getDeclarationType: builder.query<GetDeclarationResponseType, GetDeclarationPropsType>({
      query: (id) => ({
        url: `kedo/statement/${id}`,
      }),
      providesTags: [SERVICE_TAGS.GET_DECLARATION_TYPE_BY_ID],
    }),
    getDeclarationAttributeType: builder.query<
      GetDeclarationAttributeResponseType,
      GetDeclarationAttributePropsType
    >({
      query: (id) => ({
        url: `kedo/statement/${id}/attribute`,
      }),
      providesTags: [SERVICE_TAGS.GET_DECLARATION_ATTRIBUTES],
    }),
    getDeclarationAttributeVariables: builder.query<
      GetDeclarationAttributeVariablesResponseType,
      GetDeclarationAttributeVariablesPropsType
    >({
      query: (statementId) => ({
        url: `kedo/statement/attribute-variables/${statementId}`,
      }),
    }),
    removeAttribute: builder.mutation<RemoveAttributeResponseType, removeAttributePropsType>({
      query: ({ id, attributeId }) => ({
        url: `kedo/statement/${id}/attribute/${attributeId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_DECLARATION_ATTRIBUTES],
    }),
    getEmployee: builder.query<GetEmployeeResponseType, GetEmployeePropsType>({
      query: (params) => ({
        url: `employee${prepareQueryParams(params)}`,
      }),
    }),
    createAttributes: builder.mutation<RemoveAttributeResponseType, CreateAttributesPropsType>({
      query: ({ id, attributes }) => ({
        url: `kedo/statement/${id}/attributes-batch`,
        method: 'POST',
        body: attributes,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_DECLARATION_ATTRIBUTES],
    }),
    uploadTemplate: builder.mutation<void, UploadTemplateType>({
      query: ({ statementId, file }) => ({
        url: `kedo/statement/${statementId}/template`,
        method: 'POST',
        body: file,
      }),
      invalidatesTags: [SERVICE_TAGS.GET_DECLARATION_TYPE_BY_ID],
    }),
    removeTemplate: builder.mutation<void, RemoveTemplatePropsType>({
      query: ({ statementId, templateId }) => ({
        url: `kedo/statement/${statementId}/template/${templateId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [SERVICE_TAGS.GET_DECLARATION_TYPE_BY_ID],
    }),
    getBPMNProcesses: builder.query<GetBPMNProcessesResponseType, void>({
      query: () => ({
        url: `bpmn/process`,
      }),
    }),
    postBPMNProcess: builder.mutation<void, PostBPMNProcessPropsType>({
      query: ({ id, body }) => ({
        url: `bpmn/statement/${id}`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const getDeclarationTypeSelector =
  documentsDeclarationTypesApiService.endpoints.getDeclarationType;

export const {
  useLazyGetDeclarationTypesWithOrgQuery,
  useLazyGetDeclarationTypesQuery,
  useGetDeclarationTypesQuery,
  useGetCategoriesQuery,
  useLazyGetCategoriesQuery,
  useGetDeclarationTypeQuery,
  useLazyGetDeclarationTypeQuery,
  useGetDeclarationAttributeTypeQuery,
  useGetDeclarationAttributeVariablesQuery,
  useCreateDeclarationTypesMutation,
  useRemoveAttributeMutation,
  useGetEmployeeQuery,
  useCreateAttributesMutation,
  useUpdateDeclarationTypesMutation,
  useUploadTemplateMutation,
  useRemoveTemplateMutation,
  usePatchDeclarationTypesMutation,
  useGetBPMNProcessesQuery,
  usePostBPMNProcessMutation,
} = documentsDeclarationTypesApiService;
