import { t } from 'tools/i18n';

import { TableProps } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';
import TableText from 'components/table/table-text';

import { COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

import { CommunitySuggestedEntityType } from './suggestedTab.utils';

const SUGGESTED_STATUS_MAP: Record<COMMUNITY_ENTITY_STATUS, STATUSES> = {
  ACCEPTED: STATUSES.DONE,
  MODERATION: STATUSES.MODERATION,
  REJECTED: STATUSES.DECLINED,
};

export const useColumns = (): TableProps<CommunitySuggestedEntityType>['columns'] => {
  return [
    {
      title: t('common_author'),
      dataIndex: 'creator',
      render: (
        _,
        {
          creator: {
            person: { firstName, lastName },
            division: { name },
          },
        },
      ) => <TablePerson firstName={firstName} lastName={lastName} department={name} />,
    },
    {
      title: t('common_staffName'),
      dataIndex: 'creator',
      render: (
        _,
        {
          creator: {
            position: { name },
          },
        },
      ) => (
        <TableText type="secondary" weight="500">
          {name}
        </TableText>
      ),
    },
    {
      title: t('common_title'),
      dataIndex: 'title',
      render: (title: string) => (
        <TableText type="secondary" weight="500">
          {title}
        </TableText>
      ),
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      render: (status: COMMUNITY_ENTITY_STATUS) => (
        <TableStatus status={SUGGESTED_STATUS_MAP[status]} />
      ),
    },
    {
      title: t('common_updated'),
      sorter: true,
      align: 'center',
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend',
      render: (updatedAt: string) => <TableDate date={updatedAt} />,
    },
    {
      title: t('common_created'),
      sorter: true,
      align: 'center',
      dataIndex: 'createdAt',
      render: (createdAt: string) => <TableDate date={createdAt} />,
    },
  ];
};
