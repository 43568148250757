import { MouseEventHandler } from 'react';

import { t } from 'tools/i18n';

import { Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import StageDeadline from '../stage-deadline';

interface Props {
  title: string;
  name: string;
  isStage: boolean;
  disabled?: boolean;
  isActive?: boolean;
  type: string;
}

const ParentRenderer = (props: Props) => {
  const { title, isStage, name, type, disabled } = props;

  const handleClick: MouseEventHandler<HTMLInputElement> = (e) => e.stopPropagation();

  return (
    <Flex vertical gap="8">
      <Typography.Title level={5}>{title}</Typography.Title>
      {isStage && <StageDeadline name={`${name}.numberOfDays`} disabled={disabled} />}
      {type === 'STRING' && (
        <Form.Item name={name}>
          <Input onClick={handleClick} placeholder={t('common_enter_text')} disabled={disabled} />
        </Form.Item>
      )}
    </Flex>
  );
};

export default ParentRenderer;
