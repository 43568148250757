import { useEffect, useMemo, useState } from 'react';

import { t } from 'tools/i18n';

import { Button, Form, Icon, Modal, Typography } from 'gazprom-ui-lib';

import s from './ImageModal.module.scss';
import ImageModalUpload from './image-modal-upload';
import ImageModalUrl from './image-modal-url';
import { FORM_IMAGE_ENUM, FormImageResponse, IMPORT, TABS, URL } from './imageModal.utils';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (url: FormImageResponse['URL'], formResponse: FormImageResponse) => void;
  uploadDescription?: string;
  availableTypes?: string[];
  title?: string;
  uploadTitle?: string;
  maxLimitSizeMB?: number;
  kedoReviewTaskId?: string;
  isPersonalFile?: boolean;
  customUploadUrl?: string;
  customMetaUrl?: string;
}

const ImageModal = (props: Props) => {
  const {
    isPersonalFile,
    kedoReviewTaskId,
    isOpen,
    onClose,
    onSubmit,
    uploadDescription,
    uploadTitle,
    availableTypes,
    maxLimitSizeMB,
    customUploadUrl,
    customMetaUrl,
    title = t('common_upload_a_file'),
  } = props;

  const [activeTab, setActiveTab] = useState<string>(DEFAULT_ACTIVE_TAB);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!isOpen && form) {
      setActiveTab(DEFAULT_ACTIVE_TAB);
      form.resetFields();
    }
  }, [form, isOpen]);

  const handleSubmit = (formResponse: FormImageResponse) => {
    onSubmit(formResponse[FORM_IMAGE_ENUM.URL], formResponse);
  };

  const content = useMemo(() => {
    switch (activeTab) {
      case URL:
        return <ImageModalUrl form={form} />;
      case IMPORT:
        return (
          <ImageModalUpload
            isPersonalFile={isPersonalFile}
            kedoReviewTaskId={kedoReviewTaskId}
            maxLimitSizeMB={maxLimitSizeMB}
            form={form}
            title={uploadTitle}
            description={uploadDescription}
            availableTypes={availableTypes}
            customUploadUrl={customUploadUrl}
            customMetaUrl={customMetaUrl}
          />
        );
    }
  }, [
    activeTab,
    form,
    isPersonalFile,
    kedoReviewTaskId,
    maxLimitSizeMB,
    uploadTitle,
    uploadDescription,
    availableTypes,
    customUploadUrl,
    customMetaUrl,
  ]);

  // const handleTabChange = (e: { target: { value?: string } }) => {
  //   const value = e.target.value;
  //   if (value) {
  //     form.resetFields();
  //     setActiveTab(value);
  //   }
  // };

  const addButtonDisabled = !Form.useWatch(FORM_IMAGE_ENUM.SHOW_PREVIEW, form);

  return (
    <Modal
      destroyOnClose
      open={isOpen}
      onCancel={onClose}
      closable
      closeIcon={<Icon name="delete" className={s.close} />}
      width={512}
      rootClassName={s.wrapper}>
      <Typography.Text size="18" weight="500">
        {title}
      </Typography.Text>
      {/*<Radio.Group*/}
      {/*  className={s.radio}*/}
      {/*  buttonStyle="solid"*/}
      {/*  options={TABS}*/}
      {/*  onChange={handleTabChange}*/}
      {/*  value={activeTab}*/}
      {/*  optionType="button"*/}
      {/*/>*/}
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item name={FORM_IMAGE_ENUM.SHOW_PREVIEW} className={s.invisible} />
        <Form.Item name={FORM_IMAGE_ENUM.FULL_INFO} className={s.invisible} />
        {content}
        <div className={s.actions}>
          <Button shape="round" htmlType="submit" leftIcon="ok" disabled={addButtonDisabled}>
            {t('common_add')}
          </Button>
          <Button onClick={onClose} shape="round" ghost htmlType="button">
            {t('common_close')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const DEFAULT_ACTIVE_TAB = TABS[0]?.value;

export default ImageModal;
