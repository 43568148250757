import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import { useGetSurvey } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/useGetSurvey.hook';

const AnketaInfo = () => {
  const { survey } = useGetSurvey();

  if (!survey) return null;

  // TODO.Ilya инфа про анкету должна сихрнонизироваться с выбранной анкетой?
  return (
    <Typography.Text type="secondary">
      <Flex gap="16">
        <Flex gap="8" align="center">
          <Icon name="solution" />
          {t('surveys_table_anketa_id', { id: survey.anketologId })}
        </Flex>
        <Flex gap="8" align="center">
          <Icon name="tags" />
          {t('surveys_table_revision_id', { id: survey.revisionId })}
        </Flex>
      </Flex>
    </Typography.Text>
  );
};

export default AnketaInfo;
