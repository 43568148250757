import { t } from 'tools/i18n';

import FileField from 'containers/file-field';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';

const HandbookBaseFormMap = () => {
  return (
    <FileField
      maxSize={20}
      formItemConfig={{
        label: t('guide_handbook_map'),
        name: HANDBOOK_BASE_FORM_ENUM.MAP,
      }}
      previewConfig={{
        showDelete: true,
        showDownload: true,
      }}
      uploadConfig={{
        description: t('validation_upload_an_image_restrictions_guide'),
      }}
    />
  );
};

export default HandbookBaseFormMap;
