import { useState } from 'react';

import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import s from './AdditionalDocuments.module.scss';
import AddDocumentModal from './containers/add-document-modal';

interface Props {
  hideCreation?: boolean;
}

const AdditionalDocuments = (props: Props) => {
  const { hideCreation = false } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpenModal = () => setIsOpen((prev) => !prev);

  return (
    <>
      {!hideCreation && (
        <div className={s.container}>
          <Button
            htmlType="button"
            type="link"
            onClick={handleOpenModal}
            size="small"
            leftIcon="plus">
            {t('1c_lna_create_new')}
          </Button>
        </div>
      )}
      <AddDocumentModal open={isOpen} onCancel={handleOpenModal} />
    </>
  );
};

export default AdditionalDocuments;
