import { PropsWithChildren } from 'react';

import cn from 'classnames';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './TableStatus.module.scss';
import { STATUSES, getStatusData } from './tableStatus.utils';

interface Props extends PropsWithChildren {
  status: STATUSES;
  hideIcon?: boolean;
  size?: '12' | '14';
}

const TableStatus = (props: Props) => {
  const { status, hideIcon = false, size = '12', children } = props;
  const { color, label, icon } = getStatusData(status);

  return (
    <div className={cn(s.container, s[color])}>
      {!hideIcon && <Icon name={icon} size={14} />}
      <Typography.Text size={size} weight="500">
        {children ?? label}
      </Typography.Text>
    </div>
  );
};

export default TableStatus;
