import { t } from 'tools/i18n';

import { Icon, Popover, Typography } from 'gazprom-ui-lib';

import s from './DocumentsDeclarations.module.scss';
import DocumentsDeclarationsTemplate from './documents-declarations-template';

type Props = {
  isDisabled: boolean;
  popoverIsOpen: boolean;
  onClose: () => void;
};

const useTemplateTab = (props: Props) => {
  const { popoverIsOpen, onClose, isDisabled } = props;

  const popoverContent = (
    <div className={s.popoverContent}>
      <button type="button" onClick={onClose}>
        <Icon name="delete" />
      </button>
      <Typography.Text size="14" weight="500">
        {t('common_saved_successfully')}
      </Typography.Text>
      <Typography.Text size="12" type="secondary">
        {t('documents_declarations_template_popover_hint')}
      </Typography.Text>
    </div>
  );

  return {
    key: TEMPLATES_TAB_KEY,
    label: (
      <Popover
        overlayClassName={s.popover}
        open={popoverIsOpen}
        content={popoverContent}
        arrow={false}
        placement="rightTop"
        align={{ offset: [0, 20] }}>
        {t('documents_declarations_template')}
      </Popover>
    ),
    children: <DocumentsDeclarationsTemplate isDisabled={isDisabled} />,
  };
};

export const TEMPLATES_TAB_KEY = 'template';

export default useTemplateTab;
