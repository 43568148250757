import { GetCommunityMeetsPropsType } from 'services/communities/communityApiService.types';

import { COMMUNITY_ENTITY_SOURCE_TYPE } from 'types/communityMeet.types';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

type FormFiltersType = {
  categoryIds?: GetCommunityMeetsPropsType['categoryIds'];
  sourceType?: GetCommunityMeetsPropsType['sourceType'];
};

export type FormResponseType = PaginationPropsType & {
  property: string;
  order: SORT_ENUM;
  search?: string;
  filters?: FormFiltersType;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
  filters: {
    sourceType: COMMUNITY_ENTITY_SOURCE_TYPE.MODERATOR,
  },
};
