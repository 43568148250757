import { t } from 'tools/i18n';

import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order: SORT_ENUM;
  search: string;
};

export const DEFAULT_SORT = {
  property: 'status',
  order: SORT_ENUM.DESC,
};

export const MA_STATUS_TITLE = {
  QUEUED: t('documents_machine_authorities_queued'),
  PROCESSING: t('documents_machine_authorities_processing'),
  DONE: t('documents_machine_authorities_done'),
  ACTIVE: t('documents_machine_authorities_active'),
  EXPIRED: t('documents_machine_authorities_expired'),
  REVOKED: t('documents_machine_authorities_revoked'),
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  ...DEFAULT_SORT,
};
