import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetSettlementRegionsQuery } from 'services/settlement/settlementApiService';

import { Form, Select, Typography } from 'gazprom-ui-lib';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';

const HandbookBaseFormRegion = () => {
  const { data: regions = [] } = useGetSettlementRegionsQuery();
  const regionOptions = regions.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <>
      <Typography.Text strong size="16">
        {t('common_subject_rf_long')}
      </Typography.Text>
      <Form.Item rules={[formRules.required]} name={HANDBOOK_BASE_FORM_ENUM.REGION}>
        <Select
          showSearch
          optionFilterProp="label"
          options={regionOptions}
          placeholder={t('common_select_second')}
        />
      </Form.Item>
    </>
  );
};

export default HandbookBaseFormRegion;
