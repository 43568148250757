import { useState } from 'react';

import { GetAuthoritiesResponseType } from 'services/crypto/cryptoApiService.types';

import FiltersV2 from 'containers/filters-v2';

import { AuthorityType } from 'types/authority.types';

import { FormResponseType } from '../../settingsCertificationAuthorities.utils';
import ModalError from './containers/modal-error';
import ModalSuccess from './containers/modal-success';
import { ModalType } from './table.utils';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: GetAuthoritiesResponseType;
  loading: boolean;
  isInitialLoading: boolean;
}

const Table = (props: Props) => {
  const { dataSource, loading, isInitialLoading } = props;

  const [modalConfig, setModalConfig] = useState<ModalType>(null);

  const columns = useColumns({ setModalConfig });

  const handleCloseModal = () => {
    setModalConfig(null);
  };

  return (
    <>
      <FiltersV2.Table<AuthorityType, FormResponseType>
        isInitialLoading={isInitialLoading}
        rowKey={(rowData) => `${rowData.notAfter}__${rowData.serial}__${rowData.subject}`}
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        total={dataSource?.length}
      />
      <ModalError
        open={modalConfig?.type === 'error'}
        data={modalConfig?.data}
        message={modalConfig?.message}
        handleCloseModal={handleCloseModal}
      />
      <ModalSuccess
        open={modalConfig?.type === 'success'}
        data={modalConfig?.data}
        message={modalConfig?.message}
        handleCloseModal={handleCloseModal}
      />
    </>
  );
};

export default Table;
