export const MEDIA_SECONDARY_PREFIX = 'api/v1/media/download/';

export const createMediaDownloadUrl = (fileKey: string) => {
  return `${process.env.https://api-test.dev.tockl.ru/}${fileKey}`;
};
export const createMediaUploadUrl = (fileKey: string) => {
  return `${MEDIA_SECONDARY_PREFIX}${fileKey}`;
};

export const createMediaUploadUrl1c = (url: string) => {
  return `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}${url}`;
};

export const createMediaDownloadUrl1c = (url: string) => {
  return `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}${url}`;
};
