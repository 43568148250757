import { useGetHandbooksQuery } from 'services/settlement/settlementApiService';
import { useGetSurveysListQuery } from 'services/surveys/surveysApiService';

import { useGetSurvey } from '../../useGetSurvey.hook';

const DEFAULT_PAGINATION = { page: 1, size: 100 };

interface Props {
  settlementName?: string;
}

export const useGetFormData = (props: Props = {}) => {
  const { settlementName } = props;

  const { survey, isLoading: surveyIsLoading } = useGetSurvey();

  const { data: { options: surveyOptions = [] } = {}, isLoading: surveysListIsLoading } =
    useGetSurveysListQuery();

  const { data: { items: settlements = [] } = {}, isLoading: settlementsIsLoading } =
    useGetHandbooksQuery({ ...DEFAULT_PAGINATION, name: settlementName });
  const settlementOptions = settlements.map(({ id, name }) => ({ value: id, label: name }));

  const isLoading = surveysListIsLoading || surveyIsLoading || settlementsIsLoading;

  return { isLoading, surveyOptions, survey, settlementOptions };
};
