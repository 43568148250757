import { t } from 'tools/i18n';

import { RcFile } from 'gazprom-ui-lib';

import { BACKEND_V1_BASE_URL } from 'constants/apiUrls';

const MAX_LIMIT_SIZE_MB = 5;
export const AVAILABLE_TYPES = [
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const beforeUpload = (file: RcFile, setErrorsHandler: (errors: string[]) => void) => {
  const isCorrectFormat = AVAILABLE_TYPES.includes(file.type);
  const moreThanLimit = file.size / 1024 / 1024 > MAX_LIMIT_SIZE_MB;

  if (!isCorrectFormat) {
    setErrorsHandler([t('validation_file_incorrect_extension')]);
    return false;
  } else if (moreThanLimit) {
    setErrorsHandler([t('validation_file_max_size', { size: `${MAX_LIMIT_SIZE_MB} MB` })]);
    return false;
  } else {
    setErrorsHandler([]);
    return file;
  }
};

type GetDownloadUrlProps = {
  statementId: string;
  templateId: string;
};

export const getDownloadUrl = ({ statementId, templateId }: GetDownloadUrlProps) =>
  `${BACKEND_V1_BASE_URL}kedo/statement/${statementId}/template/${templateId}/download`;
