import { PaginationPropsType } from 'types/pagination.types';

export type FormResponseType = PaginationPropsType & {
  search?: string;
};

export const DEFAULT_FORM_STATE = {
  page: 1,
  size: 10,
};
