import { useParams } from 'react-router-dom';

import { useGetCommunityQuery } from 'services/communities/communitiesApiService';

import WithLoader from 'containers/wrappers/with-loader';

import Header from './containers/header';
import CommunityManagementViewTabs from './containers/tabs';

const CommunityManagementView = () => {
  const { id } = useParams() as { id: string };
  const { data: community, isFetching } = useGetCommunityQuery({ id });

  if (isFetching || !community) {
    return <WithLoader isLoading={isFetching} />;
  }

  return (
    <>
      <Header community={community} />
      <CommunityManagementViewTabs />
    </>
  );
};

export default CommunityManagementView;
