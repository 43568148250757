import { t } from 'tools/i18n';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_MEETS_FORM_ENUM } from 'containers/community-meet-form/communityMeetForm.utils';

const SpecialDate = () => {
  return (
    <Form.Item name={COMMUNITY_MEETS_FORM_ENUM.SPECIAL_DATE} valuePropName="checked">
      <Checkbox>
        <Typography.Text size="14">{t('community_meets_special_date')}</Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default SpecialDate;
