import { t } from 'i18next';

import { TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';

import { DeclarationsTypes, DeclarationsTypesLocal } from 'types/documents.types';

const renderName = (value: DeclarationsTypes['name']) => (
  <Typography.Text size="12" weight="500">
    {value}
  </Typography.Text>
);

const renderOrganization = (value: DeclarationsTypesLocal['ownerEmployerName']) => (
  <Typography.Text size="12" weight="500" type="secondary">
    {value}
  </Typography.Text>
);

const renderIsPublish = (value: DeclarationsTypesLocal['isPublish']) => (
  <TableIsPublish isPublish={value} />
);

const renderCreatedAt = (value: DeclarationsTypesLocal['createdAt']) => <TableDate date={value} />;

export const columns: TableProps<DeclarationsTypesLocal>['columns'] = [
  {
    title: t('common_name'),
    dataIndex: 'name',
    key: 'name',
    render: renderName,
  },
  {
    title: t('common_organization'),
    dataIndex: 'ownerEmployerName',
    key: 'ownerEmployerName',
    render: renderOrganization,
  },
  {
    title: t('common_published'),
    dataIndex: 'isPublish',
    key: 'isPublish',
    align: 'center',
    render: renderIsPublish,
  },
  {
    title: t('employee_groups_column_created_date'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: renderCreatedAt,
    sorter: true,
    defaultSortOrder: 'descend',
  },
];
