import { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { get1CInstanceLnaDetailsWithIdRoute } from 'routes/settings/list';

import { useLazyGetKedoReviewTasksQuery } from 'services/kedo-review-tasks/kedoReviewTasksApiService';

import { Table } from 'gazprom-ui-lib';

import { KedoReviewTaskType } from 'types/review-task.types';

import useColumnsHook from './useColumns.hook';

interface Props {
  handleExclude: (id: string) => void;
}

const ActiveLnaTable = (props: Props) => {
  const { handleExclude } = props;
  const params = useParams<{ instanceId: string }>();
  const columns = useColumnsHook({
    handleExclude,
  });
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [getKedoReviewTasks, { data, isLoading }] = useLazyGetKedoReviewTasksQuery();

  useEffect(() => {
    getKedoReviewTasks({
      page,
      size: 10,
      status: ['LNA_VALID'],
      type: 'LNA',
      instance1cId: params.instanceId!,
    });
  }, [page, getKedoReviewTasks, params.instanceId]);
  const onRow = ({ id }: KedoReviewTaskType) => ({
    onClick: () => {
      navigate(get1CInstanceLnaDetailsWithIdRoute(params.instanceId!, id));
    },
  });

  return (
    <Table
      dataSource={data?.items}
      columns={columns}
      pagination={{
        current: page,
        total: data?.totalCount,
      }}
      loading={isLoading}
      rowKey="id"
      onRow={onRow}
      onChange={(pagination) => setPage(pagination.current as number)}
    />
  );
};

export default ActiveLnaTable;
