import AccessControlRoles from 'pages/access-control/access-control-roles';
import AccessControlRolesDetails from 'pages/access-control/access-control-roles/access-control-roles-details';
import AccessControlUsers from 'pages/access-control/access-control-users';
import AccessControlUsersDetails from 'pages/access-control/access-control-users/access-control-users-details';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  ACCESS_CONTROL_USERS_BY_ID_ROUTE,
  ACCESS_CONTROL_USERS_ROUTE,
  ACCESS_CONTROL_USER_ROLES_DETAILS_ROUTE,
  ACCESS_CONTROL_USER_ROLES_ROUTE,
} from './list';

export const accessControlRoutes: RouteConfig[] = [
  {
    path: ACCESS_CONTROL_USERS_BY_ID_ROUTE,
    element: <AccessControlUsersDetails />,
    roles: [GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_ICC],
  },
  {
    path: ACCESS_CONTROL_USERS_ROUTE,
    element: <AccessControlUsers />,
    roles: [GLOBAL_ROLES.ADMIN, GLOBAL_ROLES.ADMIN_ICC],
  },
  {
    path: ACCESS_CONTROL_USER_ROLES_ROUTE,
    element: <AccessControlRoles />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
  {
    path: ACCESS_CONTROL_USER_ROLES_DETAILS_ROUTE,
    element: <AccessControlRolesDetails />,
    roles: [GLOBAL_ROLES.ADMIN],
  },
];
