type FioType =
  | 'full'
  | 'initials'
  | 'lastNameAndFirstNameInitial'
  | 'firstNameAndLastNameInitial'
  | 'firstNameLastName';

type GetFioProps = {
  lastName: string | null | undefined;
  firstName: string | null | undefined;
  patronymic?: string | null | undefined;
  type?: FioType;
  safe?: boolean;
};

const capitalize = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';

export const getFio = ({
  firstName,
  lastName,
  patronymic,
  type = 'initials',
  safe = false,
}: GetFioProps) => {
  if (safe && (!firstName || !lastName || !patronymic)) {
    return '';
  }

  const trimmedFirstName = firstName?.trim() ?? '';
  const trimmedLastName = lastName?.trim() ?? '';
  const trimmedPatronymic = patronymic?.trim() ?? '';

  const firstNameInitial = trimmedFirstName ? `${capitalize(trimmedFirstName[0])}.` : '';
  const patronymicInitial = trimmedPatronymic ? `${capitalize(trimmedPatronymic[0])}.` : '';
  const lastNameInitial = trimmedLastName ? `${capitalize(trimmedLastName[0])}.` : '';

  const capitalizedFirstName = trimmedFirstName ? capitalize(trimmedFirstName) : '';
  const capitalizedPatronymic = trimmedPatronymic ? capitalize(trimmedPatronymic) : '';
  const capitalizedLastName = trimmedLastName ? capitalize(trimmedLastName) : '';

  switch (type) {
    case 'initials':
      return [capitalizedLastName, firstNameInitial + patronymicInitial].filter(Boolean).join(' ');

    case 'firstNameLastName':
      return [capitalizedFirstName, capitalizedLastName].filter(Boolean).join(' ');

    case 'lastNameAndFirstNameInitial':
      return [capitalizedLastName, firstNameInitial].filter(Boolean).join(' ');

    case 'firstNameAndLastNameInitial':
      return [capitalizedFirstName, lastNameInitial].filter(Boolean).join(' ');

    case 'full':
    default:
      return [capitalizedLastName, capitalizedFirstName, capitalizedPatronymic]
        .filter(Boolean)
        .join(' ');
  }
};
