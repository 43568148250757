import cInitialApiService from 'services/1c/cApiService';

import {
  EnhancePropsType,
  EnhanceResponseType,
  GetAuthoritiesPropsType,
  GetAuthoritiesResponseType,
  ValidateAuthorityByIdPropsType,
  ValidateAuthorityByIdResponseType,
} from './cryptoApiService.types';

import { createCryptoUrl } from './utils';

const cryptoApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorities: builder.query<GetAuthoritiesResponseType, GetAuthoritiesPropsType>({
      query: () => ({
        url: createCryptoUrl('/private/authorities'),
      }),
      providesTags: [],
    }),
    validateAuthorityById: builder.mutation<
      ValidateAuthorityByIdResponseType,
      ValidateAuthorityByIdPropsType
    >({
      query: (id) => ({
        url: createCryptoUrl(`/private/authorities/${id}/validate`),
        method: 'POST',
      }),
      invalidatesTags: [],
    }),
    enhance: builder.mutation<EnhanceResponseType, EnhancePropsType>({
      query: (params) => ({
        url: createCryptoUrl(`/private/enhance`),
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [],
    }),
  }),
});

export const { useEnhanceMutation, useGetAuthoritiesQuery, useValidateAuthorityByIdMutation } =
  cryptoApiService;
