import { memo, useEffect } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { useGetDeclarationTypesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { useGetEmployerSocialProgramQuery } from 'services/social-program-category/socialProgramCategoryApiService';

import { Button, Checkbox, Flex, Form, Input, Select } from 'gazprom-ui-lib';

import OptionalLabel from 'components/optional-label';

import { SalaryProjectType } from 'types/salaryProject.types';

import s from './SalaryProjectForm.module.scss';
import Cover from './containers/cover';
import Description from './containers/description';
import { FORM_ENUM, FormResponseType, SALARY_PROJECT_FORM } from './salaryProjectForm.utils';

interface Props {
  className?: string;
  loading?: boolean;
  handleReset?: () => void;
  handleSubmit?: (formResponse: FormResponseType) => void;
  handleDelete?: () => void;
  initialData?: SalaryProjectType;
}

const SalaryProjectForm = (props: Props) => {
  const { initialData, className, handleSubmit, handleReset, loading, handleDelete } = props;

  const [form] = Form.useForm();
  const { data: employersOptions, isFetching: isEmployersOptionsIsFetching } =
    useGetEmployerSocialProgramQuery({ page: 1, size: 500 });
  const { data: declarationOptions, isFetching: declarationOptionsIsFetching } =
    useGetDeclarationTypesQuery({ page: 1, size: 500, isPublished: ['true'], creatorType: 'APP' });
  const handleInputNormalize = (counter: string) => {
    return Number(counter) > 0 ? counter : 1;
  };

  useEffect(() => {
    if (initialData) {
      form.setFieldsValue({
        [FORM_ENUM.NAME]: initialData.name,
        [FORM_ENUM.DESCRIPTION]: initialData.description,
        [FORM_ENUM.SORT]: initialData.sort,
        [FORM_ENUM.COVER]: initialData.bannerImg,
        [FORM_ENUM.STATEMENT]: initialData.kedoStatementId,
        [FORM_ENUM.ORG]: initialData.employerId,
        [FORM_ENUM.LINK]: initialData.tariffLink,
        [FORM_ENUM.PUBLISHED]: initialData.isPublish,
      });
    }
  }, [form, initialData]);

  return (
    <Form
      className={cn(s.wrapper, className)}
      form={form}
      onFinish={handleSubmit}
      onReset={handleReset}
      id={SALARY_PROJECT_FORM}>
      <Form.Item
        name={FORM_ENUM.NAME}
        label={t('common_program_name')}
        rules={[formRules.required]}>
        <Input placeholder={t('common_enter_text')} />
      </Form.Item>
      <Cover />
      <Form.Item
        label={t('salary_project_order')}
        name={FORM_ENUM.SORT}
        rules={[formRules.required]}
        normalize={handleInputNormalize}>
        <Input placeholder={t('common_enter_number')} type="number" min={1} step={1} />
      </Form.Item>
      <Form.Item
        label={t('common_statement_type')}
        name={FORM_ENUM.STATEMENT}
        valuePropName="value"
        rules={[formRules.required]}>
        <Select
          loading={declarationOptionsIsFetching}
          placeholder={t('common_select')}
          options={declarationOptions?.items.map((e) => ({ label: e.name, value: e.id }))}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Form.Item>
      <Form.Item
        label={t('common_organization')}
        name={FORM_ENUM.ORG}
        valuePropName="value"
        rules={[formRules.required]}>
        <Select
          loading={isEmployersOptionsIsFetching}
          placeholder={t('common_select')}
          options={employersOptions}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Form.Item>
      <Description />
      <Form.Item
        name={FORM_ENUM.LINK}
        label={<OptionalLabel>{t('salary_project_tariff_link')}</OptionalLabel>}
        extra={t('salary_project_link_description')}>
        <Input placeholder={t('salary_project_enter_url')} />
      </Form.Item>
      <Form.Item valuePropName="checked" name={FORM_ENUM.PUBLISHED}>
        <Checkbox>{t('common_published')}</Checkbox>
      </Form.Item>
      <Flex gap="8">
        <Button fullWidth htmlType="submit" leftIcon="ok" loading={loading}>
          {t('common_save')}
        </Button>
        {handleDelete ? (
          <Button
            loading={loading}
            fullWidth
            type="primary"
            danger
            ghost
            leftIcon="binFilled"
            onClick={handleDelete}>
            {t('common_delete')}
          </Button>
        ) : (
          <Button type="secondary" fullWidth htmlType="reset">
            {t('common_close')}
          </Button>
        )}
      </Flex>
    </Form>
  );
};

export default memo(SalaryProjectForm);
