import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Form, Select } from 'gazprom-ui-lib';

import {
  COMMUNITY_MEETS_FORM_ENUM,
  WEEK_DAYS_OPTIONS,
} from 'containers/community-meet-form/communityMeetForm.utils';

import { COMMUNITY_MEET_REPEAT_INTERVAL } from 'types/communityMeet.types';

const WeekDays = () => {
  const form = Form.useFormInstance();
  const repeatInterval = Form.useWatch(COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL, form);

  if (repeatInterval !== COMMUNITY_MEET_REPEAT_INTERVAL.EVERY_WEEK) {
    return null;
  }

  return (
    <Form.Item
      rules={[formRules.required]}
      label={t('common_choose_days')}
      name={COMMUNITY_MEETS_FORM_ENUM.WEEK_DAYS}>
      <Select
        showSearch={false}
        mode="multiple"
        placeholder={t('common_choose_days')}
        options={WEEK_DAYS_OPTIONS}
      />
    </Form.Item>
  );
};

export default WeekDays;
