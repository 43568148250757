import { prepareQueryParams } from 'utils/prepareQueryParams';

import { BACKEND_1C_BASE_URL } from 'constants/apiUrls';

export const createDownloadUrl = (id: string, fileKey: string, full: boolean = false) => {
  return `${BACKEND_1C_BASE_URL}renew-term/form/${id}/download-file${prepareQueryParams({
    fileKey,
    full,
  })}`;
};

export const createDownloadArchiveUrl = (id: string, full: boolean = false) => {
  return `${BACKEND_1C_BASE_URL}renew-term/form/${id}/archive${prepareQueryParams({
    full,
  })}`;
};

export const ARCHIVE_NAME = 'anketa.zip';
