import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';

import {
  useCreateCommunityArticleCategoryMutation,
  useGetCommunityArticleCategoriesQuery,
} from 'services/communities/communitiesApiService';
import {
  CreateCommunityArticleCategoryPropsType,
  GetCommunityArticleCategoriesResponseType,
} from 'services/communities/communityApiService.types';

import {
  Button,
  Form,
  Icon,
  Input,
  Modal,
  Select,
  SelectProps,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { CommunityArticleCategoryType } from 'types/communityArticle.types';

import s from './Categories.module.scss';
import { COMMUNITY_ARTICLE_CATEGORIES_PAGINATION } from './categories.utils';

interface Props {
  formFieldKey: string;
}

const CommunityArticleCategories = (props: Props) => {
  const { formFieldKey } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { id: employeeGroupId } = useParams() as { id: string };
  const [selectedEmployee] = useFullSelectedEmployee();
  const { id: employeeId } = selectedEmployee || {};

  const [form] = Form.useForm();
  const parentForm = Form.useFormInstance();

  const [createCommunityArticleCategory, { isLoading: createCommunityArticleCategoryIsLoading }] =
    useCreateCommunityArticleCategoryMutation();
  const { categoriesOptions, isFetching } = useGetCommunityArticleCategoriesQuery(
    { ...COMMUNITY_ARTICLE_CATEGORIES_PAGINATION },
    { selectFromResult },
  );

  const handleModalOpenClose = () => setIsModalOpen((prevState) => !prevState);

  const handleCategoryCreate = async () => {
    const alreadySelectedCategories = parentForm.getFieldValue(formFieldKey) ?? [];
    const newCategoryName = form.getFieldValue(NEW_CATEGORY_NAME);

    if (employeeId) {
      const createArticleCategoryProps: CreateCommunityArticleCategoryPropsType = {
        name: newCategoryName,
        employeeGroupId,
        employeeId,
        visible: true,
      };

      const onSuccess = (data?: CommunityArticleCategoryType) => {
        if (data) {
          parentForm.setFieldValue(formFieldKey, alreadySelectedCategories.concat([data.id]));
          handleModalOpenClose();
          form.resetFields([NEW_CATEGORY_NAME]);
        }
      };

      const onError = () => {
        form.setFields([
          {
            name: NEW_CATEGORY_NAME,
            errors: [t('errors_category_name_exist')],
          },
        ]);
      };

      createCommunityArticleCategory(createArticleCategoryProps).then(
        handleRequest({
          onSuccess,
          onError,
        }),
      );
    }
  };

  const handleCheckValidation = () => form.validateFields().then(handleCategoryCreate);

  const filterOptions: SelectProps['filterOption'] = (search, option) => {
    return JSON.stringify(option)?.toLowerCase().includes(search?.toLowerCase());
  };

  return (
    <>
      <div className={s.wrapper}>
        <Form.Item name={formFieldKey} rules={[formRules.required]} label={t('events_category')}>
          <Select
            mode="multiple"
            placeholder={t('common_choose_category_from_list_or_add')}
            loading={isFetching}
            options={categoriesOptions}
            filterOption={filterOptions}
          />
        </Form.Item>
        <button type="button" className={s.modalOpenButton} onClick={handleModalOpenClose}>
          <Icon name="plus" />
          <Typography.Text size="14" weight="500">
            {t('common_add_category')}
          </Typography.Text>
        </button>
      </div>
      <Modal open={isModalOpen} onCancel={handleModalOpenClose} closable>
        <Form form={form}>
          <Form.Item
            label={t('common_add_category')}
            normalize={(e: string) => e.trimStart()}
            name={NEW_CATEGORY_NAME}
            className={s.categoryInput}
            rules={[formRules.required]}>
            <Input placeholder={t('common_enter_category_name')} />
          </Form.Item>
          <div className={s.actions}>
            <Button htmlType="button" type="secondary" onClick={handleModalOpenClose}>
              {t('common_close')}
            </Button>
            <Button
              htmlType="button"
              onClick={handleCheckValidation}
              loading={createCommunityArticleCategoryIsLoading}>
              {t('common_save')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const NEW_CATEGORY_NAME = 'NEW_CATEGORY_NAME';

const selectFromResult = ({
  data,
  ...props
}: {
  data?: GetCommunityArticleCategoriesResponseType;
  isFetching: boolean;
}) => ({
  categoriesOptions: data?.items.map(({ id, name }) => ({ value: id, label: name, key: id })) ?? [],
  ...props,
});

export default CommunityArticleCategories;
