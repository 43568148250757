import { t } from 'tools/i18n';

import { Flex, Icon, TableProps, Tag } from 'gazprom-ui-lib';

import Cover from 'components/cover';
import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { CommunityMeetType } from 'types/communityMeet.types';

import s from './MeetsTab.module.scss';

export const useColumns = (): TableProps<CommunityMeetType>['columns'] => {
  return [
    {
      title: t('common_event_singular'),
      dataIndex: 'title',
      sorter: true,
      render: (_: unknown, { categories, title, cover }) => {
        const communityCategories = categories.map(({ id, name }) => (
          <Tag type="primary" key={id}>
            {name}
          </Tag>
        ));
        return (
          <div className={s.meetCoverCell}>
            <Cover src={cover?.link} alt="Обложка мероприятия" />
            <div>
              <TableText size="12" weight="500" strong>
                {title}
              </TableText>
              <Flex>{communityCategories}</Flex>
            </div>
          </div>
        );
      },
    },
    {
      title: t('common_date'),
      dataIndex: 'meetDate',
      align: 'center',
      render: (meetDate: string) => <TableDate date={meetDate} />,
    },
    {
      title: t('events_location'),
      dataIndex: 'city',
      render: (city: string) => (
        <TableText size="14" weight="500">
          {city}
        </TableText>
      ),
    },
    {
      title: t('common_registration'),
      align: 'center',
      width: '80px',
      dataIndex: 'registrationActive',
      render: (registrationActive: boolean) => (
        <Icon
          name={registrationActive ? 'lockOpenRight' : 'lockFilled'}
          color="var(--color-primary-6)"
        />
      ),
    },
    {
      title: t('news_publication'),
      align: 'center',
      width: '80px',
      dataIndex: 'isPublish',
      render: (isPublish: boolean) => (
        <Icon
          name="okFilled"
          color={`var(${isPublish ? '--color-success' : '--color-placeholder-25'})`}
        />
      ),
    },
    {
      title: t('news_updated'),
      sorter: true,
      align: 'center' as const,
      dataIndex: 'updatedAt',
      defaultSortOrder: 'descend',
      render: (updatedAt: string) => <TableDate date={updatedAt} />,
    },
  ];
};
