import { MassSubscribeInvitePropsType } from 'services/communities/communityApiService.types';

import { COMMUNITY_TARGET_AUDIENCE } from 'types/community.types';

export const MASS_SUBSCRIBE_INVITE_FORM_NAME = 'MASS_SUBSCRIBE_INVITE_FORM_NAME';

export enum MASS_SUBSCRIBE_INVITE_FORM_ENUM {
  GENDER = 'gender',
  TREE_IDS = 'treeIds',
}

export type MassSubscribeInviteFormResponse = {
  [MASS_SUBSCRIBE_INVITE_FORM_ENUM.GENDER]: COMMUNITY_TARGET_AUDIENCE;
  [MASS_SUBSCRIBE_INVITE_FORM_ENUM.TREE_IDS]: string[];
};

export const DEFAULT_MASS_SUBSCRIBE_INVITE_FORM_VALUES: MassSubscribeInviteFormResponse = {
  gender: COMMUNITY_TARGET_AUDIENCE.BOTH,
  treeIds: [],
};

export const prepareMassInviteSubscribePayload = (
  formResponse: MassSubscribeInviteFormResponse,
) => {
  const { treeIds, gender } = formResponse;

  const payload: MassSubscribeInvitePropsType['payload'] = {
    gender,
    divisionIds: [],
    employerIds: [],
  };

  treeIds.forEach((item) => {
    const [itemId, itemType] = item?.split('.') ?? [];

    if (!itemType) return;

    if (itemType === 'DIVISION') {
      payload.divisionIds.push(itemId);
    } else if (itemType === 'EMPLOYER') {
      payload.employerIds.push(itemId);
    }
  });

  return payload;
};
