import { t } from 'tools/i18n';

import { COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

export const COMMUNITY_SUGGESTED_STATUSES = [
  {
    value: COMMUNITY_ENTITY_STATUS.MODERATION,
    label: t('community_suggestions_new_plural'),
  },
  {
    value: COMMUNITY_ENTITY_STATUS.ACCEPTED,
    label: t('common_approved'),
  },
  {
    value: COMMUNITY_ENTITY_STATUS.REJECTED,
    label: t('thank_you_declined'),
  },
];
