import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useRoleModelContent from 'utils/useRoleModelContent.hook';

import { Form } from 'gazprom-ui-lib';

import DocumentDeclarationFormCodes from 'containers/documents-declarations-form/document-declaration-form-codes';
import DocumentsDeclarationsFormCategory from 'containers/documents-declarations-form/documents-declarations-form-category';
import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormDescription from 'containers/documents-declarations-form/documents-declarations-form-description';
import DocumentsDeclarationsFormExecutor from 'containers/documents-declarations-form/documents-declarations-form-executor';
import DocumentsDeclarationsFormLink from 'containers/documents-declarations-form/documents-declarations-form-link';
import DocumentsDeclarationsFormName from 'containers/documents-declarations-form/documents-declarations-form-name';
import DocumentsDeclarationsFormOrganization from 'containers/documents-declarations-form/documents-declarations-form-organization';
import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useCommonFormSubmitHandler from 'pages/documents/documents-declarations/useCommonFormSubmitHandler.hook';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';
import { GLOBAL_ROLES } from 'types/roles.types';

import LinkedStatements from '../linked-statements';
import s from './TabCreate.module.scss';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from './tabCreate.utils';
import useCommonData from './useCommonData';

interface Props {
  isOrgRequired?: boolean;
}

const TabCreate = (props: Props) => {
  const { isOrgRequired } = props;
  const [form] = Form.useForm();

  const { isLoading, createRoute, parentStatementData } = useCommonData({ form });

  const statementType = KEDO_TYPE_ENUM.BPMN;
  const { isLoading: mutationIsLoading, handleFormSubmit } = useCommonFormSubmitHandler({
    createRoute: createRoute,
    type: statementType,
  });

  const [selectedEmployee] = useFullSelectedEmployee();

  const isEditAvailable = useRoleModelContent({
    content: true,
    fallbackValue: false,
    roles: [GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.ADMIN],
  });

  const isKedoADmin = useRoleModelContent({
    content: true,
    fallbackValue: false,
    roles: [GLOBAL_ROLES.KEDO_ADMIN],
  });

  const isOnlyKedoRole = isKedoADmin && !isEditAvailable;
  const defaultOrgValue = isOnlyKedoRole ? selectedEmployee?.employer.id : undefined;

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME}>
        <div className={s.container}>
          <DocumentsDeclarationsFormLink
            defaultStatementId={parentStatementData?.id}
            type={parentStatementData?.type}
          />
          <DocumentsDeclarationsFormOrganization
            required={typeof isOrgRequired === 'boolean' ? isOrgRequired : true}
            disabled={isOnlyKedoRole}
            defaultValue={defaultOrgValue}
          />
          <DocumentsDeclarationsFormName disabled={true} />
          <DocumentsDeclarationsFormCategory disabled={true} />
          <DocumentsDeclarationsFormDescription disabled={true} />
          <DocumentsDeclarationsFormExecutor disabled={true} />
          <DocumentDeclarationFormCodes required disabled={true} />
          <DocumentsDeclarationsFormCheck
            defaultValue={false}
            className={s.hidden}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS}
            label=""
          />
          <Form.Item name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID} noStyle />
        </div>
        <LinkedStatements />
        <DocumentsDeclarationsButtons isLoading={mutationIsLoading} />
      </Form>
    </WithLoader>
  );
};

export default TabCreate;
