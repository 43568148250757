import { useParams } from 'react-router-dom';

import { useGetCommunityNewsByIdQuery } from 'services/communities/communitiesApiService';

const useGetData = () => {
  const { id, newsId } = useParams<{ id: string; newsId: string }>();

  const { data: news, isLoading } = useGetCommunityNewsByIdQuery(
    { communityId: id!, newsId: newsId! },
    { skip: !id || !newsId },
  );

  return { news, isLoading };
};

export default useGetData;
