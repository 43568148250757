import { Link, useParams } from 'react-router-dom';
import { COMMUNITY_MANAGEMENT_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { useGetEmployerRootQuery } from 'services/employee-groups-new/employeeGroupsNewApiService';

import { Button, Flex, Form, FormInstance } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import s from './MassSubscribeInviteForm.module.scss';
import MassSubscribeInviteFormGender from './mass-subscribe-invite-form-gender';
import MassSubscribeInviteFormTree from './mass-subscribe-invite-form-tree';
import {
  DEFAULT_MASS_SUBSCRIBE_INVITE_FORM_VALUES,
  MASS_SUBSCRIBE_INVITE_FORM_NAME,
  MassSubscribeInviteFormResponse,
} from './massSubscribeInviteForm.utils';

interface Props {
  form: FormInstance<MassSubscribeInviteFormResponse>;
  openConfirmModal: () => void;
  disableSubmit: boolean;
  handleFinish: (payload: MassSubscribeInviteFormResponse) => void;
  isMassSubscribePage: boolean;
  isLoading: boolean;
}

const MassSubscribeInviteForm = (props: Props) => {
  const { form, openConfirmModal, disableSubmit, handleFinish, isMassSubscribePage, isLoading } =
    props;
  const { id: communityId } = useParams<{ id: string }>() as { id: string };
  const { data: rootEmployers = [], isFetching: isFetchingRootEmployers } =
    useGetEmployerRootQuery();

  return (
    <Form
      form={form}
      className={s.formWrapper}
      onFinish={handleFinish}
      initialValues={DEFAULT_MASS_SUBSCRIBE_INVITE_FORM_VALUES}
      id={MASS_SUBSCRIBE_INVITE_FORM_NAME}>
      <WithLoader isLoading={isFetchingRootEmployers}>
        <MassSubscribeInviteFormGender />
        <MassSubscribeInviteFormTree
          rootEmployers={rootEmployers}
          isFetchingRootEmployers={isFetchingRootEmployers}
        />
        <Flex gap="8" className={s.formActionsWrapper}>
          <Link
            className={s.fullWidth}
            to={`${COMMUNITY_MANAGEMENT_ROUTE}/view/${communityId}/subscribers`}>
            <Button type="secondary" fullWidth form={MASS_SUBSCRIBE_INVITE_FORM_NAME}>
              {t('common_close')}
            </Button>
          </Link>
          <Button
            loading={isLoading}
            disabled={disableSubmit}
            onClick={openConfirmModal}
            leftIcon="plus"
            fullWidth
            type="primary"
            form={MASS_SUBSCRIBE_INVITE_FORM_NAME}>
            {t(isMassSubscribePage ? 'community_add_multiple_action' : 'community_invite')}
          </Button>
        </Flex>
      </WithLoader>
    </Form>
  );
};

export default MassSubscribeInviteForm;
