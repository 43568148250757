import { useNavigate } from 'react-router-dom';
import {
  DECLARATIONS_TYPE_TAB_ENUM,
  getDeclarationsTabTypeRoute,
  getSettingsDeclarationsEdoEditRoute,
} from 'routes/settings/list';
import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';
import useRoleModelContent from 'utils/useRoleModelContent.hook';

import { Button, Flex, Icon, Table, Typography } from 'gazprom-ui-lib';

import { createDeclarationRoles } from 'permissions/declarations.types';
import { DeclarationsTypesLocal } from 'types/documents.types';

import s from './LinkedStatements.module.scss';
import useLinkedData from './useLinkedData';
import { columns } from './utils';

const LinkedStatements = () => {
  const { id, dataSource, isFetching, isLoading, pagination, setRequestBody } = useLinkedData();

  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`${getDeclarationsTabTypeRoute(DECLARATIONS_TYPE_TAB_ENUM.LOCAL)}/edo`, {
      state: { parentStatementId: id },
    });
  };

  const addButton = useRoleModelContent({
    content: (
      <Button type="link" leftIcon="plus" onClick={handleClick} className={s.button}>
        {t('documents_statements_add_local_copy')}
      </Button>
    ),
    roles: createDeclarationRoles,
  });

  const onRow = (record: DeclarationsTypesLocal) => ({
    onClick: () => {
      navigate(getSettingsDeclarationsEdoEditRoute(record.id, DECLARATIONS_TYPE_TAB_ENUM.LOCAL));
    },
  });

  if (!id) {
    return null;
  }

  return (
    <>
      <Flex vertical gap="16" className={s.container}>
        <Flex vertical gap="8">
          <Typography.Title level={4}>
            {t('documents_declarations_local_statements')}
          </Typography.Title>
          <Flex gap="8" className={s.description}>
            <Icon name="info" />
            <Typography.Text type="secondary" size="14" weight="400">
              {t('documents_declarations_master_statement_warning')}
            </Typography.Text>
          </Flex>
        </Flex>
        <Table
          onRow={onRow}
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          onChange={handleTableChange(setRequestBody)}
          loading={isLoading || isFetching}
          rowKey="employeeId"
        />
        {addButton}
      </Flex>
    </>
  );
};

export default LinkedStatements;
