import { t } from 'tools/i18n';

import { Button, Flex, Modal, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';

import { CommunityTypeWithModerators } from 'types/community.types';

type ModeratorsModalProps = {
  open: boolean;
  close: () => void;
  moderators: CommunityTypeWithModerators['moderators'];
};

const ModeratorsModal = ({ open, close, moderators }: ModeratorsModalProps) => {
  const renderedModerators = moderators.map(
    ({ id, person: { firstName, lastName, patronymic }, employer: { name: employerName } }) => {
      return (
        <Bio
          key={id}
          firstName={firstName}
          lastName={lastName}
          patronymic={patronymic}
          employerName={employerName}
        />
      );
    },
  );

  return (
    <Modal width={360} open={open} onCancel={close} closable>
      <Flex vertical gap="16">
        <Typography.Text size="18" weight="500">
          {t('community_moderators')}
        </Typography.Text>

        <Flex vertical gap="12">
          {renderedModerators}
        </Flex>

        <Button type="secondary" onClick={close}>
          {t('common_close')}
        </Button>
      </Flex>
    </Modal>
  );
};

export default ModeratorsModal;
