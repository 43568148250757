import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = {
  page: number;
  size: number;
  order: SORT_ENUM;
  property?: string;
  search: string;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  order: SORT_ENUM.DESC,
  property: 'createDate',
  search: '',
};

export const transformCN = (input?: string): string => {
  if (!input) return '';

  let transformedName = input.replace('CN=', '');

  transformedName = transformedName.slice(0, transformedName.indexOf(',')).replace(/\\/g, '');

  if (transformedName.startsWith('"') && transformedName.endsWith('"')) {
    transformedName = transformedName.slice(1, -1);
  }
  return transformedName;
};
