import { useEffect } from 'react';

import { FormInstance } from 'gazprom-ui-lib';

import { useGetSurvey } from '../../useGetSurvey.hook';
import {
  SURVEY_SETTLEMENT_FORM_ENUM,
  SurveySettlementFormResponse,
} from './surveySettlementForm.utils';

interface Props {
  form: FormInstance<SurveySettlementFormResponse>;
}

export const useSetFormData = (props: Props) => {
  const { form } = props;
  const { survey } = useGetSurvey();

  useEffect(() => {
    if (survey) {
      form.setFieldsValue({
        [SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID]: survey.anketologId,
        [SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS]: survey?.settlements?.map(({ id }) => id) ?? [],
      });
    }
  }, [survey, form]);

  return;
};
