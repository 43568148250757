import { ReactNode } from 'react';

import { useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';

import { Flex } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { CommunityNewsType } from 'types/community.types';

import { CommunityEntityKind } from '../../../../../tabs.utils';
import SuggestedHeaderInfo from '../../../../suggested-header-info';
import { CommunitySuggestedEntityType } from '../../../../suggested/suggestedTab.utils';
import s from './Header.module.scss';

interface Props {
  news: CommunityNewsType;
  headerActions: ReactNode;
  communityEntityKind: CommunityEntityKind;
}

const Header = (props: Props) => {
  const { news, headerActions, communityEntityKind } = props;
  const { id } = useParams<{ id: string }>();

  return (
    <Flex vertical gap="8" className={s.wrapper}>
      <ContentHeader>
        <ContentHeader.Title goBackLink={getCommunityManagementViewByIdRoute(id!)}>
          {news.title}
        </ContentHeader.Title>
        <ContentHeader.Actions>{headerActions}</ContentHeader.Actions>
      </ContentHeader>

      {/* Только для предложки (sourceType: MOBILE) */}
      {/* Инфа про предложенную entity и автора (creator) */}
      <SuggestedHeaderInfo
        entity={news as CommunitySuggestedEntityType}
        communityEntityKind={communityEntityKind}
      />
    </Flex>
  );
};

export default Header;
