import { useDebounce } from 'utils/useDebounce.hook';

import {
  useGetCommunityDiscussionsQuery,
  useGetCommunityMeetsQuery,
  useGetCommunityNewsListQuery,
} from 'services/communities/communitiesApiService';

import { COMMUNITY_ARTICLE_TYPES } from 'types/communityArticle.types';
import { COMMUNITY_ENTITY_SOURCE_TYPE } from 'types/communityMeet.types';

import { FormResponseType, SuggestedTabSegments } from './suggestedTab.utils';

interface Props {
  communityId: string;
  segment: SuggestedTabSegments;
  requestBody: FormResponseType;
}

export const useRequest = (props: Props) => {
  const { segment, communityId, requestBody } = props;

  const hookMap = {
    news: useGetCommunityNewsListQuery,
    meets: useGetCommunityMeetsQuery,
    discussions: useGetCommunityDiscussionsQuery,
  };
  const useGetData = hookMap[segment];

  // Добавляем тип, для ручки GetCommunityNewsList
  const newsQueryProp = segment === 'news' && { types: COMMUNITY_ARTICLE_TYPES.NEWS };

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);
  const { property, order, search, filters, ...remainingRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody = {
    communityId,
    sourceType: COMMUNITY_ENTITY_SOURCE_TYPE.MOBILE,
    ...remainingRequestBody,
    ...filters,
    ...(search && { title: search }),
    ...(property && order && { sort: [{ order, property }] }),
    ...newsQueryProp,
  };

  const {
    data: { items: dataSource = [], totalCount: total = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetData({ ...actualRequestBody }, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied =
    !!search?.trim() || !!filters?.statuses?.length || !!filters?.employerIds?.length;

  return { isInitialLoading, loading, dataSource, total, hasFiltersApplied };
};
