import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, Select, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import useBPMNProcesses from '../useBPMNProcess.hook';

interface Props {
  defaultValue?: string;
  disabled?: boolean;
}

const DocumentsDeclarationsFormBpmnProcess = (props: Props) => {
  const { defaultValue, disabled } = props;

  const { options, isLoading } = useBPMNProcesses();

  return (
    <Flex gap="8" vertical>
      <Typography.Title level={5}>{t('documents_declarations_bpmn_title')}</Typography.Title>
      <Form.Item
        name={DOCUMENTS_PARAMETERS_DECLARATIONS_FORM_ENUM.BPMN_PROCESS}
        initialValue={defaultValue}
        rules={[formRules.required]}>
        <Select
          disabled={disabled}
          placeholder={t('documents_declarations_BPMN_placeholder')}
          options={options}
          showSearch
          filterOption
          optionFilterProp="label"
          loading={isLoading}
        />
      </Form.Item>
    </Flex>
  );
};

export default DocumentsDeclarationsFormBpmnProcess;
