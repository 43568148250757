interface Props {
  error: unknown;
  targetStatus: number;
}

export const compareErrorStatuses = ({ error, targetStatus }: Props) =>
  isErrorWithStatus(error) && error.status === targetStatus;

export const isErrorWithStatus = (error: unknown): error is { status: number } =>
  typeof error === 'object' &&
  error !== null &&
  'status' in error &&
  typeof (error as Record<string, unknown>).status === 'number';
