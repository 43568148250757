import { useEffect, useState } from 'react';

import { handleTableChange } from 'utils/table.utils';
import { useDebounce } from 'utils/useDebounce.hook';

import {
  useDeleteCommentMutation,
  useLazyGetCommentsQuery,
} from 'services/comment/commentApiService';
import { GetArticlesPropsType } from 'services/comment/commentApiService.types';

import { InputProps, Table, handleRequest } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import { CommentType } from 'types/comment.types';
import { SORT_ENUM } from 'types/sort.types';

import Filters from './containers/filters';
import Modal from './containers/modal';
import useColumns from './useColumns.hook';

interface Props {
  articleId?: string;
}

export const DEFAULT_REQUEST_BODY: GetArticlesPropsType = {
  page: 1,
  size: 10,
  sort: [{ order: SORT_ENUM.DESC, property: 'createdAt' }],
};

const Comments = (props: Props) => {
  const { articleId } = props;
  const [selectedRow, setSelectedRow] = useState<CommentType | null>(null);
  const [requestBody, setRequestBody] = useState<GetArticlesPropsType>(DEFAULT_REQUEST_BODY);
  const { debouncedValue: debouncedRequestBody } = useDebounce(requestBody);

  const [getComments, { data: commentsData, isFetching }] = useLazyGetCommentsQuery();
  const [deleteComment, { isLoading: deleteCommentIsLoading }] = useDeleteCommentMutation();

  const { items, totalCount } = commentsData ?? {};

  const handleRowClick = (record: CommentType) => setSelectedRow(record);
  const handleCloseModal = () => setSelectedRow(null);
  const handleDeleteComment = (id: CommentType['id']) =>
    deleteComment({ id }).then(handleRequest({ onSuccess: handleCloseModal }));
  const handleChangeSearch: InputProps['onChange'] = (value) =>
    setRequestBody((prev) => ({
      ...prev,
      queryString: value.target.value,
      page: DEFAULT_REQUEST_BODY.page,
    }));

  const handleOnRow = (record: CommentType) => ({
    onClick: () => handleRowClick(record),
  });

  const columns = useColumns();

  useEffect(() => {
    const initialRequestBody: GetArticlesPropsType = {
      page: debouncedRequestBody.page,
      size: debouncedRequestBody.size,
      sort: debouncedRequestBody.sort,
      articleId: articleId,
    };

    if (debouncedRequestBody.queryString) {
      initialRequestBody.queryString = debouncedRequestBody.queryString;
    }

    getComments(initialRequestBody);
  }, [articleId, debouncedRequestBody, getComments]);

  return (
    <WithOverflow>
      <Filters text={requestBody.queryString} handleSearchChange={handleChangeSearch} />
      <Table
        rowKey="id"
        dataSource={items}
        columns={columns}
        pagination={{
          defaultCurrent: debouncedRequestBody.page,
          defaultPageSize: debouncedRequestBody.size,
          total: totalCount,
        }}
        onRow={handleOnRow}
        loading={isFetching}
        showSorterTooltip={false}
        onChange={handleTableChange(setRequestBody)}
      />
      <Modal
        selectedRow={selectedRow}
        isLoading={deleteCommentIsLoading}
        handleCloseModal={handleCloseModal}
        handleDeleteComment={handleDeleteComment}
      />
    </WithOverflow>
  );
};

export default Comments;
