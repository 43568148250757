import {
  getCommunityManagementDiscussionsByIdRoute,
  getCommunityManagementMeetsEditRoute,
  getCommunityManagementNewsByIdRoute,
} from 'routes/communities/list';

import { SegmentedButtonsValues } from 'components/segmented-buttons';

import { CommunityDiscussionType, CommunityNewsType } from 'types/community.types';
import { COMMUNITY_ENTITY_STATUS, CommunityMeetType } from 'types/communityMeet.types';
import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

type FormFiltersType = {
  statuses?: COMMUNITY_ENTITY_STATUS;
  employerIds?: string[];
};

export type FormResponseType = PaginationPropsType & {
  property: string;
  order: SORT_ENUM;
  search?: string;
  filters?: FormFiltersType;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
};

export type SuggestedTabSegments = 'news' | 'meets' | 'discussions';

export const SUGGESTED_TAB_SEGMENTS: SegmentedButtonsValues<SuggestedTabSegments> = [
  {
    segment: 'news',
    leftIcon: 'news',
  },
  { segment: 'meets', leftIcon: 'event' },
  { segment: 'discussions', leftIcon: 'comment' },
];

type CommonFields<T1, T2, T3> = {
  [K in keyof T1 & keyof T2 & keyof T3]: T1[K];
};

// Общие поля типов news, meet, discussion для таблиц с предложкой
export type CommunitySuggestedEntityType = CommonFields<
  CommunityMeetType,
  CommunityNewsType,
  CommunityDiscussionType
>;

type OnRowFn = (id: string, entityId: string) => string;

const ON_ROW_LINK_MAP: Record<SuggestedTabSegments, OnRowFn> = {
  news: getCommunityManagementNewsByIdRoute,
  meets: getCommunityManagementMeetsEditRoute,
  discussions: getCommunityManagementDiscussionsByIdRoute,
};

export const getOnRowFn = (segment: SuggestedTabSegments): OnRowFn => ON_ROW_LINK_MAP[segment];
