import { t } from 'tools/i18n';

import FileField from 'containers/file-field';

import { HANDBOOK_BASE_FORM_ENUM } from '../handbookBaseForm.utils';
import s from './HandbookBaseFormMemo.module.scss';

const HandbookBaseFormMemo = () => {
  return (
    <FileField
      maxSize={20}
      availableTypes={AVAILABLE_TYPES}
      formItemConfig={{
        label: t('guide_handbook_memo'),
        name: HANDBOOK_BASE_FORM_ENUM.MEMO,
      }}
      previewConfig={{
        iconName: 'docTypePdf',
        className: s.preview,
        showDelete: true,
        showDownload: true,
      }}
      uploadConfig={{
        description: t('validation_upload_an_image_restrictions_guide'),
      }}
    />
  );
};

const AVAILABLE_TYPES = ['application/pdf', 'application/msword'];

export default HandbookBaseFormMemo;
