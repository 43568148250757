import { Form } from 'gazprom-ui-lib';

import CommunityMeetForm from 'containers/community-meet-form';
import {
  COMMUNITY_MEETS_FORM_NAME,
  CommunityMeetsFormResponse,
} from 'containers/community-meet-form/communityMeetForm.utils';

import Header from './containers/header';
import { useHandleSubmit } from './useHandleSubmit.hook';

const MeetsCreate = () => {
  const [form] = Form.useForm<CommunityMeetsFormResponse>();
  const { handleFinish, isCreatingCommunityMeet } = useHandleSubmit();

  return (
    <Form onFinish={handleFinish} form={form} name={COMMUNITY_MEETS_FORM_NAME}>
      <Header isLoading={isCreatingCommunityMeet} />
      <CommunityMeetForm isCreatingOrUpdating={isCreatingCommunityMeet} />
    </Form>
  );
};

export default MeetsCreate;
