export const SALARY_PROJECT_FORM = 'SALARY_PROJECT_FORM';

export enum FORM_ENUM {
  NAME = 'name',
  ORG = 'employerId',
  DESCRIPTION = 'description',
  LINK = 'tariffLink',
  STATEMENT = 'kedoStatementId',
  COVER = 'bannerImg',
  SORT = 'sort',
  PUBLISHED = 'isPublish',
}
export type FormResponseType = {
  [FORM_ENUM.STATEMENT]: string;
  [FORM_ENUM.NAME]: string;
  [FORM_ENUM.ORG]: string;
  [FORM_ENUM.DESCRIPTION]: string;
  [FORM_ENUM.COVER]: string;
  [FORM_ENUM.SORT]: string;
  [FORM_ENUM.LINK]?: string;
  [FORM_ENUM.PUBLISHED]?: boolean;
};
