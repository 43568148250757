import { ReactNode } from 'react';

import { Button, ButtonProps, Flex, FlexProps } from 'gazprom-ui-lib';

export type SegmentedButtonsValues<T> = {
  segment: T;
  text?: ReactNode;
  leftIcon?: ButtonProps['leftIcon'];
  rightIcon?: ButtonProps['rightIcon'];
}[];

type SegmentButtonsProps<T> = {
  segments: SegmentedButtonsValues<T>;
  activeSegment: T;
  handleSegmentChange: (segment: T) => void;
  buttonProps?: ButtonProps;
  flexProps?: FlexProps;
  activeSegmentType?: ButtonProps['type'];
  inactiveSegmentType?: ButtonProps['type'];
  className?: string;
};

const SegmentedButtons = <T,>({
  segments,
  activeSegment,
  handleSegmentChange,
  buttonProps,
  flexProps = { gap: '8' },
  activeSegmentType = 'primary',
  inactiveSegmentType = 'secondary',
  className,
}: SegmentButtonsProps<T>) => {
  const segmentedButtons = segments.map(({ segment, text, ...rest }) => {
    return (
      <Button
        className={className}
        key={String(segment)}
        onClick={() => handleSegmentChange(segment)}
        type={segment === activeSegment ? activeSegmentType : inactiveSegmentType}
        {...buttonProps}
        {...rest}>
        {text}
      </Button>
    );
  });
  return <Flex {...flexProps}>{segmentedButtons}</Flex>;
};

export default SegmentedButtons;
