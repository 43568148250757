import { useNavigate } from 'react-router-dom';
import { SALARY_PROJECT_ROUTE } from 'routes/salary-project/list';
import { t } from 'tools/i18n';

import { useCreateSalaryProjectMutation } from 'services/private/privateApiService';
import { CreateSalaryProjectsPropsType } from 'services/private/privateApiService.types';

import { Button, handleRequest } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import SalaryProjectForm from 'containers/salary-project-form';
import {
  FORM_ENUM,
  FormResponseType,
  SALARY_PROJECT_FORM,
} from 'containers/salary-project-form/salaryProjectForm.utils';

const SalaryProjectCreate = () => {
  const navigate = useNavigate();

  const [create, { isLoading }] = useCreateSalaryProjectMutation();

  const handleSubmit = (formResponse: FormResponseType) => {
    const body: CreateSalaryProjectsPropsType = {
      name: formResponse[FORM_ENUM.NAME],
      bannerImg: formResponse[FORM_ENUM.COVER],
      description: formResponse[FORM_ENUM.DESCRIPTION],
      employerId: formResponse[FORM_ENUM.ORG],
      kedoStatementId: formResponse[FORM_ENUM.STATEMENT],
      sort: Number(formResponse[FORM_ENUM.SORT]),
      isPublish: !!formResponse[FORM_ENUM.PUBLISHED],
    };

    if (formResponse[FORM_ENUM.LINK]) {
      body.tariffLink = formResponse[FORM_ENUM.LINK];
    }

    create(body).then(
      handleRequest({
        onSuccess: () => navigate(SALARY_PROJECT_ROUTE),
      }),
    );
  };

  return (
    <>
      <ContentHeader showBorder>
        <ContentHeader.Title goBackLink={SALARY_PROJECT_ROUTE}>
          {t('salary_project_add')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="ok" htmlType="submit" form={SALARY_PROJECT_FORM} loading={isLoading}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <SalaryProjectForm handleSubmit={handleSubmit} loading={isLoading} />
    </>
  );
};

export default SalaryProjectCreate;
