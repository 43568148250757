import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_NEWS_FORM_ENUM } from '../../form.utils';

const Title = () => {
  const form = Form.useFormInstance();
  const titleLength = Form.useWatch('title', form)?.length ?? 0;

  return (
    <Form.Item
      name={COMMUNITY_NEWS_FORM_ENUM.TITLE}
      label={
        <Flex align="center" justify="space-between">
          <Typography.Text strong size="16">
            {t('common_name')}
          </Typography.Text>
          <Typography.Text size="12" weight="400" type="secondary">
            {t('common_symbol_count', {
              current: titleLength ?? 0,
              total: 120,
            })}
          </Typography.Text>
        </Flex>
      }
      normalize={normalizeTextField}
      rules={[formRules.required]}>
      <Input placeholder={t('common_enter_text')} maxLength={120} />
    </Form.Item>
  );
};

export default Title;
