import { t } from 'tools/i18n';

import { Flex, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { CommunityDiscussionType } from 'types/community.types';

const useColumns = (): TableProps<CommunityDiscussionType>['columns'] => {
  const discussionTitleRenderer = (title: string) => (
    <TableText size="14" weight="500">
      {title}
    </TableText>
  );

  const discussionCommentsRenderer = (count: number) => (
    <Flex align="center" gap="8">
      <Icon name="comment" color="var(--color-primary-6)" />
      <Typography.Text size="14" weight="500" type="primary">
        {count}
      </Typography.Text>
    </Flex>
  );

  const isPublishRenderer = (_: unknown, row: CommunityDiscussionType) => (
    <Icon
      name="okFilled"
      color={`var(${row?.isPublish ? '--color-success' : '--color-placeholder-25'})`}
    />
  );

  const updatedAtRenderer = (_: unknown, row: CommunityDiscussionType) => (
    <TableDate date={row.updatedAt} />
  );

  const canCommentRenderer = (canComment: boolean) => (
    <Icon name={canComment ? 'lockOpenRight' : 'lockFilled'} color="var(--color-primary-6)" />
  );

  return [
    {
      title: t('community_discussion_title'),
      dataIndex: 'title',
      render: discussionTitleRenderer,
    },
    {
      title: t('common_comments'),
      dataIndex: 'commentCount',
      render: discussionCommentsRenderer,
    },
    {
      title: t('common_publication'),
      align: 'center' as const,
      width: 100,
      dataIndex: 'isPublish',
      render: isPublishRenderer,
    },
    {
      title: t('news_updated'),
      width: 110,
      sorter: true,
      align: 'center' as const,
      dataIndex: 'updatedAt',
      render: updatedAtRenderer,
      defaultSortOrder: 'descend',
    },
    {
      title: t('common_type'),
      align: 'center' as const,
      width: 100,
      dataIndex: 'canComment',
      render: canCommentRenderer,
    },
  ];
};

export default useColumns;
