import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

export type FormResponseType = PaginationPropsType & {
  property: string;
  order: SORT_ENUM;
  search?: string;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
};

export type CommunityEntity = 'news' | 'meets';
