import { createApi } from '@reduxjs/toolkit/query/react';

import { C_BASE_QUERY_WITH_AUTH_V2 } from 'store/services/utils';

import { C_SERVICE_TAGS_LIST } from 'constants/serviceTags';

export const API_NAME = '1cApiV2';

const cInitialApiServiceV2 = createApi({
  reducerPath: API_NAME,
  baseQuery: C_BASE_QUERY_WITH_AUTH_V2,
  keepUnusedDataFor: 0,
  endpoints: () => ({}),
  tagTypes: C_SERVICE_TAGS_LIST,
});

export default cInitialApiServiceV2;
