import { useState } from 'react';

import { t } from 'tools/i18n';

import { Checkbox, Flex, Form } from 'gazprom-ui-lib';

import NumberFieldWithText from '../formBoody/containers/number-field-with-text';
import s from './NotificationInterval.module.scss';

interface Props {
  initialValue: number | undefined;
  fieldName: string;
}

const NotificationInterval = (props: Props) => {
  const { initialValue, fieldName } = props;

  const [showInput, setShowInput] = useState(!!initialValue);
  const form = Form.useFormInstance();

  const toggleShowInput = () =>
    setShowInput((prev) => {
      if (prev) {
        form.resetFields([fieldName]);
      }
      return !prev;
    });

  return (
    <Flex vertical gap="12" className={s.wrapper}>
      <Checkbox checked={showInput} onChange={toggleShowInput}>
        {t('surveys_send_notifications')}
      </Checkbox>

      <NumberFieldWithText
        initialValue={initialValue}
        fieldName={fieldName}
        shouldRender={showInput}
        firstPart={t('surveys_send_notifications_first_part')}
        secondPart={t('surveys_send_notifications_second_part')}
      />
    </Flex>
  );
};

export default NotificationInterval;
