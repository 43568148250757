import { Dispatch, SetStateAction } from 'react';

import { useVisibility } from 'utils/useVisibility.hook';

import { CommunityDiscussionType } from 'types/community.types';

interface Props {
  setDiscussion: Dispatch<SetStateAction<CommunityDiscussionType | null>>;
}

export const useDiscussionModalConfig = (props: Props) => {
  const { setDiscussion } = props;
  const [disussionModalOpened, { close, open }] = useVisibility();

  const handleOpen = (entity: CommunityDiscussionType) => {
    setDiscussion(entity);
    open();
  };

  const handleClose = () => {
    setDiscussion(null);
    close();
  };

  return { handleClose, handleOpen, disussionModalOpened };
};
