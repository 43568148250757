import { useMemo } from 'react';

import { t } from 'tools/i18n';

import { CEmployerType, UpdateEmployer1cByIdPropsType } from 'services/1c/cApiService.types';
import { UpdateEmployerSettingsPropsType } from 'services/employer-settings/employerSettingsApiService.types';

import { Checkbox, ColumnsType, Radio } from 'gazprom-ui-lib';

interface UseColumnsProps {
  updateEmployer1cById: (params: UpdateEmployer1cByIdPropsType) => void;
  updateEmployerSettings: (params: UpdateEmployerSettingsPropsType) => void;
}

const useColumns = (props: UseColumnsProps): ColumnsType<CEmployerType> => {
  const { updateEmployer1cById, updateEmployerSettings } = props;

  return useMemo(
    () => [
      {
        title: t('common_name'),
        dataIndex: 'name',
        render: (_: unknown, row: CEmployerType) => {
          const { actualNameCode, shortName, name1c, fullName } = row;

          const updateEmployerName = (
            codeName: UpdateEmployerSettingsPropsType['employerNameCode'],
          ) => {
            updateEmployerSettings({
              id: row.id,
              employerNameCode: codeName,
            });
          };

          return (
            <Radio.Group
              onChange={(e) => {
                updateEmployerName(e.target.value);
              }}
              value={actualNameCode}
              direction="vertical">
              <Radio.Button value="SHORT_NAME" disabled={!shortName?.length}>
                {shortName?.length ? shortName : t('common_no_value')}
              </Radio.Button>
              <Radio.Button value="NAME" disabled={!name1c?.length}>
                {name1c?.length ? name1c : t('common_no_value')}
              </Radio.Button>
              <Radio.Button value="FULL_NAME" disabled={!fullName?.length}>
                {fullName?.length ? fullName : t('common_no_value')}
              </Radio.Button>
            </Radio.Group>
          );
        },
      },
      {
        title: t('1c_branch_organization'),
        dataIndex: 'isBranch',
        align: 'center',
        width: 180,
        render: (isBranch: boolean) => <Checkbox checked={isBranch} disabled />,
      },
      {
        title: t('common_kedo'),
        dataIndex: 'signEnabled',
        align: 'center',
        width: 180,
        render: (signEnabled: boolean, row: CEmployerType) => (
          <Checkbox
            disabled={!row.canIssueCert}
            checked={signEnabled}
            onChange={(e) => {
              updateEmployer1cById({
                id: row.id,
                signEnabled: e.target.checked,
                canIssueCert: !!row.canIssueCert,
              });
            }}
          />
        ),
      },
      {
        title: t('common_certificate_issue'),
        dataIndex: 'canIssueCert',
        align: 'center',
        width: 180,
        render: (canIssueCert: boolean, row: CEmployerType) => (
          <Checkbox
            checked={canIssueCert}
            onChange={(e) => {
              updateEmployer1cById({
                id: row.id,
                signEnabled: !!row.signEnabled,
                canIssueCert: e.target.checked,
              });
            }}
          />
        ),
      },
    ],
    [updateEmployer1cById, updateEmployerSettings],
  );
};

export default useColumns;
