import { Dispatch, SetStateAction } from 'react';

import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useUpdateGroupMutation } from 'services/employee-groups-new/employeeGroupsNewApiService';
import { useAppSelector } from 'store';

import {
  Button,
  Form,
  Icon,
  Modal,
  TextArea,
  TextAreaProps,
  Typography,
  handleRequest,
} from 'gazprom-ui-lib';

import { EMPLOYEE_GROUPS_FORM_ENUM, getResultNode } from '../../employeeGroupsCreate.utils';
import s from './SaveModal.module.scss';

interface Props {
  modalTitle: string;
  handleModalClose: () => void;
  setModalTitle: Dispatch<SetStateAction<string>>;
}

const SaveModal = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const { modalTitle, handleModalClose, setModalTitle } = props;

  const form = Form.useFormInstance();
  const [updateGroup] = useUpdateGroupMutation();

  const { checkedNodes, partialChecked, employees } = useAppSelector(
    (store) => store.employeeGroupsSlice,
  );

  const handleTextAreaChange: TextAreaProps['onChange'] = (e) => setModalTitle(e.target.value);

  const onHandleModalSave = () => {
    if (id) {
      form.setFieldValue(EMPLOYEE_GROUPS_FORM_ENUM.NAME, modalTitle);

      const mappedChecked = checkedNodes.map(getResultNode);
      const mappedPartial = partialChecked.map(getResultNode);
      const requestBody = {
        id: id,
        body: {
          title: modalTitle,
          employersData: {
            partialChecked: mappedPartial,
            checked: mappedChecked,
          },
          employeesData: employees,
        },
      };

      updateGroup(requestBody).then(
        handleRequest({
          onSuccess: handleModalClose,
        }),
      );
    }
  };

  return (
    <Modal open={!!modalTitle} onCancel={handleModalClose} className={s.modal}>
      <div className={s.header}>
        <Typography.Title level={4}>{t('employee_groups_edit_title')}</Typography.Title>
        <button onClick={handleModalClose}>
          <Icon name="delete" size={15} />
        </button>
      </div>

      <div className={s.body}>
        <div className={s.bodyTexts}>
          <Typography.Text size="14" weight="500" type="primary">
            {t('common_title')}
          </Typography.Text>
          <Typography.Text size="12" type="secondary">
            {t('common_symbol_count', {
              current: modalTitle.length ?? 0,
              total: 100,
            })}
          </Typography.Text>
        </div>
        <TextArea
          placeholder={t('common_enter_text')}
          value={modalTitle}
          onChange={handleTextAreaChange}
          maxLength={100}
        />
      </div>
      <div className={s.buttons}>
        <Button
          type="primary"
          leftIcon="ok"
          htmlType="button"
          disabled={modalTitle.length < 1}
          onClick={onHandleModalSave}>
          {t('common_save')}
        </Button>
        <Button type="secondary" onClick={handleModalClose}>
          {t('common_close')}
        </Button>
      </div>
    </Modal>
  );
};

export default SaveModal;
