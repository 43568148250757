import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { t } from 'tools/i18n';
import { useVisibility } from 'utils/useVisibility.hook';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { DEFAULT_FORM_STATE, FormResponseType } from './accessControlUsers.utils';
import AccessCodeAllSentModal from './containers/access-code-all-sent-modal';
import AccessCodeModal from './containers/access-code-modal';
import Filters from './containers/filters';
import Table from './containers/table';
import useGetData from './useGetData.hook';

interface HandleOpenAccessCodeModalProps {
  personId: string;
}

const AccessControlUsers = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state, pathname } = location || {};

  const [accessCodeModalIsOpened, { open, close }] = useVisibility();
  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(null);
  const [sendUsersCount, setSendUsersCount] = useState<number | null>(null);

  const [form] = Form.useForm<FormResponseType>();

  const { isFetching, data, isLoading } = useGetData({ form });

  useEffect(() => {
    const stateFromNavigationExists = state && (state.requestBody || state.role);
    if (stateFromNavigationExists) {
      let bodyToSet: FormResponseType = {
        filters: {},
        ...DEFAULT_FORM_STATE,
      };
      if (state.role) {
        bodyToSet.filters!.roleIds = [state.role];
      }
      if (state.requestBody) {
        bodyToSet = { ...bodyToSet, ...state.requestBody };
      }

      form.setFieldsValue(bodyToSet);
      navigate(pathname, { replace: true });
    }
  }, [form, navigate, pathname, state]);

  const handleOpenAccessCodeModal = ({ personId }: HandleOpenAccessCodeModalProps) => {
    setSelectedPersonId(personId);
    open();
  };

  const handleOpenAccessCodeAllModal = () => {
    open();
  };

  const handleCloseAccessCodeModal = () => {
    close();
    setSelectedPersonId(null);
  };

  const handleSentModalClose = () => {
    setSendUsersCount(null);
  };

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title total={data?.totalCount ?? ''}>
          {t('common_users_list')}
        </ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters handleOpenAccessCodeAllModal={handleOpenAccessCodeAllModal} />
        <Table
          dataSource={data}
          loading={isFetching}
          isInitialLoading={isLoading}
          handleOpenAccessCodeModal={handleOpenAccessCodeModal}
        />
      </FiltersV2>

      <AccessCodeModal
        setSendUsersCount={setSendUsersCount}
        personId={selectedPersonId}
        open={accessCodeModalIsOpened}
        close={handleCloseAccessCodeModal}
      />

      <AccessCodeAllSentModal sendUsersCount={sendUsersCount} onCancel={handleSentModalClose} />
    </WithOverflow>
  );
};

export default AccessControlUsers;
