import { useDebounce } from 'utils/useDebounce.hook';

import { useGetCommunityMeetsQuery } from 'services/communities/communitiesApiService';
import { GetCommunityMeetsPropsType } from 'services/communities/communityApiService.types';

import { FormResponseType } from './meetsTab.utils';

interface Props {
  communityId: string;
  requestBody: FormResponseType;
}

export const useRequest = (props: Props) => {
  const { communityId, requestBody } = props;

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { property, order, search, filters, ...remainingRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetCommunityMeetsPropsType = {
    communityId,
    ...remainingRequestBody,
    ...filters,
    ...(search && { title: search }),
    ...(property && order && { sort: [{ order, property }] }),
  };

  const {
    data: { items: dataSource = [], totalCount: total = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetCommunityMeetsQuery({ ...actualRequestBody }, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!search?.trim() || !!filters?.categoryIds?.length;

  return { isInitialLoading, loading, dataSource, total, hasFiltersApplied };
};
