import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useDeleteNotificationNewsletterMutation } from 'services/notificationV2/notificationV2ApiService';

import { handleRequest } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleGoBack: () => void;
}

const NotificationNewsletterEditDeleteModal = (props: Props) => {
  const { open, handleClose, handleGoBack } = props;
  const params = useParams<{ newsletterId: string }>();

  const [deleteNewsletter, { isLoading }] = useDeleteNotificationNewsletterMutation();

  const handleDelete = () => {
    if (params.newsletterId)
      deleteNewsletter(params.newsletterId).then(
        handleRequest({
          onSuccess: handleGoBack,
        }),
      );
  };

  return (
    <RemoveConfirm
      isLoading={isLoading}
      onConfirm={handleDelete}
      icon="binFilled"
      isOpen={open}
      onCancel={handleClose}
      buttonText={t('common_delete')}
      title={t('common_accept_remove')}
      subtitle={t('notification_newsletter_delete')}
      type="danger"
    />
  );
};

export default NotificationNewsletterEditDeleteModal;
