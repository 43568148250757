import CommunityCategories from 'pages/communities/community-categories';
import CommunityManagement from 'pages/communities/community-management';
import CommunityManagementView from 'pages/communities/community-management/community-managament-view';
import DiscussionsDetails from 'pages/communities/community-management/community-managament-view/containers/tabs/containers/discussions/discussions-details';
import MeetsCreate from 'pages/communities/community-management/community-managament-view/containers/tabs/containers/meets/meets-create';
import MeetsEdit from 'pages/communities/community-management/community-managament-view/containers/tabs/containers/meets/meets-edit';
import NewsCreateEdit from 'pages/communities/community-management/community-managament-view/containers/tabs/containers/news/news-create-edit';
import NewsDetails from 'pages/communities/community-management/community-managament-view/containers/tabs/containers/news/news-details';
import CommunityManagementCreateEdit from 'pages/communities/community-management/community-management-create-edit';
import CommunityManagementSubscribers from 'pages/communities/community-management/community-management-subscribers';
import CommunityManagementSubscribersAddInvite from 'pages/communities/community-management/community-management-subscribers/community-magement-subscribers-add-invite';

import { GLOBAL_ROLES } from 'types/roles.types';

import { RouteConfig } from '../routes';
import {
  COMMUNITY_CATEGORIES_ROUTE,
  COMMUNITY_MANAGEMENT_CREATE_ROUTE,
  COMMUNITY_MANAGEMENT_DISCUSSIONS_BY_ID_ROUTE,
  COMMUNITY_MANAGEMENT_EDIT_ROUTE,
  COMMUNITY_MANAGEMENT_MEET_CREATE_ROUTE,
  COMMUNITY_MANAGEMENT_MEET_EDIT_ROUTE,
  COMMUNITY_MANAGEMENT_NEWS_BY_ID_ROUTE,
  COMMUNITY_MANAGEMENT_NEWS_CREATE_ROUTE,
  COMMUNITY_MANAGEMENT_ROUTE,
  COMMUNITY_MANAGEMENT_SUBSCRIBERS_ADD_ROUTE,
  COMMUNITY_MANAGEMENT_SUBSCRIBERS_INVITE_ROUTE,
  COMMUNITY_MANAGEMENT_SUBSCRIBERS_ROUTE,
  COMMUNITY_MANAGEMENT_VIEW_ROUTE,
} from './list';

const COMMUNITY_CATEGORIES_ROLES = [
  GLOBAL_ROLES.ADMIN,
  GLOBAL_ROLES.COMMUNITY_ADMIN,
  GLOBAL_ROLES.COMMUNITY_MODERATOR,
];

const COMMUNITY_MANAGEMENT_ROLES = [GLOBAL_ROLES.COMMUNITY_ADMIN, GLOBAL_ROLES.COMMUNITY_MODERATOR];

export const communityRoutes: RouteConfig[] = [
  {
    path: COMMUNITY_CATEGORIES_ROUTE,
    element: <CommunityCategories />,
    roles: COMMUNITY_CATEGORIES_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_ROUTE,
    element: <CommunityManagement />,
    roles: COMMUNITY_MANAGEMENT_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_CREATE_ROUTE,
    element: <CommunityManagementCreateEdit />,
    // ! Only COMMUNITY_ADMIN can create
    roles: [GLOBAL_ROLES.COMMUNITY_ADMIN],
  },
  {
    path: COMMUNITY_MANAGEMENT_EDIT_ROUTE,
    element: <CommunityManagementCreateEdit />,
    roles: COMMUNITY_MANAGEMENT_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_VIEW_ROUTE,
    element: <CommunityManagementView />,
    roles: COMMUNITY_MANAGEMENT_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_SUBSCRIBERS_ROUTE,
    element: <CommunityManagementSubscribers />,
    roles: COMMUNITY_MANAGEMENT_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_SUBSCRIBERS_ADD_ROUTE,
    element: <CommunityManagementSubscribersAddInvite pageType="SUBSCRIBERS" />,
    roles: COMMUNITY_MANAGEMENT_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_SUBSCRIBERS_INVITE_ROUTE,
    element: <CommunityManagementSubscribersAddInvite pageType="INVITES" />,
    roles: COMMUNITY_MANAGEMENT_ROLES,
  },
  {
    path: COMMUNITY_MANAGEMENT_NEWS_CREATE_ROUTE,
    element: <NewsCreateEdit />,
  },
  {
    path: COMMUNITY_MANAGEMENT_NEWS_BY_ID_ROUTE,
    element: <NewsDetails />,
  },
  {
    path: COMMUNITY_MANAGEMENT_DISCUSSIONS_BY_ID_ROUTE,
    element: <DiscussionsDetails />,
  },
  {
    path: COMMUNITY_MANAGEMENT_MEET_CREATE_ROUTE,
    element: <MeetsCreate />,
  },
  {
    path: COMMUNITY_MANAGEMENT_MEET_EDIT_ROUTE,
    element: <MeetsEdit />,
  },
];
