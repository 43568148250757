import { t } from 'tools/i18n';

import { Flex, Icon, TableProps, Typography } from 'gazprom-ui-lib';

import { MachineAuthorityType } from 'types/machineAuthorities.types';

import { MA_STATUS_TITLE } from './documentsMachineAuthorities.utils';

const useColumns = (): TableProps<MachineAuthorityType>['columns'] => {
  const organizationRenderer = (_: unknown, row: MachineAuthorityType) => {
    const { principalName, principalInn, principalOgrn, principalKpp } = row;

    return (
      <Flex vertical gap="4">
        <Typography.Text weight="500" size="14">
          {principalName}
        </Typography.Text>
        <Flex vertical>
          <Flex>
            <Typography.Text weight="500">{t('common_inn')}</Typography.Text>:
            <Typography.Text>{principalInn}</Typography.Text>
          </Flex>
          <Flex>
            <Typography.Text weight="500">{t('common_kpp')}</Typography.Text>:
            <Typography.Text>{principalOgrn}</Typography.Text>
          </Flex>
          <Flex>
            <Typography.Text weight="500">{t('common_ogrn')}</Typography.Text>:
            <Typography.Text>{principalKpp}</Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const authorizedRenderer = (_: unknown, row: MachineAuthorityType) => {
    const {
      representativeName,
      representativeMiddleName,
      representativeSurname,
      representativeInnFl,
      representativeSnils,
    } = row;

    return (
      <Flex vertical gap="4">
        <Typography.Text
          weight="500"
          size="14">{`${representativeSurname} ${representativeName} ${representativeMiddleName}`}</Typography.Text>
        <Flex vertical>
          <Flex>
            <Typography.Text weight="500">{t('common_inn')}</Typography.Text>:
            <Typography.Text>{representativeInnFl}</Typography.Text>
          </Flex>
          <Flex>
            <Typography.Text weight="500">{t('common_snils')}</Typography.Text>:
            <Typography.Text>{representativeSnils}</Typography.Text>
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const kedoStatusRenderer = (isActive: boolean) => {
    return (
      <Icon
        name="okFilled"
        color={`var(${isActive ? '--color-success' : '--color-placeholder-25'})`}
      />
    );
  };

  const statusRenderer = (status: keyof typeof MA_STATUS_TITLE) => {
    return (
      <Typography.Text size="14" weight="500">
        {MA_STATUS_TITLE[status]}
      </Typography.Text>
    );
  };

  return [
    {
      title: t('documents_machine_authorities_organizations'),
      dataIndex: 'employee',
      align: 'left',
      render: organizationRenderer,
    },
    {
      title: t('documents_machine_authorities_authorized'),
      dataIndex: '',
      align: 'left',
      render: authorizedRenderer,
    },
    {
      title: t('documents_machine_authorities_kedo'),
      dataIndex: 'isActive',
      align: 'center',
      sorter: true,
      width: 180,
      render: kedoStatusRenderer,
    },
    {
      title: t('documents_machine_authorities_status'),
      dataIndex: 'status',
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      width: 180,
      render: statusRenderer,
    },
  ];
};

export default useColumns;
