import { useParams } from 'react-router-dom';

import {
  useGetCommunitySubscribersDivisionsQuery,
  useGetCommunitySubscribersEmployersQuery,
} from 'services/communities/communitiesApiService';

export const useGetFiltersData = () => {
  const { id } = useParams() as { id: string };

  const { data: employers = [] } = useGetCommunitySubscribersEmployersQuery({ id });

  const { data: divisions = [] } = useGetCommunitySubscribersDivisionsQuery({ id });

  const subscribersEmployers = employers.map(({ id, name }) => ({ value: id, label: name }));
  const subscribersDivisions = divisions.map(({ id, name }) => ({ value: id, label: name }));

  return { subscribersEmployers, subscribersDivisions };
};
