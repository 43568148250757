import { PropsWithChildren } from 'react';

import cn from 'classnames';

import { Flex, FlexProps } from 'gazprom-ui-lib';

import s from './ContentHeader.module.scss';

interface Props extends PropsWithChildren {
  align?: FlexProps['align'];
  showBorder?: boolean;
}

const ContentHeader = (props: Props) => {
  const { children, align = 'center', showBorder = false } = props;

  return (
    <Flex justify="space-between" align={align} className={cn(s.wrapper, showBorder && s.border)}>
      {children}
    </Flex>
  );
};

export default ContentHeader;
