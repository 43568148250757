import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Flex, Form, Input, Typography } from 'gazprom-ui-lib';

import s from './NumberFieldWithText.module.scss';

interface Props {
  shouldRender: boolean;
  firstPart: string;
  secondPart: string;
  fieldName: string;
  initialValue: number | undefined;
}

const NumberFieldWithText = (props: Props) => {
  const { firstPart, secondPart, shouldRender, fieldName, initialValue } = props;

  if (!shouldRender) return null;

  return (
    <Form.Item
      initialValue={initialValue}
      className={s.formItem}
      name={fieldName}
      rules={shouldRender ? [formRules.required] : undefined}>
      <Flex gap="12" align="center">
        <Typography.Text size="14">{firstPart}</Typography.Text>
        <Input
          defaultValue={initialValue}
          className={s.numberInput}
          placeholder={t('common_enter_number')}
          type="number"
          min={1}
          step={1}
        />
        <Typography.Text size="14">{secondPart}</Typography.Text>
      </Flex>
    </Form.Item>
  );
};

export default NumberFieldWithText;
