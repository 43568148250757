import { Dayjs } from 'dayjs';
import { t } from 'tools/i18n';
import { prepareDateAndTime } from 'utils/date.utils';

import { UpdateCommunityMeetPropsType } from 'services/communities/communityApiService.types';

import { FormFileType } from 'containers/file-field';

import {
  COMMUNITY_ENTITY_STATUS,
  COMMUNITY_MEET_REPEAT_INTERVAL,
  COMMUNITY_MEET_WEEK_DAYS,
} from 'types/communityMeet.types';

export enum COMMUNITY_MEETS_FORM_ENUM {
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  COVER = 'COVER',
  CITY = 'CITY',
  LOCATION = 'LOCATION',
  EVENT_DATE = 'EVENT_DATE',
  EVENT_TIME = 'EVENT_TIME',
  CATEGORIES = 'CATEGORIES',
  UNLIMITED_PARTICIPATION = 'UNLIMITED_PARTICIPATION',
  PARTICIPANTS = 'PARTICIPANTS',
  REGISTRATION_AVAILABLE = 'REGISTRATION_AVAILABLE',
  DOCS = 'DOCS',
  SPECIAL_DATE = 'SPECIAL_DATE',
  IS_PUBLISH = 'IS_PUBLISH',
  REPEAT_INTERVAL = 'REPEAT_INTERVAL',
  WEEK_DAYS = 'WEEK_DAYS',
  STATUS = 'status',
}

export interface CommunityMeetsFormResponse {
  [COMMUNITY_MEETS_FORM_ENUM.TITLE]: string;
  [COMMUNITY_MEETS_FORM_ENUM.DESCRIPTION]: string;
  [COMMUNITY_MEETS_FORM_ENUM.COVER]: FormFileType;
  [COMMUNITY_MEETS_FORM_ENUM.CITY]: string;
  [COMMUNITY_MEETS_FORM_ENUM.LOCATION]: string;
  [COMMUNITY_MEETS_FORM_ENUM.EVENT_DATE]: Dayjs;
  [COMMUNITY_MEETS_FORM_ENUM.EVENT_TIME]: Dayjs;
  [COMMUNITY_MEETS_FORM_ENUM.CATEGORIES]: string[];
  [COMMUNITY_MEETS_FORM_ENUM.PARTICIPANTS]?: number;
  [COMMUNITY_MEETS_FORM_ENUM.UNLIMITED_PARTICIPATION]?: boolean;
  [COMMUNITY_MEETS_FORM_ENUM.REGISTRATION_AVAILABLE]?: boolean;
  [COMMUNITY_MEETS_FORM_ENUM.DOCS]?: boolean;
  [COMMUNITY_MEETS_FORM_ENUM.SPECIAL_DATE]?: boolean;
  [COMMUNITY_MEETS_FORM_ENUM.IS_PUBLISH]?: boolean;
  [COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL]?: COMMUNITY_MEET_REPEAT_INTERVAL;
  [COMMUNITY_MEETS_FORM_ENUM.WEEK_DAYS]?: COMMUNITY_MEET_WEEK_DAYS[];
  [COMMUNITY_MEETS_FORM_ENUM.STATUS]?: COMMUNITY_ENTITY_STATUS;
}

export const REPEAT_INTERVAL_OPTIONS = [
  {
    label: t('common_daily'),
    value: COMMUNITY_MEET_REPEAT_INTERVAL.EVERY_DAY,
  },
  {
    label: t('common_weekly'),
    value: COMMUNITY_MEET_REPEAT_INTERVAL.EVERY_WEEK,
  },
  {
    label: t('common_monthly'),
    value: COMMUNITY_MEET_REPEAT_INTERVAL.EVERY_MONTH,
  },
  {
    label: t('common_yearly'),
    value: COMMUNITY_MEET_REPEAT_INTERVAL.EVERY_YEAR,
  },
];

export const WEEK_DAYS_OPTIONS = [
  {
    label: t('common_monday'),
    value: COMMUNITY_MEET_WEEK_DAYS.MON,
  },
  {
    label: t('common_tuesday'),
    value: COMMUNITY_MEET_WEEK_DAYS.TUE,
  },
  {
    label: t('common_wednesday'),
    value: COMMUNITY_MEET_WEEK_DAYS.WED,
  },
  {
    label: t('common_thursday'),
    value: COMMUNITY_MEET_WEEK_DAYS.THU,
  },
  {
    label: t('common_friday'),
    value: COMMUNITY_MEET_WEEK_DAYS.FRI,
  },
  {
    label: t('common_saturday'),
    value: COMMUNITY_MEET_WEEK_DAYS.SAT,
  },
  {
    label: t('common_sunday'),
    value: COMMUNITY_MEET_WEEK_DAYS.SUN,
  },
];

export const prepareCommunityMeetFormPayload = (
  formResponse: CommunityMeetsFormResponse,
): UpdateCommunityMeetPropsType['payload'] => ({
  title: formResponse[COMMUNITY_MEETS_FORM_ENUM.TITLE],
  text: formResponse[COMMUNITY_MEETS_FORM_ENUM.DESCRIPTION],
  cover: formResponse[COMMUNITY_MEETS_FORM_ENUM.COVER],
  categories: formResponse[COMMUNITY_MEETS_FORM_ENUM.CATEGORIES],
  meetDate: prepareDateAndTime(
    formResponse[COMMUNITY_MEETS_FORM_ENUM.EVENT_DATE],
    formResponse[COMMUNITY_MEETS_FORM_ENUM.EVENT_TIME],
  )!.format(),
  city: formResponse[COMMUNITY_MEETS_FORM_ENUM.CITY],
  location: formResponse[COMMUNITY_MEETS_FORM_ENUM.LOCATION],
  maxEmployees: formResponse[COMMUNITY_MEETS_FORM_ENUM.UNLIMITED_PARTICIPATION]
    ? 0
    : Number(formResponse[COMMUNITY_MEETS_FORM_ENUM.PARTICIPANTS]),
  isSpecial: !!formResponse[COMMUNITY_MEETS_FORM_ENUM.SPECIAL_DATE],
  isPublish: !!formResponse[COMMUNITY_MEETS_FORM_ENUM.IS_PUBLISH],
  registrationActive: !!formResponse[COMMUNITY_MEETS_FORM_ENUM.REGISTRATION_AVAILABLE],
  requiresDocs: !!formResponse[COMMUNITY_MEETS_FORM_ENUM.DOCS],
  repeatEvery: formResponse[COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL],
  weekDays: { days: formResponse[COMMUNITY_MEETS_FORM_ENUM.WEEK_DAYS] ?? [] },
  status: formResponse[COMMUNITY_MEETS_FORM_ENUM.STATUS],
});

export const COMMUNITY_MEETS_FORM_NAME = 'COMMUNITY_MEETS_FORM_NAME';
