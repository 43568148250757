import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { useGenerateXmlDocMutation } from 'services/kedo-archive-doc/kedoArchiveDocApiService';

import { Button, Flex, TableProps, Typography } from 'gazprom-ui-lib';

import TablePerson from 'components/table/table-person';

import { KedoArchiveDocType } from 'types/kedoArchiveDoc.types';

import EditableCell from './containers/editable-cell';
import { createKedoArchiveXmlDocDownloadUrl } from './documentsArchiveInfo.utils';

const useColumns = (): TableProps<KedoArchiveDocType>['columns'] => {
  const [generateXml] = useGenerateXmlDocMutation();

  const handleGenerateXmlDoc = (id: string) => {
    generateXml({ kedoArchiveDocIds: [id] });
  };

  const handleDownloadXmlDoc = (fileKey: string) => {
    downloadHandler({
      url: createKedoArchiveXmlDocDownloadUrl(fileKey),
      fileName: t('documents_archive_xml_title'),
    });
  };

  const employeeRenderer = (_: unknown, row: KedoArchiveDocType) => {
    const { kedoArchiveDocEmployee, employerName } = row;

    return (
      <TablePerson
        firstName={kedoArchiveDocEmployee.name}
        lastName={kedoArchiveDocEmployee.lastName}
        position={null}
        department={employerName}
      />
    );
  };

  const documentNameRenderer = (name: string) => {
    return (
      <Typography.Text size="12" weight="500" type="secondary">
        {name}
      </Typography.Text>
    );
  };

  const xmlFileRenderer = (_: unknown, row: KedoArchiveDocType) => {
    if (row.xmlFileKey) {
      return (
        <Flex justify="space-between" align="center">
          <Button onClick={() => handleGenerateXmlDoc(row.id)} type="link" leftIcon="refresh">
            {t('common_update')}
          </Button>
          <Button
            leftIcon="download"
            type="link"
            onClick={() => handleDownloadXmlDoc(row.xmlFileKey!)}
          />
        </Flex>
      );
    }

    return (
      <Button onClick={() => handleGenerateXmlDoc(row.id)} type="link" leftIcon="plus">
        {t('common_generate')}
      </Button>
    );
  };

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      align: 'left',
      render: employeeRenderer,
    },
    {
      title: t('common_document_name'),
      dataIndex: 'docName',
      align: 'left',
      width: 300,
      render: documentNameRenderer,
    },
    {
      title: t('common_document_number'),
      dataIndex: 'docNumber',
      align: 'left',
      render: (_: string, row: KedoArchiveDocType) => (
        <EditableCell row={row} field="docNumber" maxLength={30} />
      ),
    },
    {
      title: t('common_document_date'),
      dataIndex: 'docDate',
      align: 'left',
      render: (_: string, row: KedoArchiveDocType) => (
        <EditableCell
          maxLength={10}
          row={row}
          field="docDate"
          placeholder={t('documents_archive_date_placeholder')}
        />
      ),
    },
    {
      title: t('common_document_type'),
      dataIndex: 'docType',
      align: 'left',
      render: (_: string, row: KedoArchiveDocType) => (
        <EditableCell
          row={row}
          field="docType"
          maxLength={6}
          placeholder={t('common_enter_text')}
        />
      ),
    },
    {
      title: t('documents_archive_xml_title'),
      dataIndex: 'xmlFile',
      align: 'center',
      width: 240,
      render: xmlFileRenderer,
    },
  ];
};

export default useColumns;
