import { FC } from 'react';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { Trans, t } from 'tools/i18n';

import { Avatar, Icon, Popover, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';

import { EmployeeType } from 'types/employee.types';
import { FileType } from 'types/file.types';
import { ExecutorType } from 'types/review-task.types';

import s from './Person.module.scss';
import { PERSON_TYPE_ENUM, getConfig } from './person.utils';

interface DocumentsStatementsViewFilesPersonProps {
  comment: string | null;
  createdAt: string;
  employee: EmployeeType;
  executor: ExecutorType;
  finishDate?: string;
  id: string;
  num: number | null;
  status: {
    code: string;
    name: string;
    description: string;
    state: string;
  };
  type: string;
  title: string;
  files: FileType[];
}

const Person: FC<DocumentsStatementsViewFilesPersonProps> = (props) => {
  const { executor, employee, status, finishDate } = props;

  const { icon, type } = getConfig(status.state);
  const date = finishDate;

  const { person } = executor;
  const { position } = employee;
  const { firstName, lastName } = person;
  const shouldShowBadge = icon && type;
  const i18nCurrentKey = 'documents_statements_person';

  const popoverContent = (
    <Typography.Text className={s.popoverContent}>
      {t(type === PERSON_TYPE_ENUM.ERROR ? 'common_reject_date' : 'common_sign_date', {
        date: date
          ? dayjs.utc(date).local().format('DD.MM.YYYY, HH:mm')
          : t('common_waiting_sign').toLocaleLowerCase(),
      })}
    </Typography.Text>
  );

  return (
    <div className={s.container}>
      <div className={s.avatarContainer}>
        <div className={s.avatarBadge}>
          <Avatar
            firstName={firstName}
            lastName={lastName ?? ''}
            alt="аватар пользователя"
            size="40"
            className={s.avatar}
          />
          {shouldShowBadge && (
            <Popover
              destroyTooltipOnHide
              overlayClassName={s.popover}
              content={popoverContent}
              arrow={false}
              placement="rightTop"
              align={{ offset: [0, 20] }}
              trigger="hover">
              <div className={classNames(s.badgeBackgound, s[type])}>
                <Icon name={icon as TIconsLiteral} size={10} className={s.badge} />
              </div>
            </Popover>
          )}
        </div>
        <div className={s.info}>
          <Trans i18nKey={i18nCurrentKey} values={{ firstName, lastName }} className={s.name}>
            <Typography.Text size="14" weight="500" />
          </Trans>
          {position && (
            <Typography.Text
              size="10"
              type="secondary"
              className={s.position}
              title={position.name}>
              {position.name}
            </Typography.Text>
          )}
        </div>
      </div>
      <KedoStatusTag
        size="30"
        currentKedoStatus={{
          ...status,
          title: status.name,
          comment: '',
          createdAt: '',
          status: '',
          titleAlt: '',
        }}
      />
    </div>
  );
};

export default Person;
