import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetDeclarationAttributeVariablesQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form, Typography } from 'gazprom-ui-lib';

import DocumentsDescriptionsFormUpload from 'containers/documents-declarations-form/documents-declarations-form-upload';
import WithLoader from 'containers/wrappers/with-loader';

import s from './DocumentsDeclarationsTemplate.module.scss';
import { renderAttribute } from './documentsDeclarationsTemplate.utils';

interface Props {
  isDisabled?: boolean;
}

const DocumentsDeclarationsTemplate = (props: Props) => {
  const { isDisabled } = props;

  const [form] = Form.useForm();
  const { id } = useParams();
  const { data: attributeVariables, isLoading: attributeVariablesIsLoading } =
    useGetDeclarationAttributeVariablesQuery(id!);

  const renderAttributeGroup = (attributesObj?: Record<string, string>) => {
    if (!attributesObj) {
      return null;
    }

    return Object.keys(attributesObj).map(renderAttribute(attributesObj));
  };

  const hasAdditionalVariables = !!Object.keys(attributeVariables?.attributeVariables ?? {}).length;

  return (
    <WithLoader isLoading={attributeVariablesIsLoading}>
      <Form form={form}>
        <div className={s.container}>
          <DocumentsDescriptionsFormUpload isDisabled={isDisabled} />
          <div className={s.sectionContainer}>
            <div className={s.section}>
              <Typography.Text size="14" weight="500">
                {t('documents_declarations_template_main_variables')}
              </Typography.Text>
              <div className={s.list}>
                {renderAttributeGroup(attributeVariables?.commonVariables)}
              </div>
            </div>
            {hasAdditionalVariables && (
              <div className={s.section}>
                <Typography.Text size="14" weight="500">
                  {t('documents_declarations_template_additional_variables')}
                </Typography.Text>
                <div className={s.list}>
                  {renderAttributeGroup(attributeVariables?.attributeVariables)}
                </div>
              </div>
            )}
          </div>
        </div>
      </Form>
    </WithLoader>
  );
};

export default DocumentsDeclarationsTemplate;
