import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { TabsProps } from 'gazprom-ui-lib';

import { COMMUNITY_ENTITY_SOURCE_TYPE, COMMUNITY_ENTITY_STATUS } from 'types/communityMeet.types';

import DiscussionsTab from './containers/discussions/discussions-tab';
import MeetsTab from './containers/meets/meets-tab';
import NewsTab from './containers/news/news-tab';
import SuggestedTab from './containers/suggested';

enum COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM {
  NEWS = 'NEWS',
  MEET = 'MEET',
  ARTICLE_DISCUSSIONS = 'ARTICLE_DISCUSSIONS',
  // TODO not implented yet
  // ANKETA = 'ANKETA',
  ARTICLE_SUGGESTED = 'ARTICLE_SUGGESTED',
}

type CommunityManagementViewMapTabValue = {
  key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM;
  label: string;
  children: ReactNode;
};

export const COMMUNITY_MANAGEMENT_VIEW_TABS_MAP: Record<
  COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM,
  CommunityManagementViewMapTabValue
> = {
  NEWS: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.NEWS,
    label: t('common_news'),
    children: <NewsTab />,
  },
  MEET: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.MEET,
    label: t('common_events'),
    children: <MeetsTab />,
  },
  ARTICLE_DISCUSSIONS: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.ARTICLE_DISCUSSIONS,
    label: t('common_discussions'),
    children: <DiscussionsTab />,
  },
  // TODO not implented yet
  // ANKETA: {
  //   key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.ANKETA,
  //   label: t('common_surveys_short'),
  //   children: <div>Опросы</div>,
  // },
  ARTICLE_SUGGESTED: {
    key: COMMUNITY_MANAGEMENT_VIEW_TABS_ENUM.ARTICLE_SUGGESTED,
    label: t('common_suggested'),
    children: <SuggestedTab />,
  },
};

export const tabsItems: TabsProps['items'] = Object.values(COMMUNITY_MANAGEMENT_VIEW_TABS_MAP).map(
  (tab) => tab,
);

type GetCommunityEntityKindProps = {
  sourceType: COMMUNITY_ENTITY_SOURCE_TYPE | undefined;
  status: COMMUNITY_ENTITY_STATUS | undefined;
};

export type CommunityEntityKind =
  | 'admin'
  | 'mobileModeration'
  | 'mobileAccepted'
  | 'mobileRejected';

export const getCommunityEntityKind = ({
  sourceType,
  status,
}: GetCommunityEntityKindProps): CommunityEntityKind => {
  const isMobile = sourceType === COMMUNITY_ENTITY_SOURCE_TYPE.MOBILE;
  const isModeration = status === COMMUNITY_ENTITY_STATUS.MODERATION;
  const isAccepted = status === COMMUNITY_ENTITY_STATUS.ACCEPTED;
  const isRejected = status === COMMUNITY_ENTITY_STATUS.REJECTED;

  if (isMobile && isModeration) {
    return 'mobileModeration';
  } else if (isMobile && isAccepted) {
    return 'mobileAccepted';
  } else if (isMobile && isRejected) {
    return 'mobileRejected';
  } else {
    return 'admin';
  }
};

export const COMMUNITY_ENTITY_STATUS_FIELD = 'status';
