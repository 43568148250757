import { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM, SETTINGS_DECLARATIONS_ROUTE } from 'routes/settings/list';

import { getDeclarationTypeSelector } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import { useAppSelector } from 'store';

import { Form } from 'gazprom-ui-lib';

import {
  DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM,
  DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM,
} from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import { FormInstance } from 'gazprom-ui-lib/dist/components/form';

interface Props {
  form: FormInstance;
}

const useCommonData = (props: Props) => {
  const { form } = props;

  const { id } = useParams<{
    id: string;
    declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM;
  }>();

  const { data: statementData, isLoading: isStatementDataLoading } = useAppSelector(
    getDeclarationTypeSelector.select(id!),
  );

  useEffect(() => {
    if (id && statementData) {
      form.setFieldsValue({
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS]: statementData.isPublish,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.CATEGORY]: statementData?.categoryId,
        [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.EXECUTOR]: statementData?.role1cCode,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DESCRIPTION]: statementData?.description,
        [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.STATEMENT_CODE]: statementData?.statementXmlCode,
        [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.ORDER_CODE]: statementData?.orderXmlCode,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.NAME]: statementData?.name,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ORGANIZATION]: statementData?.ownerEmployerId,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID]:
          statementData.defaultStatementId,
      });
    } else {
      form.resetFields();
    }
  }, [statementData, form, id]);

  const organization = Form.useWatch(DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ORGANIZATION, form);
  const currentReddirectType = organization
    ? DECLARATIONS_TYPE_TAB_ENUM.LOCAL
    : DECLARATIONS_TYPE_TAB_ENUM.COMMON;
  const createRoute = `${SETTINGS_DECLARATIONS_ROUTE}/${currentReddirectType}/edo`;

  return {
    statementData,
    isFetching: isStatementDataLoading,
    createRoute,
  };
};

export default useCommonData;
