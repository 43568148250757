import { t } from 'tools/i18n';

import { TableProps } from 'gazprom-ui-lib';

import TablePerson from 'components/table/table-person';
import TableText from 'components/table/table-text';

import { CommunityMeetParticipantType } from 'types/communityMeet.types';

export const useColumns = (): TableProps<CommunityMeetParticipantType>['columns'] => {
  return [
    {
      title: t('common_employee'),
      dataIndex: 'id',
      render: (_, participant) => <TablePerson {...participant} />,
    },
    {
      title: t('common_phone'),
      dataIndex: 'phone',
      render: (phone: string) => <TableText>{phone}</TableText>,
    },
    {
      title: t('common_email'),
      dataIndex: 'email',
      render: (email: string) => <TableText>{email}</TableText>,
    },
  ];
};
