import { t } from 'tools/i18n';

import { SURVEY_CONDITIONS } from 'types/surveys.types';

type ConditionType = { value: SURVEY_CONDITIONS; label: string };

export const CONDITION_OPTIONS: ConditionType[] = [
  {
    value: SURVEY_CONDITIONS.NDAYS,
    label: t('surveys_conditions_after_employment'),
  },
  {
    value: SURVEY_CONDITIONS.SHIFT_END,
    label: t('surveys_conditions_watch_exit'),
  },
];
