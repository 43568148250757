import { useEffect } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useLocation } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM, SETTINGS_DECLARATIONS_ROUTE } from 'routes/settings/list';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Form } from 'gazprom-ui-lib';

import {
  DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM,
  DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM,
} from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import { FormInstance } from 'gazprom-ui-lib/dist/components/form';

interface Props {
  form: FormInstance;
}

const useCommonData = (props: Props) => {
  const { form } = props;

  const location = useLocation();
  const parentStatementId = location.state?.parentStatementId as string | undefined;

  const {
    data: parentStatementData,
    isLoading: isParentStatementDataLoading,
    isFetching: isParentStatementDataFetching,
  } = useGetDeclarationTypeQuery(parentStatementId ?? skipToken);

  useEffect(() => {
    if (parentStatementData) {
      form.setFieldsValue({
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.CATEGORY]: parentStatementData?.categoryId,
        [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.EXECUTOR]: parentStatementData?.role1cCode,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DESCRIPTION]: parentStatementData?.description,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.NAME]: parentStatementData?.name,
        [DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID]: parentStatementData?.id,
        [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.STATEMENT_CODE]: parentStatementData.statementXmlCode,
        [DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.ORDER_CODE]: parentStatementData.orderXmlCode,
      });
    }
  }, [form, parentStatementData]);

  const isLoading = isParentStatementDataLoading || isParentStatementDataFetching;

  const organization = Form.useWatch(DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ORGANIZATION, form);
  const currentReddirectType = organization
    ? DECLARATIONS_TYPE_TAB_ENUM.LOCAL
    : DECLARATIONS_TYPE_TAB_ENUM.COMMON;
  const createRoute = `${SETTINGS_DECLARATIONS_ROUTE}/${currentReddirectType}/edo`;

  return {
    parentStatementData,
    isLoading,
    createRoute,
  };
};

export default useCommonData;
