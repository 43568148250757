import { Dayjs } from 'dayjs';

import { FormFileType } from 'containers/file-field/fileField';

export const NEWS_FORM_NAME = 'NEWS_FORM_NAME';
export const MAX_CATEGORIES_PER_PAGE = 500;

export enum NEWS_FORM_ENUM {
  TITLE = 'title',
  TEXT = 'text',
  COVER = 'cover',
  CATEGORIES = 'categories',
  PUBLISH = 'PUBLISH',
  PUBLISH_FROM_DATE = 'PUBLISH_FROM_DATE',
  PUBLISH_FROM_TIME = 'PUBLISH_FROM_TIME',
  NEW_CATEGORY_NAME = 'NEW_CATEGORY_NAME',
}

export interface NewsFormResponse {
  [NEWS_FORM_ENUM.TITLE]: string;
  [NEWS_FORM_ENUM.TEXT]: string;
  [NEWS_FORM_ENUM.COVER]?: FormFileType;
  [NEWS_FORM_ENUM.CATEGORIES]: string[];
  [NEWS_FORM_ENUM.PUBLISH]: boolean;
  [NEWS_FORM_ENUM.PUBLISH_FROM_DATE]?: Dayjs;
  [NEWS_FORM_ENUM.PUBLISH_FROM_TIME]?: Dayjs;
}
