import { t } from 'tools/i18n';
import { compareErrorStatuses } from 'utils/error.utils';

import { FormInstance } from 'gazprom-ui-lib';

const DUPLICATE_NAME_ERROR_STATUS = 409;

interface Props {
  error: unknown;
  form: FormInstance;
}

export enum COMMUNITY_CATEGORY_FORM_ENUM {
  NAME = 'name',
}

export const handleDuplicateNameError = (props: Props) => {
  const { error, form } = props;

  if (compareErrorStatuses({ error, targetStatus: DUPLICATE_NAME_ERROR_STATUS })) {
    form.setFields([
      {
        name: COMMUNITY_CATEGORY_FORM_ENUM.NAME,
        errors: [t('errors_category_name_exist')],
      },
    ]);
  }
};
