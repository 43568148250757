import { FC, memo } from 'react';

import cn from 'classnames';
import downloadHandler from 'utils/downloadHandler';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './ImgPreview.module.scss';

export interface ImgPreviewProps {
  downloadWithAuth?: boolean;
  url: string | TIconsLiteral;
  resetImage?: () => void;
  className?: string;
  type?: 'icon' | 'image';
  iconName?: TIconsLiteral;
  title?: string;
  description?: string;
  showDescription?: string;
  showDownload?: boolean;
  showDelete?: boolean;
}

const ImgPreview: FC<ImgPreviewProps> = (props) => {
  const {
    downloadWithAuth,
    showDownload,
    showDelete = true,
    type = 'icon',
    url,
    resetImage,
    className,
    title,
    description,
    iconName,
    showDescription = true,
  } = props;
  const isImage = type === 'image';

  //TODO temporary FIX
  const mockedIconName = 'image';
  const handleDownloadFile = () => {
    if (title) {
      if (downloadWithAuth && url) {
        downloadHandler({
          url: url as string,
          fileName: title,
        });
      } else {
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = title;
        link.href = url?.toString();
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const downloadButton = showDownload ? (
    <button type="button" onClick={handleDownloadFile}>
      <Icon name="download" />
    </button>
  ) : (
    <></>
  );

  if (isImage) {
    return (
      <div className={cn(s.wrapper, className)}>
        <Icon name={mockedIconName as TIconsLiteral} />
        {/* TODO temporary FIX */}
        {/*<img src={url} alt="cover preview" />*/}
        <div>
          <Typography.Text size="14" weight="500" type="primary">
            {title ?? url?.toString().split('/').pop()}
          </Typography.Text>
          <Typography.Text size="12" weight="500" type="secondary">
            {description ?? url}
          </Typography.Text>
        </div>
        <div className={s.actions}>
          {downloadButton}
          {showDelete && (
            <button type="button" onClick={resetImage}>
              <Icon name="bin" />
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={cn(s.wrapper, s.icon, className)}>
      <div>
        <Icon name={iconName ?? (mockedIconName as TIconsLiteral)} />
        {/*<Icon name={iconName as TIconsLiteral} />*/}
      </div>
      <div>
        <Typography.Text size="14" weight="500" type="primary">
          {title}
        </Typography.Text>
        {showDescription && (
          <Typography.Text size="12" weight="500" type="secondary">
            {description}
          </Typography.Text>
        )}
      </div>
      <div className={s.actions}>
        {downloadButton}
        {showDelete && (
          <button type="button" onClick={resetImage}>
            <Icon name="bin" />
          </button>
        )}
      </div>
    </div>
  );
};

export default memo(ImgPreview);
