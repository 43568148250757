export const SURVEY_SETTLEMENT_FORM_NAME = 'SURVEY_SETTLEMENT_FORM_NAME';

export enum SURVEY_SETTLEMENT_FORM_ENUM {
  ANKETOLOG_ID = 'anketologId',
  NOTIFICATION_INTERVAL = 'notificationInterval',
  SETTLEMENTS = 'settlementIds',
}

export interface SurveySettlementFormResponse {
  [SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID]: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.NOTIFICATION_INTERVAL]?: string;
  [SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS]?: string[];
}
