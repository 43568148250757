import { useParams } from 'react-router-dom';
import useFullSelectedEmployee from 'utils/useFullSelectedEmployee.hook';
import useRoleModelContent from 'utils/useRoleModelContent.hook';

import { Form } from 'gazprom-ui-lib';

import DocumentDeclarationFormCodes from 'containers/documents-declarations-form/document-declaration-form-codes';
import DocumentsDeclarationsFormCategory from 'containers/documents-declarations-form/documents-declarations-form-category';
import DocumentsDeclarationsFormCheck from 'containers/documents-declarations-form/documents-declarations-form-check';
import DocumentsDeclarationsFormDescription from 'containers/documents-declarations-form/documents-declarations-form-description';
import DocumentsDeclarationsFormExecutor from 'containers/documents-declarations-form/documents-declarations-form-executor';
import DocumentsDeclarationsFormName from 'containers/documents-declarations-form/documents-declarations-form-name';
import DocumentsDeclarationsFormOrganization from 'containers/documents-declarations-form/documents-declarations-form-organization';
import { DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from 'pages/documents/documents-declarations/documents-declarations-buttons';
import useCommonFormSubmitHandler from 'pages/documents/documents-declarations/useCommonFormSubmitHandler.hook';
import useDeclarationRoleModel from 'pages/documents/documents-declarations/useDeclarationRoleModel';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';
import { GLOBAL_ROLES } from 'types/roles.types';

import LinkedStatements from '../linked-statements';
import s from './TabCommon.module.scss';
import { DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME } from './tabCommon.utils';
import useCommonData from './useCommonData';

interface Props {
  isOrgRequired?: boolean;
}

const TabCommon = (props: Props) => {
  const { isOrgRequired } = props;

  const [form] = Form.useForm();

  const [selectedEmployee] = useFullSelectedEmployee();
  const { id } = useParams<{ id: string }>();
  const { isLoading, statementData, createRoute } = useCommonData({ form });

  const statementType = id ? statementData?.type : KEDO_TYPE_ENUM.BPMN;
  const { isLoading: mutationIsLoading, handleFormSubmit } = useCommonFormSubmitHandler({
    createRoute: createRoute,
    type: statementType,
  });

  const isEditMode = !!id;

  const isKedoADmin = useRoleModelContent({
    content: true,
    fallbackValue: false,
    roles: [GLOBAL_ROLES.KEDO_ADMIN],
  });
  const defaultOrgValue = isKedoADmin ? selectedEmployee?.employer.id : undefined;

  const { commonDisabled } = useDeclarationRoleModel({});

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_EDO_COMMON_FORM_NAME}>
        <div className={s.container}>
          {!isEditMode && (
            <DocumentsDeclarationsFormOrganization
              required={typeof isOrgRequired === 'boolean' ? isOrgRequired : true}
              defaultValue={defaultOrgValue}
              disabled={commonDisabled}
            />
          )}
          <DocumentsDeclarationsFormName disabled={commonDisabled} />
          <DocumentsDeclarationsFormCategory type={statementData?.type} disabled={commonDisabled} />
          <DocumentsDeclarationsFormDescription disabled={commonDisabled} />
          <DocumentsDeclarationsFormExecutor disabled={commonDisabled} />
          <DocumentDeclarationFormCodes required disabled={commonDisabled} />
          <DocumentsDeclarationsFormCheck
            defaultValue={false}
            className={s.hidden}
            formName={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.ACCESS}
            label=""
          />
          <Form.Item name={DOCUMENTS_SOCIAL_DECLARATIONS_FORM_ENUM.DEFAULT_STATEMENT_ID} noStyle />
        </div>
        <LinkedStatements />
        <DocumentsDeclarationsButtons isLoading={mutationIsLoading} />
      </Form>
    </WithLoader>
  );
};

export default TabCommon;
