import { Button, ButtonProps, ModalDefault, ModalDefaultIconProps } from 'gazprom-ui-lib';

interface CommonModalProps {
  config: {
    title?: string;
    description?: string;
    icon?: ModalDefaultIconProps;
    button?: ButtonProps;
  };
  isVisible: boolean;
  handleClose: () => void;
  isLoading: boolean;
}

const CommonModal = (props: CommonModalProps) => {
  const {
    config: { title, description, icon, button },
    isVisible,
    handleClose,
    isLoading,
  } = props;

  return (
    <ModalDefault open={isVisible} onCancel={handleClose} width={320} closable>
      {icon && <ModalDefault.Icon {...icon} />}
      <ModalDefault.Title>{title}</ModalDefault.Title>
      <ModalDefault.Description>{description}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button loading={isLoading} {...button} />
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default CommonModal;
