import { ReactNode } from 'react';

import { t } from 'tools/i18n';

import { Flex, Form } from 'gazprom-ui-lib';

import Cover from 'containers/event-form/event-form-main/containers/cover';
import DateAndTime from 'containers/event-form/event-form-main/containers/date-and-time';
import Description from 'containers/event-form/event-form-main/containers/description';
import Docs from 'containers/event-form/event-form-main/containers/docs';
import Place from 'containers/event-form/event-form-main/containers/place';
import Title from 'containers/event-form/event-form-main/containers/title';

import CommunityArticleCategories from '../community-article-categories';
import s from './CommunityMeetForm.module.scss';
import { COMMUNITY_MEETS_FORM_ENUM } from './communityMeetForm.utils';
import MeetsFormActions from './containers/actions';
import IsPublish from './containers/is-publish';
import Participants from './containers/participants';
import Registration from './containers/registration';
import RepeatInterval from './containers/repeat-interval';
import SpecialDate from './containers/special-date';
import WeekDays from './containers/week-days';

interface Props {
  actions?: ReactNode;
  isCreatingOrUpdating?: boolean;
}

const CommunityMeetForm = (props: Props) => {
  const { actions, isCreatingOrUpdating } = props;

  // Рендерим кастомные actions из useSuggestedFormActions когда sourceType: MOBILE (предложка)
  const renderActions = () => {
    if (actions) {
      return actions;
    } else if (typeof isCreatingOrUpdating === 'boolean') {
      return <MeetsFormActions isCreatingOrUpdating={isCreatingOrUpdating} />;
    }
  };

  return (
    <div className={s.wrapper}>
      <Title maxLength={120} />
      <Description />
      <Cover />
      <CommunityArticleCategories formFieldKey={COMMUNITY_MEETS_FORM_ENUM.CATEGORIES} />
      <DateAndTime />
      <RepeatInterval />
      <WeekDays />
      <Place
        cityLabel={t('events_location')}
        cityPlaceholder={t('community_meets_city_placeholder')}
        locationLabel={t('common_address')}
      />
      <Flex vertical gap="8" className={s.noFormItemMargin}>
        <Participants />
        <Registration />
        <Docs />
        <SpecialDate />
        <IsPublish />
      </Flex>
      {/* Поле для того, чтобы сеттить поле status, когда работаем с suggested */}
      <Form.Item name={COMMUNITY_MEETS_FORM_ENUM.STATUS} hidden />
      {renderActions()}
    </div>
  );
};

export default CommunityMeetForm;
