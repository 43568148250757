import { t } from 'tools/i18n';

import FileField from 'containers/file-field';

import { NEWS_FORM_ENUM } from '../newsForm.utils';

const NewsFormCover = () => {
  return (
    <FileField
      formItemConfig={{
        label: t('news_cover'),
        name: NEWS_FORM_ENUM.COVER,
      }}
      previewConfig={{
        showDelete: true,
        showDownload: true,
      }}
      uploadConfig={{
        description: t('validation_upload_an_image_restrictions'),
      }}
    />
  );
};

export default NewsFormCover;
