import { memo, useMemo } from 'react';

import { t } from 'i18next';
import { formRules } from 'utils/formRules';

import { useGetKedoDocTypesQuery } from 'services/public/publicApiService';

import { Form, Select } from 'gazprom-ui-lib';

import { DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import { OptionsType } from './documentDeclarationFormCodes.utils';

interface Props {
  disabled?: boolean;
  required?: boolean;
}

const DocumentDeclarationFormCodes = (props: Props) => {
  const { disabled, required } = props;

  const { data, isFetching } = useGetKedoDocTypesQuery();

  const { statementOptions, orderOptions } = useMemo<OptionsType>(() => {
    if (data) {
      return data.reduce(
        (prev, curr) => {
          prev.statementOptions?.push({
            value: curr.docType,
            label: `${curr.docType} ${curr.name}`,
          });
          prev.orderOptions?.push({
            value: curr.docType,
            label: `${curr.docType} ${curr.name}`,
          });

          return prev;
        },
        {
          statementOptions: [],
          orderOptions: [],
        } as OptionsType,
      );
    }

    return {
      statementOptions: [],
      orderOptions: [],
    } as OptionsType;
  }, [data]);

  return (
    <>
      <Form.Item
        name={DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.STATEMENT_CODE}
        rules={required ? [formRules.required] : undefined}
        label={t('documents_declaration_statement_code')}>
        <Select
          showSearch
          options={statementOptions}
          disabled={disabled}
          loading={isFetching}
          placeholder={t('common_select_placeholder')}
        />
      </Form.Item>
      <Form.Item
        name={DOCUMENTS_EDO_DECLARATIONS_FORM_ENUM.ORDER_CODE}
        rules={required ? [formRules.required] : undefined}
        label={t('documents_declaration_order_code')}>
        <Select
          showSearch
          options={orderOptions}
          disabled={disabled}
          loading={isFetching}
          placeholder={t('common_select_placeholder')}
        />
      </Form.Item>
    </>
  );
};

export default memo(DocumentDeclarationFormCodes);
