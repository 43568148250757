import { Avatar, Typography } from 'gazprom-ui-lib';

import s from './TablePerson.module.scss';

interface Props {
  firstName: string;
  lastName?: string | null;
  department?: string | null;
  position?: string | null;
}

const TablePerson = (props: Props) => {
  const { firstName, lastName, department, position } = props;
  const name = lastName ? `${lastName}\u00A0${firstName}` : firstName;

  return (
    <div className={s.container}>
      <Avatar
        firstName={firstName}
        lastName={lastName ?? ''}
        alt="аватар пользователя"
        size="40"
        className={s.avatar}
      />
      <div className={s.info}>
        {department && (
          <Typography.Text className={s.department} size="10" type="secondary">
            {department}
          </Typography.Text>
        )}
        <Typography.Text size="14" weight="500" className={s.name}>
          {name}
        </Typography.Text>
        {position && (
          <Typography.Text size="10" type="secondary" className={s.position}>
            {position}
          </Typography.Text>
        )}
      </div>
    </div>
  );
};

export default TablePerson;
