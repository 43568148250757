import { getFiltersCount } from 'utils/filter.utils';

import { Form } from 'gazprom-ui-lib';

interface Props {
  filtersCountFromProps?: number | null;
}

const useFiltersCount = (props: Props) => {
  const { filtersCountFromProps } = props;

  const filters = Form.useWatch('filters');

  const filtersCount = getFiltersCount(filters);

  const isValidPropsCount =
    typeof filtersCountFromProps === 'number' && !isNaN(filtersCountFromProps);

  return isValidPropsCount ? filtersCountFromProps : filtersCount;
};

export default useFiltersCount;
