import { useEffect } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetSalaryProjectsQuery } from 'services/private/privateApiService';
import { GetSalaryProjectsPropsType } from 'services/private/privateApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { FormResponseType } from './salaryProject.utils';

interface UseGetDataProp {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: UseGetDataProp) => {
  const { form } = props;

  const [getSalaryProjects, { data, isLoading, isFetching, isUninitialized }] =
    useLazyGetSalaryProjectsQuery();

  const requestBody: FormResponseType = Form.useWatch([], form);

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  useEffect(() => {
    if (debouncedRequestBody) {
      const initialRequestBody: GetSalaryProjectsPropsType = {};

      if (debouncedRequestBody.property && debouncedRequestBody.order) {
        initialRequestBody.sort = [
          { order: debouncedRequestBody.order, property: debouncedRequestBody.property },
        ];
      }

      if (debouncedRequestBody.search) {
        initialRequestBody.search = debouncedRequestBody.search;
      }

      if (debouncedRequestBody.filters?.employerIds?.length) {
        initialRequestBody.orgIds = debouncedRequestBody.filters?.employerIds;
      }

      getSalaryProjects(initialRequestBody);
    }
  }, [debouncedRequestBody, getSalaryProjects]);

  return {
    data,
    isLoading: isUninitialized || isLoading,
    isFetching: isDebouncing || isFetching,
  };
};

export default useGetData;
