interface Props {
  options: { value: string; label: string }[];
}

export const removeDuplicateOptions = (props: Props) => {
  const { options } = props;

  const seen = new Set();
  return options.filter((option) => !seen.has(option.value) && seen.add(option.value));
};
