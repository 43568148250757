import { useNavigate } from 'react-router-dom';
import { getSurveySettlementByIdRoute } from 'routes/surveys/list';

import { Empty } from 'components/empty';
import FiltersV2 from 'containers/filters-v2';

import { SurveySettlementType } from 'types/surveys.types';

import { FormResponseType } from '../../surveysSettlement.utils';
import { useColumns } from './useColumns.hook';

interface Props {
  dataSource: SurveySettlementType[];
  loading: boolean;
  total: number;
  isInitialLoading: boolean;
  hasFiltersApplied: boolean;
}

const Table = (props: Props) => {
  const columns = useColumns();
  const navigate = useNavigate();

  const onRow = ({ id }: SurveySettlementType) => ({
    onClick: () => navigate(getSurveySettlementByIdRoute(id)),
  });

  const locale = {
    emptyText: () => (
      <Empty
        title={`${props.hasFiltersApplied ? 'surveys_empty_filters_title' : 'surveys_empty_title'}`}
        description={`${
          props.hasFiltersApplied ? 'common_empty_list_with_filters_description' : ''
        }`}
      />
    ),
  };

  return (
    <FiltersV2.Table<SurveySettlementType, FormResponseType>
      rowKey="id"
      onRow={onRow}
      columns={columns}
      locale={locale}
      {...props}
    />
  );
};

export default Table;
