import { useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployees1cQuery, useGetEmployer1cQuery } from 'services/1c/cApiService';

import { prepareEmployees } from './kedoReviewTaskForm.utils';

const DEFAULT_PAGINATION = { page: 1, size: 500 };

type Props = {
  employerId: string;
};

export const useKedoReviewTaskFormData = ({ employerId }: Props) => {
  const [employeeSearch, setEmployeeSearch] = useState('');
  const { debouncedValue: debouncedEmployeeSearch } = useDebounce(employeeSearch);

  const { data: { employees = [] } = {}, isFetching: isFetchingEmployees } = useGetEmployees1cQuery(
    {
      ...DEFAULT_PAGINATION,
      fio: debouncedEmployeeSearch,
      employerIds: [employerId],
    },
    { skip: !employerId },
  );

  const preparedEmployees = prepareEmployees({ employees });

  const { data: { employers = [] } = {}, isFetching: isFetchingEmployers } = useGetEmployer1cQuery({
    ...DEFAULT_PAGINATION,
  });

  const preparedEmployers = employers.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

  return {
    preparedEmployees,
    setEmployeeSearch,
    employeeSearch,
    isFetchingEmployees,
    preparedEmployers,
    isFetchingEmployers,
  };
};
