import { useNavigate, useParams } from 'react-router-dom';
import { getCommunityManagementMeetsEditRoute } from 'routes/communities/list';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CommunityMeetType } from 'types/communityMeet.types';

import s from './MeetsTab.module.scss';
import Filters from './containers/filters';
import { DEFAULT_FORM_STATE, FormResponseType } from './meetsTab.utils';
import { useColumns } from './useColumns.hook';
import { useRequest } from './useRequest.hook';

const MeetsTab = () => {
  const { id: communityId } = useParams() as { id: string };
  const [form] = Form.useForm<FormResponseType>();

  const navigate = useNavigate();

  const requestBody: FormResponseType = Form.useWatch([], form);
  const requestData = useRequest({ communityId, requestBody });
  const columns = useColumns();

  const onRow = ({ communityId, id: meetingId }: CommunityMeetType) => ({
    onClick: () => navigate(getCommunityManagementMeetsEditRoute(communityId, meetingId)),
  });

  return (
    <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
      <Filters />
      <FiltersV2.Table<CommunityMeetType, FormResponseType>
        {...requestData}
        onRow={onRow}
        columns={columns}
        className={s.table}
      />
    </FiltersV2>
  );
};

export default MeetsTab;
