import { useEffect } from 'react';

import { t } from 'tools/i18n';

import { Button, Collapse, Form } from 'gazprom-ui-lib';

import RemoveConfirm from 'components/removeConfirm';
import WithLoader from 'containers/wrappers/with-loader';

import DocumentsDeclarationsButtons from '../documents-declarations-buttons';
import s from './DocumentsDeclarationsAdditional.module.scss';
import { DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME } from './documentsDeclarationsAdditional.utils';
import useAdditionalFormSubmitHandler from './useAdditionalFormSubmitHandler.hook';
import useSectionHandler from './useSectionHandler.hook';

interface Props {
  isDisabled?: boolean;
}

const DocumentsDeclarationsAdditional = (props: Props) => {
  const { isDisabled } = props;
  const [form] = Form.useForm();

  const { isLoading: mutationIsLoading, handleFormSubmit } = useAdditionalFormSubmitHandler();

  const {
    defaultOpenedId,
    isLoading,
    isModalOpen,
    sections,
    addSectionHandler,
    closeModalHandler,
  } = useSectionHandler();

  const showCollapse = !!sections.length;

  useEffect(() => {
    form.setFieldValue('isDisabled', isDisabled);
  }, [form, isDisabled]);

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        id={DOCUMENTS_DECLARATIONS_ADDITIONAL_FORM_NAME}>
        <div className={s.container}>
          {showCollapse && (
            <Collapse noPadding items={sections} defaultActiveKey={defaultOpenedId} />
          )}
          <Button type="link" leftIcon="plus" onClick={addSectionHandler} disabled={isDisabled}>
            {t('common_add_field')}
          </Button>
        </div>
        <DocumentsDeclarationsButtons isLoading={mutationIsLoading} />
        <Form.Item name="isDisabled" hidden />
      </Form>
      <RemoveConfirm
        type="success"
        isOpen={isModalOpen}
        onCancel={closeModalHandler}
        title={t('common_successfully_removed')}
        onConfirm={closeModalHandler}
        buttonText={t('common_ok')}
        icon="ok"
      />
    </WithLoader>
  );
};

export default DocumentsDeclarationsAdditional;
