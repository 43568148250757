import { useEffect } from 'react';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_MEETS_FORM_ENUM } from 'containers/community-meet-form/communityMeetForm.utils';
import { EVENT_FORM_ENUM, FORM_LABELS } from 'containers/event-form/eventForm.utils';

const Registration = () => {
  const form = Form.useFormInstance();
  const hasRepeatEvery = !!Form.useWatch(COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL, form);

  useEffect(() => {
    if (hasRepeatEvery) {
      form.setFieldValue(EVENT_FORM_ENUM.REGISTRATION_AVAILABLE, true);
    }
  }, [hasRepeatEvery, form]);

  return (
    <Form.Item name={EVENT_FORM_ENUM.REGISTRATION_AVAILABLE} valuePropName="checked">
      <Checkbox disabled={hasRepeatEvery}>
        <Typography.Text size="14">
          {FORM_LABELS[EVENT_FORM_ENUM.REGISTRATION_AVAILABLE]}
        </Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default Registration;
