import { t } from 'tools/i18n';
import { getFiltersCount } from 'utils/filter.utils';

import { Flex, Form } from 'gazprom-ui-lib';

import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';
import { FiltersResponseType } from 'containers/filters/filters.utils';

import { FormFiltersType } from '../../communityManagement.utils';
import { COMMUNITIES_FILTERS_TYPE_OPTIONS } from './filters.utils';
import { useFilterItems } from './useFilterItems.hook';

const Filters = () => {
  const filters = Form.useWatch('filters');

  const filterItems = useFilterItems();

  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
      </Flex>
      <FiltersContainer<FormFiltersType>
        showClearAll
        initialValues={filters as FiltersResponseType | null}
        overlay={
          <>
            <FilterCollapse items={filterItems} />

            <FilterCustomWrapper>
              <FilterHeader valueKey="isPublish">{t('common_type')}</FilterHeader>
              <FilterCheckboxGroup
                usePadding={false}
                valueKey="isPublish"
                options={COMMUNITIES_FILTERS_TYPE_OPTIONS}
              />
            </FilterCustomWrapper>
          </>
        }
        filtersCount={getFiltersCount(filters)}
      />
    </FiltersV2.TableFilters>
  );
};

export default Filters;
