import { useDebounce } from 'utils/useDebounce.hook';

import { useGetSurveySettlementsQuery } from 'services/surveys/surveysApiService';
import { GetSurveySettlementsPropsType } from 'services/surveys/surveysApiService.types';

import { FormResponseType } from './surveysSettlement.utils';

interface Props {
  requestBody: FormResponseType;
}

export const useRequest = (props: Props) => {
  const { requestBody } = props;

  const { debouncedValue: debouncedRequestBody, isDebouncing } = useDebounce(requestBody);

  const { search, property, order, filters, ...clearedRequestBody } = debouncedRequestBody ?? {};

  const actualRequestBody: GetSurveySettlementsPropsType = {
    ...clearedRequestBody,
    ...filters,
    ...(search && { name: search }),
    ...(property && order && { sort: [{ order, property }] }),
  };

  const {
    data: { items: dataSource = [], totalCount: total = 0 } = {},
    isLoading,
    isFetching,
    isUninitialized,
  } = useGetSurveySettlementsQuery(actualRequestBody, { skip: !actualRequestBody?.page });

  const isInitialLoading = isLoading || isUninitialized;
  const loading = isFetching || isDebouncing;
  const hasFiltersApplied = !!actualRequestBody?.name?.trim();

  return { isInitialLoading, loading, dataSource, total, hasFiltersApplied };
};
