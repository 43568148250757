import { FileType } from 'types/file.types';

export const sortDocumentsFiles = (a: FileType, b: FileType) => {
  if (a?.type === 'ORDER' && b?.type !== 'ORDER') return -1;
  if (b?.type === 'ORDER' && a?.type !== 'ORDER') return 1;

  if (a?.type !== 'ORDER' && a?.type !== 'PETITION' && b?.type === 'ORDER') return 1;
  if (b?.type !== 'ORDER' && b?.type !== 'PETITION' && a?.type === 'ORDER') return -1;

  if (a?.type === null && b?.type !== null) return 1;
  if (b?.type === null && a?.type !== null) return -1;

  if (a?.type === 'PETITION' && b?.type !== 'PETITION') return 1;
  if (b?.type === 'PETITION' && a?.type !== 'PETITION') return -1;

  return 0;
};

export const getOrderFile = (files?: FileType[]) => {
  if (!files) {
    return null;
  }

  return files.find((file) => file?.type === 'ORDER');
};
