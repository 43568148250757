import { useMemo, useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { useGetEmployersListQuery } from 'services/employer/employerApiService';
import { GetEmployersResponse } from 'services/employer/employerApiService.types';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import { FilterCheckboxGroupOptions } from 'containers/filters/filter-checkbox-group/filterCheckboxGroup';
import FilterHeader from 'containers/filters/filter-header';

import { MAX_EMPLOYERS_PER_PAGE } from 'pages/thank-you/thank-you.utils';

const useFilterItems = () => {
  const [employer, setEmployer] = useState<string>();

  const { debouncedValue: debouncedEmployer } = useDebounce(employer);
  const { data: employersOptions } = useGetEmployersListQuery(
    { page: 1, size: MAX_EMPLOYERS_PER_PAGE, name: debouncedEmployer },
    { selectFromResult: selectFromResultEmployers },
  );

  return useMemo(
    () => [
      {
        open: true,
        key: 'employerIds',
        label: <FilterHeader valueKey="employerIds">{t('common_employers')}</FilterHeader>,
        children: (
          <FilterCheckboxGroup
            showSearch
            optionFilterProp="label"
            valueKey="employerIds"
            searchProps={{
              placeholder: t('common_employers'),
              value: employer,
              onChange: (e) => setEmployer(e.target.value || undefined),
            }}
            options={employersOptions}
          />
        ),
      },
    ],
    [employer, employersOptions],
  );
};

export const selectFromResultEmployers = ({
  data,
}: {
  data?: GetEmployersResponse;
}): { data: FilterCheckboxGroupOptions[] } => ({
  data: data?.content.map(({ name, id }) => ({ value: id, label: name })) ?? [],
});

export default useFilterItems;
