import { useVisibility } from 'utils/useVisibility.hook';

import { Form } from 'gazprom-ui-lib';

import MassSubscribeInviteForm from 'containers/mass-subscribe-invite-form';
import {
  MASS_SUBSCRIBE_INVITE_FORM_ENUM,
  MassSubscribeInviteFormResponse,
} from 'containers/mass-subscribe-invite-form/massSubscribeInviteForm.utils';

import ConfirmModal from 'pages/communities/community-management/community-management-subscribers/community-magement-subscribers-add-invite/containers/confirm-modal';

import { CommunityManagementSubscribersEnumType } from '../containers/tabs/tabs.utils';
import Header from './containers/header';
import { useHandleFinish } from './useHandleFinish.hook';

type CommunityManagementSubscribersAddInviteProps = {
  pageType: CommunityManagementSubscribersEnumType;
};

const CommunityManagementSubscribersAddInvite = ({
  pageType,
}: CommunityManagementSubscribersAddInviteProps) => {
  const [form] = Form.useForm<MassSubscribeInviteFormResponse>();
  const [confirmModalIsOpened, { close, open }] = useVisibility();
  const isMassSubscribePage = pageType === 'SUBSCRIBERS';
  const disableSubmit = !Form.useWatch(MASS_SUBSCRIBE_INVITE_FORM_ENUM.TREE_IDS, form)?.length;

  const { handleFinish, isSubscribingOrInviting } = useHandleFinish({
    isMassSubscribePage,
    closeConfirmModal: close,
    form,
  });

  return (
    <>
      <Header
        isLoading={isSubscribingOrInviting}
        disableSubmit={disableSubmit}
        openConfirmModal={open}
        isMassSubscribePage={isMassSubscribePage}
      />
      <MassSubscribeInviteForm
        isLoading={isSubscribingOrInviting}
        openConfirmModal={open}
        form={form}
        disableSubmit={disableSubmit}
        isMassSubscribePage={isMassSubscribePage}
        handleFinish={handleFinish}
      />
      <ConfirmModal
        isSubscribingOrInviting={isSubscribingOrInviting}
        close={close}
        isMassSubscribePage={isMassSubscribePage}
        open={confirmModalIsOpened}
      />
    </>
  );
};

export default CommunityManagementSubscribersAddInvite;
