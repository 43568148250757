import { useNavigate } from 'react-router-dom';
import { GUIDE_HANDBOOK_ROUTE, getHandbookWithIdRoute } from 'routes/guide/list';
import { t } from 'tools/i18n';

import { useCreateHandbookMutation } from 'services/settlement/settlementApiService';
import { CreateHandbookPropsType } from 'services/settlement/settlementApiService.types';

import { Button, Typography, handleRequest } from 'gazprom-ui-lib';

import HandbookBaseForm from 'containers/handbook-base-form';
import {
  HANDBOOK_BASE_FORM_ENUM,
  HANDBOOK_BASE_FORM_NAME,
  HandbookBaseFormResponse,
} from 'containers/handbook-base-form/handbookBaseForm.utils';

import { HandbookType } from 'types/guide.types';

import s from './GuideHandbookCreate.module.scss';

const GuideHandbookCreate = () => {
  const navigate = useNavigate();

  const [createHandbook, { isLoading }] = useCreateHandbookMutation();

  const handleGoBack = () => navigate(GUIDE_HANDBOOK_ROUTE);

  const onSuccess = (data?: HandbookType) => {
    if (data)
      navigate(getHandbookWithIdRoute(data.id), {
        state: { newHandbook: true },
      });
  };

  const handleCreate = (form: HandbookBaseFormResponse) => {
    const [x, y] = form[HANDBOOK_BASE_FORM_ENUM.COORDINATES].split(',');

    const requestBody: CreateHandbookPropsType = {
      fileMap: form[HANDBOOK_BASE_FORM_ENUM.MAP],
      fileInstruction: form[HANDBOOK_BASE_FORM_ENUM.MEMO],
      name: form[HANDBOOK_BASE_FORM_ENUM.NAME],
      isPublish: !!form[HANDBOOK_BASE_FORM_ENUM.PUBLISH],
      telegramId: form[HANDBOOK_BASE_FORM_ENUM.TG_CHANEL],
      coordinates: {
        x,
        y,
      },
      regionId: form[HANDBOOK_BASE_FORM_ENUM.REGION],
    };

    createHandbook(requestBody).then(
      handleRequest({
        onSuccess,
      }),
    );
  };

  return (
    <>
      <div className={s.actionsHeader}>
        <div>
          <Button htmlType="button" type="link" leftIcon="arrowLeft" onClick={handleGoBack}>
            {t('common_go_back')}
          </Button>
          <Typography.Title level={3}>{t('guide_handbook_create')}</Typography.Title>
        </div>
        <Button leftIcon="ok" htmlType="submit" loading={isLoading} form={HANDBOOK_BASE_FORM_NAME}>
          {t('common_save')}
        </Button>
      </div>
      <div className={s.content}>
        <HandbookBaseForm onSubmit={handleCreate} />
        <div className={s.actions}>
          <Button
            fullWidth
            type="primary"
            leftIcon="ok"
            htmlType="submit"
            loading={isLoading}
            form={HANDBOOK_BASE_FORM_NAME}>
            {t('common_save')}
          </Button>

          <Button fullWidth type="secondary" onClick={handleGoBack}>
            {t('common_close')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default GuideHandbookCreate;
