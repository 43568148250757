import cInitialApiService from 'services/1c/cApiService';

import {
  UpdateEmployerSettingsPropsType,
  UpdateEmployerSettingsResponseType,
} from './employerSettingsApiService.types';
import { C_SERVICE_TAGS } from 'constants/serviceTags';

import { createEmployerSettingsUrl } from './utils';

const employerSettingsApiService = cInitialApiService.injectEndpoints({
  endpoints: (builder) => ({
    updateEmployerSettings: builder.mutation<
      UpdateEmployerSettingsResponseType,
      UpdateEmployerSettingsPropsType
    >({
      query: ({ id, ...body }) => ({
        url: createEmployerSettingsUrl(`/${id}/update`),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [C_SERVICE_TAGS.GET_EMPLOYERS],
    }),
  }),
});

export const { useUpdateEmployerSettingsMutation } = employerSettingsApiService;
