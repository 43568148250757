import { t } from 'tools/i18n';

import { ColumnsType } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';
import TableText from 'components/table/table-text';

import { SocialProgramType } from 'types/socialProgram.types';

import s from './Table.module.scss';

const textProps = { size: '12', weight: '500' } as const;

const useColumns = (): ColumnsType<SocialProgramType> => {
  const nameRenderer = (text: string) => {
    return (
      <TableText size="14" weight="500" className={s.maxWidth}>
        {text}
      </TableText>
    );
  };

  const categoryRenderer = (props: { nameCategory: string }) => {
    const { nameCategory } = props;
    return <TableText {...textProps}>{nameCategory}</TableText>;
  };

  const organizationRenderer = (org: { id: string; name: string }) => {
    const { name } = org;

    return (
      <TableText {...textProps} type="secondary">
        {name || t('social_program_all_organizations')}
      </TableText>
    );
  };
  const isPublishRenderer = (value: boolean) => <TableIsPublish isPublish={value} />;
  const updatedAtRenderer = (date: string) => <TableDate date={date} />;

  return [
    {
      title: t('common_program_name'),
      dataIndex: 'nameProgram',
      key: 'nameProgram',
      render: nameRenderer,
    },
    {
      title: t('social_program_category'),
      dataIndex: 'category',
      key: 'category',
      render: categoryRenderer,
    },
    {
      title: t('social_program_organization'),
      dataIndex: 'organization',
      key: 'organization',
      render: organizationRenderer,
    },
    {
      title: t('social_program_publication'),
      dataIndex: 'isPublish',
      key: 'isPublish',
      align: 'center',
      render: isPublishRenderer,
    },
    {
      title: t('social_program_updated'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: true,
      align: 'center',
      defaultSortOrder: 'descend',
      render: updatedAtRenderer,
    },
  ];
};

export default useColumns;
