import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Divider, Flex, Icon, Modal, Typography } from 'gazprom-ui-lib';

import Bio from 'components/bio';
import TableStatus from 'components/table/table-status';
import { STATUSES } from 'components/table/table-status/tableStatus.utils';

import { ExecutionType } from 'types/bypassSheet.types';

interface Props {
  data: ExecutionType | null;
  handleClose: () => void;
}

const ExecutionModal = (props: Props) => {
  const { data, handleClose } = props;
  return (
    <Modal open={!!data} closable onCancel={handleClose}>
      <Flex vertical gap="8">
        <Flex align="center" gap="8">
          <TableStatus status={(data?.status?.state === 'done' ? 'DONE' : 'PENDING') as STATUSES}>
            {data?.status?.name}
          </TableStatus>
          {data?.doneDate && (
            <Typography.Text type="secondary">
              {dayjs.utc(data?.doneDate).local().format('DD MMMM YYYY, HH:mm')}
            </Typography.Text>
          )}
        </Flex>
        <Divider />
        <Flex vertical gap="4">
          <Typography.Title level={5}>{data?.dutyTitle}</Typography.Title>
          {data && (
            <Bio
              employerName={data.dutyEmployee.employer.name}
              firstName={data.dutyEmployee.person.firstName}
              lastName={data.dutyEmployee.person.lastName}
              patronymic={data.dutyEmployee.person.patronymic}
              staffName={data.dutyEmployee.position.name}
            />
          )}
        </Flex>
        {data?.comment && (
          <>
            <Divider />
            <Flex vertical gap="4">
              <Flex align="center" justify="space-between">
                <Flex align="center" gap="8">
                  <Icon name="comment" color="var(--color-primary-6)" />
                  <Typography.Text size="14" weight="500">
                    {t('bypass_sheet_comment')}
                  </Typography.Text>
                </Flex>
                {data?.commentDate && (
                  <Typography.Text size="14" type="secondary">
                    {dayjs.utc(data?.commentDate).local().format('DD MMMM YYYY, HH:mm')}
                  </Typography.Text>
                )}
              </Flex>
              <Typography.Text size="14">{data?.comment}</Typography.Text>
            </Flex>
          </>
        )}
        {data?.message && (
          <>
            <Divider />
            <Flex vertical gap="4">
              <Flex align="center" justify="space-between">
                <Flex align="center" gap="8">
                  <Icon name="comment" color="var(--color-primary-6)" />
                  <Typography.Text size="14" weight="500">
                    {t('common_message')}
                  </Typography.Text>
                </Flex>
                {data?.messageDate && (
                  <Typography.Text size="14" type="secondary">
                    {dayjs.utc(data?.messageDate).local().format('DD MMMM YYYY, HH:mm')}
                  </Typography.Text>
                )}
              </Flex>
              <Typography.Text size="14">{data?.message}</Typography.Text>
            </Flex>
          </>
        )}
      </Flex>
    </Modal>
  );
};

export default ExecutionModal;
