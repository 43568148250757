import { Button, Flex, Typography } from 'gazprom-ui-lib';

import s from './InviteSubscribeUser.module.scss';

type RenderUserProps = {
  fio: string;
  position: string;
  employeeId: string;
  handleInvite: (employeeId: string) => void;
};

export const RenderOption = ({ fio, position, handleInvite, employeeId }: RenderUserProps) => {
  return (
    <Flex gap="8" justify="space-between" align="center">
      <Flex vertical className={s.userDetails}>
        <Typography.Text size="14" weight="500" className={s.truncate}>
          {fio}
        </Typography.Text>
        <Typography.Text type="secondary" className={s.truncate}>
          {position}
        </Typography.Text>
      </Flex>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          handleInvite(employeeId);
        }}
        type="link"
        onlyIcon
        leftIcon="plus"
      />
    </Flex>
  );
};

export const filterOptionHansdler = (input: string, option?: { searchValue: string }) => {
  if (!option?.searchValue) {
    return false;
  }

  return option.searchValue.toString().toLowerCase().includes(input.toLowerCase());
};
