import { Key, useState } from 'react';

import { useRemoveFeedbacksMutation } from 'services/thank-you/thankYouApiService';
import { GetFeedbacksResponseType } from 'services/thank-you/thankYouApiService.types';

import { Checkbox, Form, TableProps } from 'gazprom-ui-lib';

import DownloadProgressModal from 'components/download-progress-modal';
import TableSelectedActions from 'components/table/table-selected-actions';
import FiltersV2 from 'containers/filters-v2';

import { TMappedFeedBack, TMappedFeedBacks } from 'types/thankYou.types';

import ThankYouRowModal from '../../thank-you-row-modal';
import { FormResponseType, ModifiedFeedback } from '../../thank-you.utils';
import s from './ThankYouTable.module.scss';
import ModalDownloadError from './containers/modal-download-error';
import ModalRemove from './containers/modal-remove';
import ModalSuccess from './containers/modal-success';
import { MODALS, handleDownload } from './thankYouTable.utils';
import useActions from './useActions.hook';
import useColumns from './useColumns.hook';

interface Props {
  dataSource?: Omit<GetFeedbacksResponseType, 'feedbacks'> & { feedbacks: TMappedFeedBacks };
  loading: boolean;
  isInitialLoading: boolean;
}

const ThankYouTable = (props: Props) => {
  const { dataSource, loading, isInitialLoading } = props;

  const form = Form.useFormInstance();

  const [selectedRow, setSelectedRow] = useState<TMappedFeedBack | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [isDownloadingInProgress, setIsDownloadingInProgress] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<MODALS>(MODALS.EMPTY);

  const [removeFeedbacks] = useRemoveFeedbacksMutation();

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    const pageSize = form.getFieldValue('size');
    if (isSelectedAll && newSelectedRowKeys.length < pageSize) {
      setIsSelectedAll(false);
    }

    setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleRowClick = (record: TMappedFeedBack) => setSelectedRow(record);
  const handleCloseModal = () => setSelectedRow(null);
  const removeSelection = () => {
    setSelectedRowKeys([]);
    setIsSelectedAll(false);
  };
  const handleSelectAll = () => {
    if (dataSource) {
      setIsSelectedAll(!isSelectedAll);

      if (isSelectedAll) {
        setSelectedRowKeys([]);
      } else {
        setSelectedRowKeys(dataSource?.feedbacks.map((row) => row.key));
      }
    }
  };

  const hasSelected = selectedRowKeys.length > 0;
  const rowSelection: TableProps<TMappedFeedBack>['rowSelection'] = {
    selectedRowKeys,
    onChange: onSelectChange,
    columnTitle: <Checkbox checked={isSelectedAll} onChange={handleSelectAll} />,
  };

  const closeModal = () => setIsModalOpen(MODALS.EMPTY);
  const handleDownloadClick = () => {
    setIsDownloadingInProgress(true);
    const filters = form.getFieldValue('filters');
    const search = form.getFieldValue('search');

    handleDownload(filters, search, isSelectedAll, selectedRowKeys)
      .then(() => setIsModalOpen(MODALS.DOWNLOAD_SUCCESS))
      .catch((err) => {
        console.log(err);

        setIsModalOpen(MODALS.DOWNLOAD_ERROR);
      })
      .finally(() => setIsDownloadingInProgress(false));
  };

  const handleDownloadRetry = () => {
    handleDownloadClick();
    closeModal();
  };

  const removeHandler = () => {
    removeFeedbacks({ ids: selectedRowKeys });
    setSelectedRowKeys([]);
    closeModal();
  };

  const onRow = (record: TMappedFeedBack) => ({
    onClick: () => handleRowClick(record),
  });

  const columns = useColumns();
  const actions = useActions({ handleDownloadClick, removeSelection, setIsModalOpen });

  return (
    <>
      {hasSelected && <TableSelectedActions buttons={actions} />}
      <FiltersV2.Table<ModifiedFeedback, FormResponseType>
        columns={columns}
        isInitialLoading={isInitialLoading}
        dataSource={dataSource?.feedbacks}
        total={dataSource?.totalCount}
        className={s.table}
        onRow={onRow}
        rowSelection={rowSelection}
        loading={loading}
        rowKey="key"
        showSorterTooltip={false}
      />
      <DownloadProgressModal isOpen={isDownloadingInProgress} />
      {selectedRow && (
        <ThankYouRowModal
          isModalOpen={Boolean(selectedRow)}
          onCancel={handleCloseModal}
          data={selectedRow}
        />
      )}

      <ModalSuccess open={isModalOpen === MODALS.DOWNLOAD_SUCCESS} onCancel={closeModal} />
      <ModalRemove
        open={isModalOpen === MODALS.REMOVE}
        onCancel={closeModal}
        onRemove={removeHandler}
      />
      <ModalDownloadError
        onRetry={handleDownloadRetry}
        open={isModalOpen === MODALS.DOWNLOAD_ERROR}
        onCancel={closeModal}
      />
    </>
  );
};

export default ThankYouTable;
