import { SORT_ENUM } from 'types/sort.types';

export type FormFiltersType = {
  filledValues?: string[];
  employerIds?: string[];
};

export type FormResponseType = {
  page: number;
  size: number;
  property?: string;
  order: SORT_ENUM;
  search: string;
  filters: FormFiltersType;
};

export const DEFAULT_SORT = {
  property: 'createdAt',
  order: SORT_ENUM.DESC,
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  search: '',
  filters: {},
  ...DEFAULT_SORT,
};

export const createKedoArchiveXmlDocDownloadUrl = (fileKey: string) =>
  `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-files/download/${fileKey}`;
