export type CommunityArticleCategoryType = {
  id: string; // UUID
  name: string;
  creator: string; // UUID
  editor: string; // UUID
  createdAt: string; // Date-time
  updatedAt: string; // Date-time
  employeeId: string; // UUID
  employeeGroupId: string; // UUID
  countArticles: number; // Int32
  visible: boolean;
};

export type CommunityArticleCategoryCreateUpdateType = {
  name: string;
  employeeId: string; // UUID
  employeeGroupId: string; // UUID
  visible: boolean;
};

export enum COMMUNITY_ARTICLE_TYPES {
  NEWS = 'NEWS',
  MEETING = 'MEETING',
}
