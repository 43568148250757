import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { DEFAULT_FORM_STATE, FormResponseType } from './communityManagement.utils';
import Filters from './containers/filters';
import Header from './containers/header';
import Table from './containers/table';
import { useRequest } from './useRequest.hook';

const CommunityManagement = () => {
  const [form] = Form.useForm<FormResponseType>();
  const requestBody: FormResponseType = Form.useWatch([], form);
  const requestData = useRequest({ requestBody });
  return (
    <WithOverflow>
      <Header />
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />
        <Table {...requestData} />
      </FiltersV2>
    </WithOverflow>
  );
};

export default CommunityManagement;
