import { ReactNode } from 'react';

import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';

import { Flex } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

import { CommunityMeetType } from 'types/communityMeet.types';

import { CommunityEntityKind } from '../../../../../tabs.utils';
import SuggestedHeaderInfo from '../../../../suggested-header-info';
import { CommunitySuggestedEntityType } from '../../../../suggested/suggestedTab.utils';
import s from './Header.module.scss';

interface Props {
  communityMeet: CommunityMeetType;
  communityId: string;
  communityEntityKind: CommunityEntityKind;
  headerActions: ReactNode;
}

const Header = (props: Props) => {
  const { communityMeet, communityId, communityEntityKind, headerActions } = props;
  const { title } = communityMeet;

  return (
    <Flex vertical gap="8" className={s.wrapper}>
      <ContentHeader>
        <ContentHeader.Title goBackLink={getCommunityManagementViewByIdRoute(communityId)}>
          {title}
        </ContentHeader.Title>
        <ContentHeader.Actions>{headerActions}</ContentHeader.Actions>
      </ContentHeader>

      {/* Только для предложки (sourceType: MOBILE) */}
      {/* Инфа про предложенную entity и автора (creator) */}
      <SuggestedHeaderInfo
        entity={communityMeet as CommunitySuggestedEntityType}
        communityEntityKind={communityEntityKind}
      />
    </Flex>
  );
};

export default Header;
