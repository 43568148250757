import { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import {
  useGetDeclarationTypeQuery,
  useRemoveTemplateMutation,
  useUploadTemplateMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Flex, Form, Icon, Tooltip, Typography, UploadProps } from 'gazprom-ui-lib';

import File from 'components/file';
import { FORM_IMAGE_ENUM } from 'containers/image-modal/imageModal.utils';

import { FileDataType } from 'types/documents.types';

import s from './DocumentsDescriptionsFormUpload.module.scss';
import {
  AVAILABLE_TYPES,
  beforeUpload,
  getDownloadUrl,
} from './documentsDescriptionsFormUpload.utils';

interface Props {
  isDisabled?: boolean;
}

const DocumentsDescriptionsFormUpload = (props: Props) => {
  const { isDisabled } = props;

  const { id: statementId } = useParams<{ id: string }>() as { id: string };
  const form = Form.useFormInstance();
  const [fileData, setFileData] = useState<FileDataType[]>([]);
  const [fileTitle, setFileTitle] = useState<string>('');

  const { data: declarationData } = useGetDeclarationTypeQuery(statementId);
  const [uploadFile, { isLoading, isError: isUploadError, reset: uploadMutationReset }] =
    useUploadTemplateMutation();
  const [removeTemplateMutation] = useRemoveTemplateMutation();

  useEffect(() => {
    if (declarationData?.docTemplates) {
      if (declarationData.docTemplates.length > 0) {
        const updatedFileData: FileDataType[] = declarationData.docTemplates.map(
          ({ id, name, createdAt }) => ({
            id,
            title: name,
            description: dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss'),
            icon: 'documents',
            isLoaded: true,
          }),
        );

        setFileData(updatedFileData);
      } else {
        setFileData([]);
      }
    }
  }, [declarationData]);

  const setErrors = (errors: string[]) => form.setFields([{ name: FORM_IMAGE_ENUM.URL, errors }]);

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const file = (options as { file: File }).file;
    const formData = new FormData();
    formData.append('template', file);
    setFileTitle(file.name);

    try {
      await uploadFile({ statementId, file: formData }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const onClickResetHandler = () => {
    form.resetFields(['file']);
    if (isUploadError) {
      uploadMutationReset();
    }
  };

  const deleteHandler = async (templateId: string) => {
    try {
      removeTemplateMutation({ statementId, templateId }).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  const statementTemplates = fileData.map((file) => (
    <File.Download
      key={file.id}
      {...file}
      deleteHandler={isDisabled ? undefined : () => deleteHandler(file.id)}
      resetHandler={onClickResetHandler}
      handleDownload={() =>
        downloadHandler({
          url: getDownloadUrl({ statementId, templateId: file.id }),
          fileName: file.title,
        })
      }
    />
  ));

  return (
    <div className={s.container}>
      <div className={s.titleContainer}>
        <div className={s.title}>
          <Typography.Title level={5}>{t('documents_declarations_template')}</Typography.Title>
          <Tooltip
            overlayClassName={s.tooltip}
            arrow={false}
            trigger="hover"
            align={{ offset: [20, 16] }}
            placement="bottomLeft"
            title={
              <Typography.Text size="12" weight="400" className={s.tooltipTitle}>
                {t('documents_declarations_template_help_text')}
              </Typography.Text>
            }>
            <button className={s.tooltipIcon}>
              <Icon name="info" />
            </button>
          </Tooltip>
        </div>

        <a
          href="/templateExample.docx"
          download={t('documents_declarations_download_template')}
          className={s.download}>
          <Icon name="download" />
          {t('common_download_example')}
        </a>
      </div>
      <Form.Item valuePropName="fileList" noStyle>
        <Flex vertical gap="16">
          {statementTemplates}
          {!isDisabled && (
            <File.Upload
              description={
                isUploadError ? t('common_template_has_error') : t('common_upload_document_file')
              }
              icon={isUploadError ? 'error' : 'upload'}
              title={isUploadError ? fileTitle : t('common_upload_document_file')}
              isError={isUploadError}
              isLoading={isLoading}
              availableFileTypes={AVAILABLE_TYPES}
              beforeUpload={(file) => beforeUpload(file, setErrors)}
              customRequest={customRequest}
              resetHandler={onClickResetHandler}
            />
          )}
        </Flex>
      </Form.Item>
    </div>
  );
};

export default DocumentsDescriptionsFormUpload;
