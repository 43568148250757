import { prepareQueryParams } from 'utils/prepareQueryParams';

import cInitialApiServiceV2 from 'services/1c-v2/cApiServiceV2';
import {
  ActivatePowerOfAttorneyByIdPropsType,
  ActivatePowerOfAttorneyByIdResponseType,
  DeactivatePowerOfAttorneyByIdPropsType,
  DeactivatePowerOfAttorneyByIdResponseType,
  GetPowerOfAttorneyByIdPropsType,
  GetPowerOfAttorneyByIdResponseType,
  GetPowerOfAttorneyPropsType,
  GetPowerOfAttorneyResponseType,
} from 'services/power-of-attorney-private/powerOfAttorneyPrivateApiService.types';

import { C_SERVICE_TAGS } from 'constants/serviceTags';

const powerOfAttorneyPrivateService = cInitialApiServiceV2.injectEndpoints({
  endpoints: (builder) => ({
    getPowerOfAttorney: builder.query<GetPowerOfAttorneyResponseType, GetPowerOfAttorneyPropsType>({
      query: (params) => ({
        url: `private/power_of_attorney${prepareQueryParams(params)}`,
      }),
      providesTags: [C_SERVICE_TAGS.GET_POWER_OF_ATTORNEY],
    }),
    getPowerOfAttorneyById: builder.query<
      GetPowerOfAttorneyByIdResponseType,
      GetPowerOfAttorneyByIdPropsType
    >({
      query: (id) => ({
        url: `private/power_of_attorney/${id}`,
      }),
      providesTags: [C_SERVICE_TAGS.GET_POWER_OF_ATTORNEY_BY_ID],
    }),
    activatePowerOfAttorney: builder.mutation<
      ActivatePowerOfAttorneyByIdResponseType,
      ActivatePowerOfAttorneyByIdPropsType
    >({
      query: (id) => ({
        url: `private/power_of_attorney/${id}/active`,
        method: 'PATCH',
      }),
    }),
    deactivatePowerOfAttorney: builder.mutation<
      DeactivatePowerOfAttorneyByIdResponseType,
      DeactivatePowerOfAttorneyByIdPropsType
    >({
      query: (id) => ({
        url: `private/power_of_attorney/${id}/deactive`,
        method: 'PATCH',
      }),
    }),
  }),
});

export const {
  useGetPowerOfAttorneyQuery,
  useGetPowerOfAttorneyByIdQuery,
  useActivatePowerOfAttorneyMutation,
  useDeactivatePowerOfAttorneyMutation,
} = powerOfAttorneyPrivateService;
