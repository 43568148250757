import { Dispatch, SetStateAction } from 'react';

import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { useValidateAuthorityByIdMutation } from 'services/crypto/cryptoApiService';

import { Button, TableProps, handleRequest } from 'gazprom-ui-lib';

import TableText from 'components/table/table-text';

import { AuthorityType } from 'types/authority.types';

import { transformCN } from '../../settingsCertificationAuthorities.utils';
import s from './Table.module.scss';
import { ModalType } from './table.utils';

interface Props {
  setModalConfig: Dispatch<SetStateAction<ModalType>>;
}

const useColumns = (props: Props): TableProps<AuthorityType>['columns'] => {
  const { setModalConfig } = props;

  const [validate, { isLoading, originalArgs }] = useValidateAuthorityByIdMutation();

  const nameRenderer = (name: string) => {
    return (
      <TableText size="16" weight="500">
        {transformCN(name)}
      </TableText>
    );
  };

  const dateRenderer = (date: string) => (
    <TableText size="14" weight="500">
      {dayjs(date).format('DD.MM.YYYY')}
    </TableText>
  );

  const handleValidate = (rowData: AuthorityType) => () => {
    validate(rowData.serial).then(
      handleRequest({
        onSuccess: (res) => {
          let type: 'error' | 'success' = 'error';

          if (res) {
            if (res.crl.some((e) => e.available)) {
              type = 'success';
            }
            if (res.ocsp.some((e) => e.available)) {
              type = 'success';
            }
          }

          setModalConfig({
            message: res?.message,
            data: rowData,
            type,
          });
        },
        onError: (err) => {
          setModalConfig({
            message: (err as { data: { message: string } })?.data?.message ?? '',
            data: rowData,
            type: 'error',
          });
        },
      }),
    );
  };

  const actionRenderer = (rowData: AuthorityType) => {
    return (
      <Button
        loading={isLoading && originalArgs === rowData.serial}
        className={s.button}
        leftIcon="checklist"
        type="link"
        size="small"
        onClick={handleValidate(rowData)}>
        {t('certification_authorities_check_availability')}
      </Button>
    );
  };

  return [
    {
      title: t('certification_authorities_name'),
      dataIndex: 'subject',
      width: 500,
      render: nameRenderer,
    },
    {
      title: t('common_active_date'),
      dataIndex: 'notBefore',
      align: 'center',
      width: 140,
      render: dateRenderer,
    },
    {
      title: t('common_finish_date'),
      dataIndex: 'notAfter',
      align: 'center',
      width: 140,
      render: dateRenderer,
    },
    {
      width: 280,
      render: actionRenderer,
    },
  ];
};

export default useColumns;
