import { useNavigate } from 'react-router-dom';
import { EVENTS_ROUTE, getEventsWithIdRoute } from 'routes/events/list';
import { t } from 'tools/i18n';
import { prepareDateAndTime } from 'utils/date.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useCreateEventMutation } from 'services/meet/meetApiService';
import { CreateEventPropsType } from 'services/meet/meetApiService.types';

import { EVENT_FORM_ENUM, EventFormResponse } from 'containers/event-form/eventForm.utils';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';
import { EventType } from 'types/event.types';

const useSubmit = () => {
  const navigate = useNavigate();

  const [createEvent, { isLoading: createEventIsFetching }] = useCreateEventMutation();

  const handleReset = () => {
    navigate(EVENTS_ROUTE);
  };

  const handleGoToDetails = (e: EventType | undefined) => {
    if (e) {
      navigate(getEventsWithIdRoute(e?.meeting?.id));
    }
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccess: handleGoToDetails,
    onSuccessModalConfig: {
      title: {
        children: t('common_added_successfully'),
      },
    },
  });

  const handleSubmit = (form: EventFormResponse) => {
    const articleCreateReqDto: CreateEventPropsType['articleCreateReqDto'] = {
      type: ARTICLE_TYPE_ENUM.EVENT,
      // employerId: form[EVENT_FORM_ENUM.ORGANIZATIONS],
      text: form[EVENT_FORM_ENUM.DESCRIPTION],
      title: form[EVENT_FORM_ENUM.TITLE],
      categories: form[EVENT_FORM_ENUM.CATEGORIES],
      cover: form[EVENT_FORM_ENUM.COVER],
      isPublish: false,
    };

    const meetingCreateReqDto: CreateEventPropsType['meetingCreateReqDto'] = {
      group: {
        partialChecked: form[EVENT_FORM_ENUM.TARGET_GROUP].halfChecked.map(transformGroup),
        checked: form[EVENT_FORM_ENUM.TARGET_GROUP].checked.map(transformGroup),
      },
      isNotify: !!form[EVENT_FORM_ENUM.SEND_NOTIFICATION],
      gender: form[EVENT_FORM_ENUM.TARGET_GROUP_GENDER],
      meetDate: prepareDateAndTime(
        form[EVENT_FORM_ENUM.EVENT_DATE],
        form[EVENT_FORM_ENUM.EVENT_TIME],
      )!,
      maxEmployees: form[EVENT_FORM_ENUM.UNLIMITED_PARTICIPATION]
        ? 0
        : Number(form[EVENT_FORM_ENUM.PARTICIPANTS]),
      city: form[EVENT_FORM_ENUM.CITY],
      location: form[EVENT_FORM_ENUM.LOCATION],
      requiresDocs: !!form[EVENT_FORM_ENUM.DOCS],
      registrationActive: !!form[EVENT_FORM_ENUM.REGISTRATION_AVAILABLE],
    };

    if (form[EVENT_FORM_ENUM.SEND_NOTIFICATION]) {
      meetingCreateReqDto.titleNotice = form[EVENT_FORM_ENUM.NOTIFICATION_TITLE];
      meetingCreateReqDto.bodyNotice = form[EVENT_FORM_ENUM.NOTIFICATION_TEXT];
    }

    createEvent({ articleCreateReqDto, meetingCreateReqDto }).then(handleCreate);
  };

  return {
    createEventIsFetching,
    handleSubmit,
    handleReset,
  };
};

const transformGroup = (idWithType: string) => {
  const [id, type] = idWithType.split('.');

  return {
    id,
    type,
  };
};

export default useSubmit;
