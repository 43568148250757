import { GetCommunityNewsListPropsType } from 'services/communities/communityApiService.types';

import { PaginationPropsType } from 'types/pagination.types';
import { SORT_ENUM } from 'types/sort.types';

type FormFilters = {
  categoryIds?: GetCommunityNewsListPropsType['categoryIds'];
  sourceType?: GetCommunityNewsListPropsType['sourceType'];
};

export type FormResponseType = PaginationPropsType & {
  property: string;
  order: SORT_ENUM;
  search?: string;
  filters?: FormFilters;
};

export const DEFAULT_FORM_STATE: FormResponseType = {
  page: 1,
  size: 10,
  property: 'updatedAt',
  order: SORT_ENUM.DESC,
};
