import { useState } from 'react';

import { Form } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import { CommunityNewsComment } from 'types/community.types';

import { CommunityEntity, DEFAULT_FORM_STATE, FormResponseType } from './communityComments.utils';
import DetailsModal from './containers/details-modal';
import Filters from './containers/filters';
import useColumns from './useColumns';
import useRequest from './useRequest.hook';

interface Props {
  isRemoved?: boolean;
  communityEntity?: CommunityEntity;
}

const CommunityComments = (props: Props) => {
  const { isRemoved = false, communityEntity = 'news' } = props;
  const [form] = Form.useForm();

  const { isLoading, isFetching, data } = useRequest({
    form,
    communityEntity,
    visible: !isRemoved,
  });
  const columns = useColumns();
  const [currentComment, setCurrentComment] = useState<CommunityNewsComment | null>(null);

  const handleOnRow = (record: CommunityNewsComment) => ({
    onClick: () => !isRemoved && setCurrentComment(record),
  });

  const handleCloseDetails = () => setCurrentComment(null);

  return (
    <>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <Filters />

        <FiltersV2.Table<CommunityNewsComment, FormResponseType>
          isInitialLoading={isLoading}
          rowKey="id"
          dataSource={data?.items}
          total={data?.totalCount}
          loading={isFetching}
          onRow={handleOnRow}
          columns={columns}
        />
      </FiltersV2>
      <DetailsModal detailsComment={currentComment} closeDetails={handleCloseDetails} />
    </>
  );
};

export default CommunityComments;
