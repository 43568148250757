import { useNavigate } from 'react-router-dom';
import { SURVEYS_SETTLEMENT_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import { Button, Flex } from 'gazprom-ui-lib';

import { SURVEY_SETTLEMENT_FORM_NAME } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form/surveySettlementForm.utils';
import { useGetSurvey } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/useGetSurvey.hook';

import PublishModal from './containers/survey-settlement-form/containers/publish-modal';

interface Props {
  isSubmittingForm: boolean;
  handleClosePublishModal: () => void;
  handleOpenPublishModal: () => void;
  handlePublish: () => void;
  isPublishModalOpen: boolean;
}

export const useFormActions = (props: Props) => {
  const {
    isPublishModalOpen,
    handleClosePublishModal,
    handleOpenPublishModal,
    handlePublish,
    isSubmittingForm,
  } = props;

  const { survey } = useGetSurvey();
  const navigate = useNavigate();
  const handleClose = () => navigate(SURVEYS_SETTLEMENT_ROUTE);

  const isUpdate = !!survey;
  const { isPublish } = survey || {};

  return (
    <Flex gap="8">
      <Button
        disabled={isPublish}
        loading={isSubmittingForm}
        leftIcon="ok"
        htmlType="submit"
        form={SURVEY_SETTLEMENT_FORM_NAME}
        fullWidth>
        {t('common_save')}
      </Button>

      <Button
        danger={isPublish}
        onClick={handleOpenPublishModal}
        loading={isSubmittingForm}
        leftIcon={isPublish ? 'unpublish' : 'checklist'}
        fullWidth
        disabled={!isUpdate}>
        {t(isPublish ? 'common_unpublish' : 'common_publish')}
      </Button>

      <Button type="secondary" onClick={handleClose} fullWidth>
        {t('common_close')}
      </Button>

      <PublishModal
        isLoading={isSubmittingForm}
        open={isPublishModalOpen}
        onCancel={handleClosePublishModal}
        onActionClick={handlePublish}
      />
    </Flex>
  );
};
