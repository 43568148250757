import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import { MASS_SUBSCRIBE_INVITE_FORM_NAME } from 'containers/mass-subscribe-invite-form/massSubscribeInviteForm.utils';

interface Props {
  open: boolean;
  close: () => void;
  isMassSubscribePage: boolean;
  isSubscribingOrInviting: boolean;
}

const ConfirmModal = (props: Props) => {
  const { open, close, isMassSubscribePage, isSubscribingOrInviting } = props;
  return (
    <ModalDefault open={open} onCancel={close} width={320} closable>
      <ModalDefault.Icon name="groupsFilled" type="primary" />
      <ModalDefault.Title>
        {t(
          isMassSubscribePage
            ? 'community_subscribe_multiple_confirm_title'
            : 'community_invite_multiple_confirm_title',
        )}
      </ModalDefault.Title>
      <ModalDefault.Description>
        {t(
          isMassSubscribePage
            ? 'community_subscribe_multiple_confirm_description'
            : 'community_invite_multiple_confirm_description',
        )}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          disabled={isSubscribingOrInviting}
          loading={isSubscribingOrInviting}
          form={MASS_SUBSCRIBE_INVITE_FORM_NAME}
          htmlType="submit"
          fullWidth
          leftIcon="ok">
          {t('common_confirm')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default ConfirmModal;
