import { useEffect, useState } from 'react';

import { useAuth } from 'react-oidc-context';

import { useGetWorkerEmployeesQuery } from 'services/1c/cApiService';

import { GLOBAL_ROLES } from '../types/roles.types';

import useFullSelectedEmployee from './useFullSelectedEmployee.hook';

const ALLOWED_ROLES = Object.values(GLOBAL_ROLES);

const useInitialAuthRequestsHook = () => {
  const auth = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedEmployee] = useFullSelectedEmployee();

  const userRoles = (auth?.user?.profile?.roles as string[]) ?? [];
  const hasAllowedRole = userRoles?.some((role) => ALLOWED_ROLES.includes(role as GLOBAL_ROLES));

  const { data: employeesData, isLoading: getEmployeesIsLoading } = useGetWorkerEmployeesQuery(
    undefined,
    {
      skip: !auth?.isAuthenticated,
    },
  );

  useEffect(() => {
    if (isLoading && !auth.isLoading && auth.activeNavigator !== 'signinSilent') {
      setIsLoading(false);
    }
  }, [auth.activeNavigator, auth.isLoading, isLoading]);

  return {
    isAuthenticated: auth.isAuthenticated,
    employeesData,
    selectedEmployee,
    isLoading,
    getEmployeesIsLoading,
    hasAllowedRole,
  };
};

export default useInitialAuthRequestsHook;
