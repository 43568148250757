import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import { CommunityEntityKind } from '../../../../tabs.utils';

interface Props {
  communityEntityKind: CommunityEntityKind;
}

const StatusBadge = (props: Props) => {
  const { communityEntityKind } = props;
  switch (communityEntityKind) {
    case 'mobileAccepted':
      return (
        <Flex gap="4">
          <Icon name="okFilled" color="var(--color-success)" />
          <Typography.Text type="success" size="14" weight="400">
            {t('common_approved')}
          </Typography.Text>
        </Flex>
      );
    case 'mobileRejected':
      return (
        <Flex gap="4">
          <Icon name="deleteFilled" color="var(--color-error)" />
          <Typography.Text type="danger" size="14" weight="400">
            {t('community_invite_rejected')}
          </Typography.Text>
        </Flex>
      );
    default:
      return null;
  }
};

export default StatusBadge;
