import { t } from 'tools/i18n';

import { useGetAttributeValidationQuery } from 'services/kedo/kedoApiService';

import { Flex, Form, Select, Typography } from 'gazprom-ui-lib';

import { DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM } from '../documentsDeclarationsForm.utils';
import s from './DocumentsDeclarationsFormRules.module.scss';

interface Props {
  id: string;
  defaultValue?: string;
  disabled?: boolean;
}

const DocumentsDeclarationsFormRules = (props: Props) => {
  const { id, defaultValue, disabled } = props;

  const { data: validationOptions } = useGetAttributeValidationQuery({});

  const options =
    validationOptions?.map((item) => ({
      label: item.title,
      value: item.code,
    })) ?? [];

  return (
    <>
      <Flex gap="4" align="flex-end" className={s.titleContainer}>
        <Typography.Title level={5}>{t('common_checking')}</Typography.Title>
        <Typography.Text size="12" weight="400" type="secondary">
          {t('common_optional')}
        </Typography.Text>
      </Flex>
      <Form.Item
        name={`${DOCUMENTS_ADDITIONAL_DECLARATIONS_FORM_ENUM.VALIDATION_CODE}.${id}`}
        initialValue={defaultValue}>
        <Select
          disabled={disabled}
          allowClear
          options={options}
          placeholder={t('common_select_from_list')}
          showSearch
          filterOption
          optionFilterProp="label"
        />
      </Form.Item>
    </>
  );
};

export default DocumentsDeclarationsFormRules;
