import { ReactNode, useMemo } from 'react';

import { useAuth } from 'react-oidc-context';

interface Props<C, F> {
  content: C;
  fallbackValue?: F;
  roles?: string[];
}

const useRoleModelContent = <C = ReactNode, F = ReactNode>(props: Props<C, F>): C | F => {
  const { content, fallbackValue = null as F, roles } = props;
  const { user } = useAuth();

  const userRoles = user?.profile?.roles as string[] | undefined;

  const userHasNecessaryRole: boolean = !!roles?.some((role) => {
    return userRoles?.includes(role);
  });

  return useMemo(
    () => (userHasNecessaryRole ? content : fallbackValue),
    [content, fallbackValue, userHasNecessaryRole],
  );
};

export default useRoleModelContent;
