export enum GLOBAL_ROLES {
  ADMIN = 'admin',
  ADMIN_MOBILE = 'admin_mobile',
  MODERATOR = 'moderator',
  MODERATOR_NOTICE = 'moderator_notice',
  MODERATOR_NEWS = 'moderator_news',
  MODERATOR_NEWS_ORG = 'moderator_news_org',
  MODERATOR_SOCIAL = 'moderator_social',
  MODERATOR_ANKETA = 'moderator_anketa',
  MODERATOR_TOPS_ANKETA = 'moderator_tops_anketa',
  KEDO_ADMIN = 'kedo_admin',
  KEDO_MODERATOR = 'kedo_moderator',
  KEDO_SIGNER = 'kedo_signer',
  COMMUNITY_ADMIN = 'community_admin',
  COMMUNITY_MODERATOR = 'community_moderator',
  ADMIN_ICC = 'admin_icc',
}

export type RoleType = {
  code: string;
  name: string;
  description?: string;
  instruction?: {
    fileKey: string;
    name: string;
    version: number;
  };
  editor: {
    id: string;
    lastName: string;
    firstName: string;
    patronymic: string;
  };
  updatedAt: string;
};
