import { t } from 'tools/i18n';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_MEETS_FORM_ENUM } from 'containers/community-meet-form/communityMeetForm.utils';

const IsPublish = () => {
  return (
    <Form.Item name={COMMUNITY_MEETS_FORM_ENUM.IS_PUBLISH} valuePropName="checked">
      <Checkbox>
        <Typography.Text size="14">{t('common_publish')}</Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default IsPublish;
