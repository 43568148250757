import { t } from 'tools/i18n';
import { getShortenName } from 'utils/getShortenName.utils';

import { ColumnsType, Tooltip, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';
import TableText from 'components/table/table-text';

import { ShortCreatorEditorType } from 'types/creatorEditor.types';
import { CurrentKedoStatusType } from 'types/documents.types';
import { KedoReviewTaskType } from 'types/review-task.types';

import s from './DocumentsKedoReviewTasks.module.scss';

const useColumns = (): ColumnsType<KedoReviewTaskType> => {
  const nameRenderer = (text: string) => {
    return (
      <TableText size="14" weight="500">
        {text}
      </TableText>
    );
  };

  const docNameRenderer = (text: string) => {
    const tooltipContent = (
      <Typography.Text className={s.tooltipTitle} size="12">
        {text}
      </Typography.Text>
    );

    return (
      <Tooltip placement="topLeft" title={tooltipContent}>
        <TableText size="12" weight="500" type="secondary">
          {text}
        </TableText>
      </Tooltip>
    );
  };

  const creatorRenderer = (creator: ShortCreatorEditorType) => {
    const { lastName, firstName, patronymic } = creator || {};

    return (
      <div>
        <TableText size="14" weight="500">
          {getShortenName(lastName, firstName, patronymic)}
        </TableText>
      </div>
    );
  };

  const statusRenderer = (status: KedoReviewTaskType['status']) => {
    const { state, name, code, description } = status;

    const currentKedoStatusImitation: CurrentKedoStatusType = {
      comment: null,
      createdAt: null,
      description,
      state,
      status: code,
      title: name,
      titleAlt: null,
    };
    return <KedoStatusTag currentKedoStatus={currentKedoStatusImitation} size="24" />;
  };

  const sentDateRenderer = (sentDate: string) => {
    return <TableDate date={sentDate} />;
  };

  return [
    {
      title: t('common_task'),
      dataIndex: 'name',
      key: 'name',
      render: nameRenderer,
    },
    {
      title: t('common_document'),
      dataIndex: 'docName',
      key: 'docName',
      render: docNameRenderer,
    },
    {
      title: t('common_number'),
      dataIndex: 'docNumber',
      key: 'docNumber',
      render: (docNumber: string) => (
        <Typography.Text weight="500" size="12" type="secondary">
          {docNumber}
        </Typography.Text>
      ),
    },
    {
      title: t('common_document_date'),
      dataIndex: 'docDate',
      key: 'docDate',
      render: (docDate: string) => <TableDate date={docDate} />,
    },
    {
      title: t('common_author'),
      dataIndex: 'creator',
      key: 'creator',
      width: 260,
      render: creatorRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      key: 'status',
      width: 140,
      render: statusRenderer,
    },
    {
      title: t('notification_sent'),
      dataIndex: 'sentDate',
      key: 'sentDate',
      width: 100,
      align: 'center',
      sorter: true,
      defaultSortOrder: 'descend',
      render: sentDateRenderer,
    },
  ];
};

export default useColumns;
