import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Avatar, Button, Divider, Flex, ModalDefault, Typography } from 'gazprom-ui-lib';

import { CommentType } from 'types/comment.types';

import s from './Modal.module.scss';

interface Props {
  selectedRow: CommentType | null;
  isLoading: boolean;
  handleDeleteComment: (id: CommentType['id']) => void;
  handleCloseModal: () => void;
}

const Modal = (props: Props) => {
  const { selectedRow, isLoading, handleDeleteComment, handleCloseModal } = props;

  const { creator, comment, createdAt } = selectedRow || {};
  const { position, employer } = creator || {};
  const { firstName, lastName, patronymic } = creator?.person || {};
  const fullName = [lastName, firstName, patronymic].filter(Boolean).join(' ');

  return (
    <ModalDefault open={!!selectedRow} onCancel={handleCloseModal} closable>
      <Flex gap="16" vertical style={{ textAlign: 'start', width: '100%' }}>
        <Typography.Text size="14" type="primary" weight="500">
          {t('common_sent_at', {
            date: dayjs(createdAt).format('DD.MM.YYYY'),
            time: dayjs(createdAt).format('HH:mm'),
          })}
        </Typography.Text>
        <Flex gap="12" vertical>
          <Divider />
          <Flex gap="12" align="center">
            <Avatar
              firstName={firstName ?? ''}
              lastName={lastName ?? ''}
              size="40"
              className={s.avatar}
            />
            <Flex vertical>
              {employer?.name && (
                <Typography.Text size="10" type="secondary">
                  {employer.name}
                </Typography.Text>
              )}
              <Typography.Text size="14" weight="500">
                {fullName}
              </Typography.Text>
              {position?.name && (
                <Typography.Text size="10" type="secondary">
                  {position.name}
                </Typography.Text>
              )}
            </Flex>
          </Flex>
          <Divider />
        </Flex>
        <Flex gap="8" vertical>
          <Typography.Text size="14" type="primary" weight="500">
            {t('commont_comment_text')}
          </Typography.Text>
          <Typography.Text size="14">{comment}</Typography.Text>
        </Flex>
      </Flex>
      <ModalDefault.Actions>
        <Flex gap="8">
          <Button
            danger
            leftIcon="bin"
            fullWidth
            onClick={() => handleDeleteComment(selectedRow!.id)}
            loading={isLoading}>
            {t('common_delete')}
          </Button>
          <Button fullWidth onClick={handleCloseModal} type="secondary">
            {t('common_close')}
          </Button>
        </Flex>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default Modal;
