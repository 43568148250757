import { FC, useEffect, useState } from 'react';

import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetDivisionsQuery } from 'services/division/divisionApiService';
import { useGetEmployersListQuery } from 'services/employer/employerApiService';
import { useLazyGetPositionsQuery } from 'services/position/positionApiService';

import { Select } from 'gazprom-ui-lib';

interface CompanyFiltersProps {
  handleCompanyChange?: (e: string) => void;
  handleDivisionChange?: (e: string) => void;
  handleStaffChange?: (e: string) => void;
  company?: string | null;
  position?: string | null;
  division?: string | null;
}
const CompanyFilters: FC<CompanyFiltersProps> = (props) => {
  const {
    company,
    position,
    division,
    handleCompanyChange,
    handleStaffChange,
    handleDivisionChange,
  } = props;

  const [search, setSearch] = useState<string>('');

  const { employersOptions } = useGetEmployersListQuery(
    { page: 1, size: 500 },
    { selectFromResult },
  );
  const [getPositions, { data: positions }] = useLazyGetPositionsQuery({
    selectFromResult: simpleSelectFromResult,
  });
  const [getDivisions, { data: divisions }] = useLazyGetDivisionsQuery({
    selectFromResult: simpleSelectFromResult,
  });

  const { debouncedValue: debouncedSearch } = useDebounce(search);

  useEffect(() => {
    if (company) {
      getPositions({ employerId: company });
      getDivisions({ employerId: company, name: debouncedSearch || undefined });
    }
  }, [company, getDivisions, getPositions, debouncedSearch]);

  const filterOptionHansdler = (input: string, option?: { label: React.ReactNode }) => {
    if (!option?.label) {
      return false;
    }

    return option.label.toString().toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div>
      <Select
        allowClear
        options={employersOptions}
        placeholder="Организация"
        value={company}
        onChange={handleCompanyChange}
        showSearch
        filterOption={filterOptionHansdler}
      />
      <Select
        allowClear
        options={positions}
        placeholder="Должность"
        disabled={!company}
        onChange={handleStaffChange}
        value={position}
        showSearch
        filterOption={filterOptionHansdler}
      />
      <Select
        showSearch
        searchValue={search}
        onSearch={setSearch}
        allowClear
        options={divisions}
        placeholder="Подразделение"
        disabled={!company}
        value={division}
        onChange={handleDivisionChange}
        filterOption={() => true}
      />
    </div>
  );
};

const selectFromResult = ({
  data,
  ...other
}: {
  data?: { content: { id: string; name: string }[] };
}) => ({
  employersOptions: data?.content?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
  ...other,
});
const simpleSelectFromResult = ({ data, ...other }: { data?: { id: string; name: string }[] }) => ({
  data: data?.map(({ name, id }) => ({ value: id, label: name })) ?? [],
  ...other,
});

export default CompanyFilters;
