import { useMemo, useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useParams } from 'react-router-dom';
import { DECLARATIONS_TYPE_TAB_ENUM } from 'routes/settings/list';
import { t } from 'tools/i18n';

import { useGetDeclarationTypeQuery } from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';

import { Tabs } from 'gazprom-ui-lib';

import WithOverflow from 'containers/wrappers/with-overflow';

import { KEDO_TYPE_ENUM } from 'types/kedo.types';

import DocumentsDeclarationsAdditional from '../documents-declarations-additional';
import useDeclarationRoleModel from '../useDeclarationRoleModel';
import usePopoverHook from '../usePopover.hook';
import useTemplateTab from '../useTemplateTab.hook';
import s from './DocumentsDeclarationsEdo.module.scss';
import Header from './containers/header';
import TabCommon from './containers/tab-common';
import TabCreate from './containers/tab-create';
import TabEdit from './containers/tab-edit';
import TabParameters from './containers/tab-parameters';
import { ADDITIONAL_TAB, COMMON_TAB, PARAMETERS_TAB } from './documentsDeclarationsEdo.utils';

const DocumentsDeclarationsEdo = () => {
  const { id, declarationTypeTab } = useParams<{
    id: string;
    declarationTypeTab: DECLARATIONS_TYPE_TAB_ENUM;
  }>();

  const { data } = useGetDeclarationTypeQuery(id ?? skipToken);

  const isLocalTab = declarationTypeTab === DECLARATIONS_TYPE_TAB_ENUM.LOCAL;

  const CREATE_TABS = [
    {
      key: COMMON_TAB,
      label: t('common_common_description'),
      children: isLocalTab ? <TabCreate /> : <TabCommon isOrgRequired={false} />,
    },
  ];

  const { additionalFieldsDisabled, templateDisabled } = useDeclarationRoleModel({});
  const EDIT_TABS = useMemo(
    () => [
      {
        key: COMMON_TAB,
        label: t('common_common_description'),
        children: isLocalTab ? <TabEdit /> : <TabCommon />,
      },
      {
        key: PARAMETERS_TAB,
        label: t('documents_declarations_parameters_tab'),
        children: <TabParameters />,
      },
      {
        key: ADDITIONAL_TAB,
        label: t('common_additional_fields'),
        children: <DocumentsDeclarationsAdditional isDisabled={additionalFieldsDisabled} />,
      },
    ],
    [additionalFieldsDisabled, isLocalTab],
  );

  const defaultActiveTab = id ? EDIT_TABS[0].key : CREATE_TABS[0].key;

  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  const { popoverIsOpen, popoverHandler } = usePopoverHook({ activeTab });
  const templateTab = useTemplateTab({
    popoverIsOpen,
    onClose: popoverHandler,
    isDisabled: templateDisabled,
  });

  let tabs = useMemo(() => [...EDIT_TABS, templateTab], [EDIT_TABS, templateTab]);

  if (data?.type === KEDO_TYPE_ENUM.KEDO) {
    tabs.splice(-1);
  }

  return (
    <WithOverflow>
      <Header activeTab={activeTab} />
      <Tabs
        className={s.tabs}
        items={id ? tabs : CREATE_TABS}
        type="card"
        onTabClick={setActiveTab}
        activeKey={activeTab}
      />
    </WithOverflow>
  );
};

export default DocumentsDeclarationsEdo;
