import { useState } from 'react';

import { useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';

import { Flex } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import DiscussionCreateEditModal from 'containers/discussion-create-edit-modal';

import { CommunityDiscussionType } from 'types/community.types';

import { getCommunityEntityKind } from '../../../../../tabs.utils';
import SuggestedHeaderInfo from '../../../../suggested-header-info';
import CreateCommentModal from '../comments/containers/create-comment-modal';
import s from './Header.module.scss';
import { useActions } from './useActions.hook';

interface Props {
  title: string;
  discussion?: CommunityDiscussionType;
}

const Header = (props: Props) => {
  const { title, discussion } = props;
  const { id } = useParams<{ id: string }>();
  const [isCreateCommentModalVisible, setIsCreateCommentModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const communityEntityKind = getCommunityEntityKind({
    sourceType: discussion?.sourceType,
    status: discussion?.status,
  });

  const discussionDetailsActions = useActions({
    communityEntityKind,
    discussion,
    setIsCreateCommentModalVisible,
  });

  if (!discussion) {
    return null;
  }

  return (
    <>
      <DiscussionCreateEditModal
        communityId={id!}
        edit
        discussion={discussion}
        open={isEditModalVisible}
        onClose={() => setIsEditModalVisible(false)}
      />
      <Flex vertical gap="8" className={s.wrapper}>
        <ContentHeader>
          {/* @TODO custom handleEdit........ */}
          <ContentHeader.Title
            editConfig={{
              value: '',
              isLoading: false,
              handleSave: () => new Promise(() => {}),
              title: '',
              label: '',
              handleEdit: () => setIsEditModalVisible(true),
            }}
            goBackLink={getCommunityManagementViewByIdRoute(id!)}>
            {title}
          </ContentHeader.Title>
          <ContentHeader.Actions>{discussionDetailsActions}</ContentHeader.Actions>
        </ContentHeader>
        <SuggestedHeaderInfo communityEntityKind={communityEntityKind} entity={discussion} />
      </Flex>
      <CreateCommentModal
        open={isCreateCommentModalVisible}
        handleClose={() => setIsCreateCommentModalVisible(false)}
      />
    </>
  );
};

export default Header;
