import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, TIconsLiteral, Typography } from 'gazprom-ui-lib';

import s from './UploadImitation.module.scss';

interface Props {
  handleClick: () => void;
  title?: string;
  description?: string;
  className?: string;
  iconName?: TIconsLiteral;
}

const UploadImitation = (props: Props) => {
  const {
    iconName = 'upload',
    className,
    handleClick,
    title = t('common_upload_an_image'),
    description = t('validation_upload_an_image_restrictions'),
  } = props;

  return (
    <button onClick={handleClick} className={cn(s.wrapper, className)} type="button">
      <Icon name={iconName} />
      <div>
        <Typography.Text size="14" weight="500" type="primary">
          {title}
        </Typography.Text>
        <Typography.Text size="12" type="secondary">
          {description}
        </Typography.Text>
      </div>
    </button>
  );
};

export default UploadImitation;
