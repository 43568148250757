import { useParams } from 'react-router-dom';
import { COMMUNITIES_ROUTE } from 'routes/communities/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

interface Props {
  openConfirmModal: () => void;
  isMassSubscribePage: boolean;
  disableSubmit: boolean;
  isLoading: boolean;
}

const Header = (props: Props) => {
  const { openConfirmModal, isMassSubscribePage, disableSubmit, isLoading } = props;
  const { id: communityId } = useParams<{ id: string }>();

  return (
    <ContentHeader>
      <ContentHeader.Title
        goBackLink={`${COMMUNITIES_ROUTE}/management/view/${communityId}/subscribers`}>
        {t(
          isMassSubscribePage
            ? 'community_add_multiple_page_title'
            : 'community_invite_multiple_page_title',
        )}
      </ContentHeader.Title>
      <ContentHeader.Actions>
        <Button
          loading={isLoading}
          disabled={disableSubmit}
          onClick={openConfirmModal}
          leftIcon="plus">
          {t(isMassSubscribePage ? 'community_add_multiple_action' : 'community_invite')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
