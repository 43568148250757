import { Tabs } from 'gazprom-ui-lib';

import s from './Tabs.module.scss';
import { tabsItems } from './tabs.utils';

const CommunityManagementViewTabs = () => {
  return <Tabs className={s.wrapper} type="card" defaultActiveKey="NEWS" items={tabsItems} />;
};

export default CommunityManagementViewTabs;
