import { useState } from 'react';

import Pages from 'pages';
import useInitialAuthRequestsHook from 'utils/useInitialAuthRequestsHook';

import { ConfigProvider, Flex } from 'gazprom-ui-lib';

import LoginLoader from 'containers/login-loader';
import ProfileModal from 'containers/profile-modal';
import SavedModal from 'containers/saved-modal';

import './App.module.scss';
import { SaveModalConfigType, SaveModalContext } from './app.utils';
import NoPermission from './pages/no-permission';
import useLastUserActionHook from './utils/useLastUserAction.hook';
import usePinCodeAuthorizeHook from './utils/usePinCodeAuthorize.hook';

const AppPrepare = () => {
  const {
    isAuthenticated,
    employeesData,
    selectedEmployee,
    isLoading,
    getEmployeesIsLoading,
    hasAllowedRole,
  } = useInitialAuthRequestsHook();

  useLastUserActionHook();
  usePinCodeAuthorizeHook();

  if (isLoading || getEmployeesIsLoading) return <LoginLoader />;

  const noPermissionContent = (
    <Flex align="center" justify="center" fullHeight fullWidth>
      <NoPermission withTitle={false} />
    </Flex>
  );

  return (
    <ConfigProvider.CustomContext.Provider value={{ isMobile: false }}>
      {(!isAuthenticated || selectedEmployee) && <App />}
      {!hasAllowedRole && noPermissionContent}
      <ProfileModal employeesData={employeesData} />
    </ConfigProvider.CustomContext.Provider>
  );
};

const App = () => {
  const [modalConfig, setModalConfig] = useState<SaveModalConfigType>(null);

  return (
    <SaveModalContext.Provider value={setModalConfig}>
      <Pages />
      <SavedModal modalConfig={modalConfig} setModalConfig={setModalConfig} />
    </SaveModalContext.Provider>
  );
};

export default AppPrepare;
