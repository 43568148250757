import { t } from 'tools/i18n';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import CommonSelect from 'components/form/common-select';

import { SURVEY_SETTLEMENT_FORM_ENUM } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form/surveySettlementForm.utils';

import { useGetFormData } from '../../useGetFormData.hook';
import s from './Anketa.module.scss';
import AnketaInfo from './containers/anketa-info';

const Anketa = () => {
  const { surveyOptions } = useGetFormData();

  return (
    <Flex vertical gap="8" className={s.formItemMargin}>
      <CommonSelect
        name={SURVEY_SETTLEMENT_FORM_ENUM.ANKETOLOG_ID}
        title={t('surveys_select_survey_placeholder')}
        placeholder={t('common_select_second')}
        options={surveyOptions}
        className={s.noMargin}
        isRequired
      />

      <Flex gap="8" align="center">
        <Icon name="info" color="var(--color-primary-6)" />
        <Typography.Text type="secondary">
          {t('surveys_settlement_anketa_subtitle')}
        </Typography.Text>
      </Flex>

      <AnketaInfo />
    </Flex>
  );
};

export default Anketa;
