import { t } from 'tools/i18n';

import { Flex, Form, Select } from 'gazprom-ui-lib';

import { SURVEY_CONDITIONS } from 'types/surveys.types';

import NumberFieldWithText from '../number-field-with-text';
import s from './AvailabilityConditions.module.scss';
import { CONDITION_OPTIONS } from './availabilityConditions.utils';

interface Props {
  hidden: boolean;
  initialCondition: string | undefined;
  daysCount: number | undefined;
}

const NDAYS_COUNT = 'daysCount';

const AvailabilityConditions = (props: Props) => {
  const { hidden, initialCondition, daysCount } = props;
  const form = Form.useFormInstance();
  const condition = Form.useWatch('condition', form);
  const isNDAYS = condition === SURVEY_CONDITIONS.NDAYS;

  return (
    <Flex vertical gap="12" className={s.wrapper}>
      <Form.Item
        className={s.formItem}
        name="condition"
        hidden={hidden}
        initialValue={initialCondition}
        label={t('surveys_type')}>
        <Select options={CONDITION_OPTIONS} placeholder={t('surveys_select_type')} />
      </Form.Item>

      <NumberFieldWithText
        initialValue={daysCount}
        fieldName={NDAYS_COUNT}
        firstPart={t('surveys_conditions_after_employment_first_part')}
        secondPart={t('surveys_conditions_after_employment_second_part')}
        shouldRender={isNDAYS}
      />
    </Flex>
  );
};

export default AvailabilityConditions;
