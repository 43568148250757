import { t } from 'tools/i18n';

import { ColumnsType } from 'gazprom-ui-lib';

import TableDate from 'components/table/table-date';
import TableIsPublish from 'components/table/table-is-publish';

import Receivers from 'pages/surveys/containers/receivers';
import TitleWithTags from 'pages/surveys/containers/title-with-tags';

import { SurveySettlementType } from 'types/surveys.types';

export const useColumns = (): ColumnsType<SurveySettlementType> => [
  {
    title: t('surveys_settlement_info'),
    dataIndex: 'id',
    render: (_, { name, anketologId, revisionId }: SurveySettlementType) => {
      return (
        <TitleWithTags
          text={name}
          primaryTag={t('surveys_table_anketa_id', { id: anketologId })}
          secondaryTag={t('surveys_table_revision_id', { id: revisionId })}
        />
      );
    },
  },
  {
    title: t('surveys_column_settlement'),
    dataIndex: 'settlements',
    render: (_, { settlements }) => {
      return <Receivers receivers={settlements ?? []} />;
    },
  },
  {
    title: t('common_publication'),
    dataIndex: 'isPublish',
    sorter: true,
    align: 'center',
    render: (isPublish: boolean) => {
      return <TableIsPublish isPublish={isPublish} />;
    },
  },
  {
    title: t('common_created'),
    dataIndex: 'createdAt',
    sorter: true,
    defaultSortOrder: 'descend',
    align: 'center',
    render: (createdAt: string) => {
      return <TableDate date={createdAt} />;
    },
  },
];
