import { GLOBAL_ROLES } from 'types/roles.types';

export const createDeclarationRoles = [
  GLOBAL_ROLES.ADMIN_MOBILE,
  GLOBAL_ROLES.ADMIN,
  GLOBAL_ROLES.KEDO_ADMIN,
];

export const editCommonDeclarationRoles = [GLOBAL_ROLES.ADMIN_MOBILE, GLOBAL_ROLES.ADMIN];
export const editLocalDeclarationRoles = [
  GLOBAL_ROLES.ADMIN_MOBILE,
  GLOBAL_ROLES.ADMIN,
  GLOBAL_ROLES.KEDO_ADMIN,
];
