import { getFio } from 'utils/getFio.utils';

import { CEmployeeType } from 'services/1c/cApiService.types';

import { EmployeeGroupType } from '../../types/employeeGroups.types';
import { EmployeeType } from 'types/employee.types';

export const KEDO_REVIEW_TASK_FORM_NAME = 'KEDO_REVIEW_TASK_FORM_NAME';

export enum KEDO_REVIEW_TASK_FORM_ENUM {
  NAME = 'NAME',
  DOC_NAME = 'DOC_NAME',
  FILE = 'FILE',
  SIGN_REQUIRED = 'SIGN_REQUIRED',
  INSTANCE_1C = 'INSTANCE_1C',
  DOC_NUMBER = 'DOC_NUMBER',
  DOC_DATE = 'DOC_DATE',
  EMPLOYEES = 'EMPLOYEES',
  DIVISIONS = 'DIVISIONS',
  EMPLOYER = 'EMPLOYER',
}

export type KedoReviewTaskFileType = {
  link: string;
  name: string;
  size: number;
};

export interface KedoReviewTaskFormResponse {
  [KEDO_REVIEW_TASK_FORM_ENUM.NAME]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NAME]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.FILE]: KedoReviewTaskFileType;
  [KEDO_REVIEW_TASK_FORM_ENUM.SIGN_REQUIRED]: boolean;
  [KEDO_REVIEW_TASK_FORM_ENUM.DOC_NUMBER]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.DOC_DATE]: string;
  [KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYEES]: string[];
  [KEDO_REVIEW_TASK_FORM_ENUM.DIVISIONS]: string[];
  [KEDO_REVIEW_TASK_FORM_ENUM.EMPLOYER]: string;
}

export const DEFAULT_PAGE_DATA = { page: 1, size: 500 };

export const prepareEmployeeGroupsOption = (
  employeeGroups: EmployeeGroupType[],
): { value: string; label: string }[] =>
  employeeGroups.map(({ id, title }) => ({ value: id, label: title }));

type PrepareEmployeeProps = {
  employees: EmployeeType[] | CEmployeeType[] | undefined;
};

export const prepareEmployees = ({ employees }: PrepareEmployeeProps) => {
  if (!employees) return [];
  return employees.map(
    ({ id, person: { firstName, lastName, patronymic }, position: { name: positionName } }) => {
      const fio = getFio({ firstName, lastName, patronymic, type: 'full' });
      const label = `${fio} / ${positionName}`;
      return {
        value: id,
        label,
      };
    },
  );
};
