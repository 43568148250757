import { Middleware, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { slices } from './slices/index';
import cInitialApiServiceV2 from 'services/1c-v2/cApiServiceV2';
import cInitialApiService from 'services/1c/cApiService';
import initialApiService from 'services/initialApiService';
import initialApiServiceV2 from 'services/initialApiServiceV2';

let isNotificationVisible = false;

const errorMiddleware: Middleware = (_store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (!isNotificationVisible) {
      isNotificationVisible = true;

      console.table({
        'Код ошибки': action.payload?.status,
        'Описание ошибки': action.payload?.data?.message,
      });

      notification.error({
        message: 'Произошла ошибка',
        description: 'Произошла непредвиденная ошибка. Попробуйте повторить действие позже.',
        duration: 3,
        onClose: () => {
          isNotificationVisible = false;
        },
      });
    }
  }

  return next(action);
};

export const store = configureStore({
  reducer: {
    [initialApiService.reducerPath]: initialApiService.reducer,
    [initialApiServiceV2.reducerPath]: initialApiServiceV2.reducer,
    [cInitialApiService.reducerPath]: cInitialApiService.reducer,
    [cInitialApiServiceV2.reducerPath]: cInitialApiServiceV2.reducer,
    ...slices,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
      .concat(initialApiService.middleware)
      .concat(initialApiServiceV2.middleware)
      .concat(cInitialApiService.middleware)
      .concat(cInitialApiServiceV2.middleware)
      .concat(errorMiddleware);
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
