import { useNavigate } from 'react-router-dom';
import { NEWS_ROUTE } from 'routes/news/list';
import { t } from 'tools/i18n';
import { prepareDateAndTime } from 'utils/date.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { useCreateArticleMutation } from 'services/article/articleApiService';
import { CreateArticlePropsType } from 'services/article/articleApiService.types';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import NewsForm from 'containers/news-form';
import {
  NEWS_FORM_ENUM,
  NEWS_FORM_NAME,
  NewsFormResponse,
} from 'containers/news-form/newsForm.utils';
import WithOverflow from 'containers/wrappers/with-overflow';

import { ARTICLE_TYPE_ENUM } from 'types/article.types';

import s from './NewsAdd.module.scss';

const NewsAdd = () => {
  const navigate = useNavigate();

  const [createArticle, { isLoading: createArticleIsFetching }] = useCreateArticleMutation();

  const handleReset = () => {
    navigate(NEWS_ROUTE);
  };

  const handleCreate = useHandleRequestWithModal({
    onSuccessModalConfig: {
      onClose: handleReset,
      onConfirm: handleReset,
      title: {
        children: t('common_added_successfully'),
      },
    },
  });

  const handleSubmit = (form: NewsFormResponse) => {
    const body: CreateArticlePropsType = {
      type: ARTICLE_TYPE_ENUM.NEWS,
      text: form[NEWS_FORM_ENUM.TEXT],
      title: form[NEWS_FORM_ENUM.TITLE],
      categories: form[NEWS_FORM_ENUM.CATEGORIES],
      publishFrom: prepareDateAndTime(
        form[NEWS_FORM_ENUM.PUBLISH_FROM_DATE],
        form[NEWS_FORM_ENUM.PUBLISH_FROM_TIME],
      ),
      isPublish: !!form[NEWS_FORM_ENUM.PUBLISH],
    };

    if (form[NEWS_FORM_ENUM.COVER]) {
      body.cover = form[NEWS_FORM_ENUM.COVER];
    }
    createArticle(body).then(handleCreate);
  };

  return (
    <WithOverflow>
      <ContentHeader showBorder align="flex-start">
        <ContentHeader.Title goBackLink={NEWS_ROUTE}>{t('news_add')}</ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="ok" htmlType="submit" form={NEWS_FORM_NAME}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>

      <div className={s.formWrapper}>
        <NewsForm handleReset={handleReset} handleSubmit={handleSubmit} />
        <div className={s.actionsFooter}>
          <Button
            leftIcon="ok"
            fullWidth
            type="primary"
            htmlType="submit"
            form={NEWS_FORM_NAME}
            loading={createArticleIsFetching}>
            {t('common_save')}
          </Button>
          <Button type="secondary" fullWidth htmlType="reset" form={NEWS_FORM_NAME}>
            {t('common_close')}
          </Button>
        </div>
      </div>
    </WithOverflow>
  );
};

export default NewsAdd;
