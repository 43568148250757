import { useEffect } from 'react';

import dayjs from 'dayjs';

import { useGetCommunityMeetByIdQuery } from 'services/communities/communitiesApiService';

import { FormInstance } from 'gazprom-ui-lib';

import { CommunityMeetsFormResponse } from 'containers/community-meet-form/communityMeetForm.utils';

interface Props {
  communityId: string;
  meetingId: string;
  form: FormInstance<CommunityMeetsFormResponse>;
}

export const useFormData = (props: Props) => {
  const { communityId, meetingId, form } = props;

  const {
    data: communityMeet,
    isFetching,
    isLoading,
  } = useGetCommunityMeetByIdQuery({
    communityId,
    meetingId,
  });

  useEffect(() => {
    if (communityMeet) {
      form.setFieldsValue({
        TITLE: communityMeet.title,
        DESCRIPTION: communityMeet.text,
        COVER: { ...communityMeet.cover },
        CATEGORIES: communityMeet.categories.length
          ? communityMeet.categories.map(({ id }) => id)
          : [],
        EVENT_DATE: dayjs(communityMeet.meetDate),
        EVENT_TIME: dayjs(communityMeet.meetDate),
        REPEAT_INTERVAL: communityMeet.repeatEvery,
        WEEK_DAYS: communityMeet.weekDays?.days,
        CITY: communityMeet.city,
        LOCATION: communityMeet.location,
        PARTICIPANTS: communityMeet.maxEmployees,
        UNLIMITED_PARTICIPATION: communityMeet.maxEmployees === 0,
        REGISTRATION_AVAILABLE: communityMeet.registrationActive,
        DOCS: communityMeet.requiresDocs,
        SPECIAL_DATE: communityMeet.isSpecial,
        IS_PUBLISH: communityMeet.isPublish,
      });
    }
  }, [communityMeet, form]);

  const isLoadingCommunityMeet = isFetching || isLoading;

  return { communityMeet, isLoadingCommunityMeet };
};
