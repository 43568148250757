import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, TextArea } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

interface Props {
  maxLength?: number;
}

const Title = (props: Props) => {
  const { maxLength = 255 } = props;
  return (
    <Form.Item
      label={FORM_LABELS[EVENT_FORM_ENUM.TITLE]}
      normalize={normalizeTextField}
      name={EVENT_FORM_ENUM.TITLE}
      rules={[formRules.required, { max: maxLength }]}>
      <TextArea placeholder={t('common_enter_text')} maxLength={maxLength} showCount />
    </Form.Item>
  );
};

export default Title;
