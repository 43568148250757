import { useNavigate, useParams } from 'react-router-dom';
import { getCommunityManagementViewByIdRoute } from 'routes/communities/list';

import { useCreateCommunityMeetMutation } from 'services/communities/communitiesApiService';

import { handleRequest } from 'gazprom-ui-lib';

import {
  CommunityMeetsFormResponse,
  prepareCommunityMeetFormPayload,
} from 'containers/community-meet-form/communityMeetForm.utils';

export const useHandleSubmit = () => {
  const navigate = useNavigate();
  const { id: communityId } = useParams() as {
    id: string;
  };

  const [createCommunityMeet, { isLoading }] = useCreateCommunityMeetMutation();

  const handleNavigate = () => navigate(getCommunityManagementViewByIdRoute(communityId));

  const handleFinish = (formResponse: CommunityMeetsFormResponse) => {
    const payload = prepareCommunityMeetFormPayload(formResponse);

    if (communityId) {
      createCommunityMeet({
        communityId,
        payload,
      }).then(handleRequest({ onSuccess: handleNavigate }));
    }
  };

  return { handleFinish, isCreatingCommunityMeet: isLoading };
};
