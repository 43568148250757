import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { ColumnsType, Flex, Icon, Typography } from 'gazprom-ui-lib';

import KedoStatusTag from 'components/kedo-status-tag';
import TableDate from 'components/table/table-date';
import TablePerson from 'components/table/table-person';

import { CurrentKedoStatusType } from 'types/documents.types';
import { TaskAssignmentType } from 'types/taskAssignment.types';

const useColumns = (): ColumnsType<TaskAssignmentType> => {
  const employeeRenderer = (employee: TaskAssignmentType['employee']) => {
    const { person, employer } = employee || {};
    const { firstName, lastName } = person || {};
    const { name } = employer || {};

    return <TablePerson firstName={firstName} lastName={lastName} department={name} />;
  };

  const titleRenderer = (title: TaskAssignmentType['title']) => {
    return (
      <Typography.Text size="12" weight="500">
        {title}
      </Typography.Text>
    );
  };
  const statusRenderer = (currentKedoStatus: TaskAssignmentType['status']) => {
    const { code, description, name, state } = currentKedoStatus;

    const currentKedoStatusImitation: CurrentKedoStatusType = {
      comment: null,
      createdAt: null,
      description,
      state,
      status: code,
      title: name,
      titleAlt: null,
    };

    return <KedoStatusTag currentKedoStatus={currentKedoStatusImitation} size="24" />;
  };

  const dateRenderer = (date: TaskAssignmentType['createdAt']) => <TableDate date={date} />;

  const deadlineRenderer = (date: TaskAssignmentType['deadlineAt']) => {
    const isGreaterThanCurrent = dayjs().isAfter(dayjs(date));

    return (
      <Flex>
        {isGreaterThanCurrent && <Icon name="attention" color="var(--color-error)" />}
        <TableDate date={date} danger={isGreaterThanCurrent} />
      </Flex>
    );
  };

  const completeDateRenderer = (
    date: TaskAssignmentType['finishDate'],
    row: TaskAssignmentType,
  ) => {
    const isGreaterThanPlanned = dayjs(dayjs(date)).isAfter(row.deadlineAt);

    return <TableDate date={date} danger={isGreaterThanPlanned} />;
  };

  return [
    {
      title: t('common_employee'),
      dataIndex: 'employee',
      render: employeeRenderer,
    },
    {
      title: t('common_document_name'),
      dataIndex: 'title',
      render: titleRenderer,
    },
    {
      title: t('common_status'),
      dataIndex: 'status',
      width: 210,
      render: statusRenderer,
    },
    {
      title: t('common_created'),
      dataIndex: 'createdAt',
      align: 'center',
      render: dateRenderer,
      width: 130,
      sorter: true,
      defaultSortOrder: 'descend',
    },
    {
      title: t('common_complete_due'),
      dataIndex: 'deadlineAt',
      align: 'center',
      render: deadlineRenderer,
      width: 150,
      sorter: true,
    },
    {
      title: t('common_completed'),
      dataIndex: 'finishDate',
      align: 'center',
      render: completeDateRenderer,
      width: 130,
      sorter: true,
    },
  ];
};

export default useColumns;
