import {
  BaseQueryApi,
  BaseQueryExtraOptions,
  BaseQueryFn,
} from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { userManager } from 'utils/keycloak';

import {
  BACKEND_1C_BASE_URL,
  BACKEND_1C_V2_BASE_URL,
  BACKEND_V1_BASE_URL,
  BACKEND_V2_BASE_URL,
} from 'constants/apiUrls';

const AUTH_ERROR_CODES = [401];
const IGNORE_ENDPOINTS = ['getWorkerEmployees'];

export const baseQueryWithReauth =
  (config: FetchBaseQueryArgs) =>
  async (
    args: FetchArgs | string,
    api: BaseQueryApi,
    extraOptions: BaseQueryExtraOptions<BaseQueryFn>,
  ) => {
    let result = await fetchBaseQuery(config)(args, api, extraOptions);

    if (result.error && AUTH_ERROR_CODES.includes(result.error.status as number)) {
      try {
        await userManager.signinSilent();
        result = await fetchBaseQuery(config)(args, api, extraOptions);
      } catch (e) {
        await userManager.signoutSilent();
      }
    }

    return result;
  };

export const BASE_QUERY_WITH_AUTH = baseQueryWithReauth({
  baseUrl: BACKEND_V1_BASE_URL,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders: async (headers, { endpoint }) => {
    const user = await userManager.getUser();
    const token = user?.access_token;
    const myEmployeeId = localStorage.getItem('selectedEmployee');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (myEmployeeId && !IGNORE_ENDPOINTS.includes(endpoint)) {
      headers.set('X-My-Employee-Id', JSON.parse(myEmployeeId));
    }

    return headers;
  },
});

export const C_BASE_QUERY_WITH_AUTH = baseQueryWithReauth({
  baseUrl: BACKEND_1C_BASE_URL,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders: async (headers, { endpoint }) => {
    const user = await userManager.getUser();
    const token = user?.access_token;
    const myEmployeeId = localStorage.getItem('selectedEmployee');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (myEmployeeId && !IGNORE_ENDPOINTS.includes(endpoint)) {
      headers.set('X-My-Employee-Id', JSON.parse(myEmployeeId));
    }

    return headers;
  },
});

export const C_BASE_QUERY_WITH_AUTH_V2 = baseQueryWithReauth({
  baseUrl: BACKEND_1C_V2_BASE_URL,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders: async (headers, { endpoint }) => {
    const user = await userManager.getUser();
    const token = user?.access_token;
    const myEmployeeId = localStorage.getItem('selectedEmployee');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (myEmployeeId && !IGNORE_ENDPOINTS.includes(endpoint)) {
      headers.set('X-My-Employee-Id', JSON.parse(myEmployeeId));
    }

    return headers;
  },
});

export const BASE_QUERY_WITH_AUTH_V2 = baseQueryWithReauth({
  baseUrl: BACKEND_V2_BASE_URL,
  credentials: 'include',
  mode: 'cors',
  prepareHeaders: async (headers, { endpoint }) => {
    const user = await userManager.getUser();
    const token = user?.access_token;
    const myEmployeeId = localStorage.getItem('selectedEmployee');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (myEmployeeId && !IGNORE_ENDPOINTS.includes(endpoint)) {
      headers.set('X-My-Employee-Id', JSON.parse(myEmployeeId));
    }

    return headers;
  },
});

export const downloadFileUsingRtk =
  (filename: string, format?: string) => async (response: Response) => {
    const formatToUse = format ?? 'xlsx';

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${filename}.${formatToUse}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

type ApiVersions = 'v1' | 'v2' | 'v3' | 'v4' | 'v5';
export interface CreateUrlProps {
  url: string;
  isPrivate?: boolean;
  apiVersion?: ApiVersions;
}
