import { PropsWithChildren } from 'react';

import cn from 'classnames';

import { Flex } from 'gazprom-ui-lib';

import s from './DocumentView.module.scss';

interface Props extends PropsWithChildren {
  className?: string;
  isError?: boolean;
}

const DocumentView = (props: Props) => {
  const { className, children } = props;

  return (
    <Flex vertical gap="24" className={cn(s.wrapper, className)}>
      {children}
    </Flex>
  );
};

export default DocumentView;
