import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

interface Props {
  sendUsersCount: number | null;
  onCancel: () => void;
}

const AccessCodeAllSentModal = (props: Props) => {
  const { sendUsersCount, onCancel } = props;

  return (
    <ModalDefault open={!!sendUsersCount} closable width={320} onCancel={onCancel}>
      <ModalDefault.Icon name="ok" type="success" />
      <ModalDefault.Title>{t('common_successfully_sent')}</ModalDefault.Title>
      <ModalDefault.Description>
        {t('common_access_code_all_send_description', { numOfUsersSent: sendUsersCount ?? 0 })}
      </ModalDefault.Description>
      <ModalDefault.Actions>
        <Button type="primary" onClick={onCancel}>
          {t('common_ok')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default AccessCodeAllSentModal;
