export enum TABS_KEYS {
  ATTACHED = 'attached',
  ADDITIONAL = 'additional',
}

export const getFileUrl = (id: string, fileKey: string) =>
  `${process.env.https://admin-gateway-test.dev.tockl.ru/api/v1/}kedo-file/assignment/${id}/download?fileKey=${fileKey}`;

export const ORDER_FILE_TYPES = [
  'ORDER',
  'CONTRACT',
  'FILE_FOR_SIGN',
  'SIGN_TWO_SIDES',
  'CONTRACT_APPLICATION',
  'ADD_CONTRACT',
];

export const REJECTED_STATUSES = ['REJECTED', 'REVISION', 'CANCELED'];
