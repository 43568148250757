import { t } from 'tools/i18n';

import { Checkbox, Form, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_NEWS_FORM_ENUM } from '../../form.utils';

const Publication = () => {
  return (
    <Form.Item name={COMMUNITY_NEWS_FORM_ENUM.PUBLISH} valuePropName="checked">
      <Checkbox>
        <Typography.Text size="14">{t('common_published')}</Typography.Text>
      </Checkbox>
    </Form.Item>
  );
};

export default Publication;
