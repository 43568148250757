import { useNavigate } from 'react-router-dom';
import { getDocumentsMachineAuthoritiesWithIdRoute } from 'routes/documents/list';
import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import FiltersV2 from 'containers/filters-v2';
import WithOverflow from 'containers/wrappers/with-overflow';

import { MachineAuthorityType } from 'types/machineAuthorities.types';

import s from './DocumentsMachineAuthorities.module.scss';
import {
  DEFAULT_FORM_STATE,
  DEFAULT_SORT,
  FormResponseType,
} from './documentsMachineAuthorities.utils';
import useColumns from './useColumns.hook';
import useGetData from './useGetData.hook';

const DocumentsMachineAuthorities = () => {
  const [form] = Form.useForm<FormResponseType>();
  const columns = useColumns();
  const navigate = useNavigate();

  const onRow = (request: MachineAuthorityType) => ({
    onClick: () => navigate(getDocumentsMachineAuthoritiesWithIdRoute(request.id)),
  });

  const { powersOfAttorney, powersOfAttorneyFetching } = useGetData({ form });

  return (
    <WithOverflow>
      <ContentHeader>
        <ContentHeader.Title>{t('documents_machine_authorities')}</ContentHeader.Title>
      </ContentHeader>
      <FiltersV2 form={form} initialValues={DEFAULT_FORM_STATE}>
        <FiltersV2.TableFilters>
          <FiltersV2.Search />
        </FiltersV2.TableFilters>
        <FiltersV2.Table<MachineAuthorityType, FormResponseType>
          fallbackSort={DEFAULT_SORT}
          className={s.tableWrapper}
          dataSource={powersOfAttorney?.items}
          loading={powersOfAttorneyFetching}
          columns={columns}
          rowKey="id"
          onRow={onRow}
          total={0}
        />
      </FiltersV2>
    </WithOverflow>
  );
};

export default DocumentsMachineAuthorities;
