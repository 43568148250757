import { useNavigate, useParams } from 'react-router-dom';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import {
  useCreateDeclarationTypesMutation,
  useUpdateDeclarationTypesMutation,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService';
import {
  CreateDeclarationTypesPropsType,
  UpdateDeclarationTypesPropsType,
} from 'services/documents/documents-declaration-types/documentsDeclarationTypesApiService.types';

import { handleRequest } from 'gazprom-ui-lib';

import { CommonDocumentsFormResponse } from 'containers/documents-declarations-form/documentsDeclarationsForm.utils';

import { DeclarationsTypes } from 'types/documents.types';
import { KEDO_TYPE_ENUM } from 'types/kedo.types';

interface UseFormSubmitHandlerHookProps {
  createRoute: string;
  type?: KEDO_TYPE_ENUM;
}

const useCommonFormSubmitHandler = (props: UseFormSubmitHandlerHookProps) => {
  const { createRoute, type } = props;

  const { id } = useParams<{
    id: string;
  }>();
  const navigate = useNavigate();

  const [createDeclaration, { isLoading: createIsLoading }] = useCreateDeclarationTypesMutation();
  const [updateDeclaration, { isLoading: updateIsLoading }] = useUpdateDeclarationTypesMutation();

  const handleModalUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleUpdate = (params: UpdateDeclarationTypesPropsType) => {
    updateDeclaration(params).then(handleModalUpdate);
  };

  const handleCreate = (body: CreateDeclarationTypesPropsType) => {
    createDeclaration(body).then(
      handleRequest({
        onSuccess: (data?: DeclarationsTypes) => {
          if (data) {
            navigate(`${createRoute}/${data?.id}?popover=true`, { replace: true });
          }
        },
        onError: () => Promise.reject(new Error()),
      }),
    );
  };

  const getRequestBody = (formValues: CommonDocumentsFormResponse) => {
    if (!type) {
      return null;
    }

    if (id) {
      const requestBody: UpdateDeclarationTypesPropsType = {
        id,
        body: {
          type,
          ...formValues,
        },
      };

      return requestBody;
    }

    const requestBody: CreateDeclarationTypesPropsType = {
      type,
      ...formValues,
    };

    return requestBody;
  };

  return {
    isLoading: createIsLoading || updateIsLoading,
    handleFormSubmit: (formValues: CommonDocumentsFormResponse) => {
      const requestBody = getRequestBody(formValues);

      const mutation = id
        ? () => handleUpdate(requestBody as UpdateDeclarationTypesPropsType)
        : () => handleCreate(requestBody as CreateDeclarationTypesPropsType);

      mutation();
    },
  };
};

export default useCommonFormSubmitHandler;
