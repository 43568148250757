import { useEffect } from 'react';

import { t } from 'tools/i18n';
import { formRules } from 'utils/formRules';

import { Checkbox, Form, Input, Typography } from 'gazprom-ui-lib';

import { COMMUNITY_MEETS_FORM_ENUM } from 'containers/community-meet-form/communityMeetForm.utils';

import s from './Participants.module.scss';

const Participants = () => {
  const form = Form.useFormInstance();

  const isUnlimitedParticipation = Form.useWatch(COMMUNITY_MEETS_FORM_ENUM.UNLIMITED_PARTICIPATION);
  const participants = Form.useWatch(COMMUNITY_MEETS_FORM_ENUM.PARTICIPANTS);
  const hasRepeatEvery = !!Form.useWatch(COMMUNITY_MEETS_FORM_ENUM.REPEAT_INTERVAL, form);

  const handleCheckboxNormalize = (flag: boolean) => {
    if (flag) {
      form.setFields([
        { name: COMMUNITY_MEETS_FORM_ENUM.PARTICIPANTS, errors: [], value: participants },
      ]);
    }

    return flag;
  };

  const handleInputNormalize = (counter: string) => {
    return Number(counter) > 0 ? counter : 1;
  };

  useEffect(() => {
    if (hasRepeatEvery) {
      form.setFieldValue(COMMUNITY_MEETS_FORM_ENUM.UNLIMITED_PARTICIPATION, true);
    }
  }, [hasRepeatEvery, form]);

  return (
    <>
      <Form.Item
        label={t('events_participants')}
        className={s.participants}
        name={COMMUNITY_MEETS_FORM_ENUM.PARTICIPANTS}
        rules={isUnlimitedParticipation ? undefined : [formRules.required]}
        normalize={handleInputNormalize}>
        <Input
          placeholder={t('common_enter_number')}
          type="number"
          min={1}
          step={1}
          disabled={isUnlimitedParticipation || hasRepeatEvery}
        />
      </Form.Item>
      <Form.Item
        name={COMMUNITY_MEETS_FORM_ENUM.UNLIMITED_PARTICIPATION}
        valuePropName="checked"
        normalize={handleCheckboxNormalize}>
        <Checkbox disabled={hasRepeatEvery}>
          <Typography.Text size="14">{t('events_unlimited_participation')}</Typography.Text>
        </Checkbox>
      </Form.Item>
    </>
  );
};

export default Participants;
