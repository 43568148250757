import { PropsWithChildren } from 'react';

import { Flex, FlexProps } from 'gazprom-ui-lib';

interface Props extends PropsWithChildren, FlexProps {}

const ContentHeaderActions = (props: Props) => {
  const { children, gap = '12', ...otherProps } = props;

  return (
    <Flex gap={gap} justify="center" align="center" {...otherProps}>
      {children}
    </Flex>
  );
};

export default ContentHeaderActions;
