import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { t } from 'tools/i18n';
import { handleTableChange } from 'utils/table.utils';
import useHandleRequestWithModal from 'utils/useHandleRequestWithModal.hook';

import { UpdateCInstancePropsType } from 'services/1c-instances/cInstancesService.types';
import { GetSynclogByIdResponseType } from 'services/synclog/synclogService.types';

import CInstanceForm from 'containers/c-instance-form';
import {
  CInstanceFormResponse,
  C_INSTANCE_FORM_ENUM,
} from 'containers/c-instance-form/cInstanceForm.utils';

import { CInstanceType } from 'types/cInstances.types';

import s from './Settings1cInstanceDetails.module.scss';
import ImportedOrganizationsTable from './imported-organizations-table';
import {
  C_INSTANCE_IMPORTED_ORGANIZATIONS_TAB,
  C_INSTANCE_SETTINGS_TAB,
  C_INSTANCE_SYNCHRONIZATION_HISTORY_TAB,
  RequestBody,
} from './settings1cInstanceDetails.utils';
import Settings1cInstanceSyncHistory from './settings-1c-instance-sync-history';

interface UseTabItemsHook {
  synclogData?: GetSynclogByIdResponseType;
  isSynclogFetching: boolean;
  cInstance?: CInstanceType;
  handleGoBack: () => void;
  updateCInstance: (requestBody: UpdateCInstancePropsType) => Promise<
    | { data: CInstanceType }
    | {
        error: FetchBaseQueryError | SerializedError;
      }
  >;
  requestBody: RequestBody;
  setRequestBody: Dispatch<SetStateAction<RequestBody>>;
}

const useTabItemsHook = (props: UseTabItemsHook) => {
  const {
    synclogData,
    isSynclogFetching,
    requestBody,
    setRequestBody,
    cInstance,
    updateCInstance,
  } = props;

  const handleUpdate = useHandleRequestWithModal({
    onSuccessModalConfig: {},
  });

  const handleEdit = useCallback(
    (form: CInstanceFormResponse) => {
      if (cInstance?.id) {
        const requestBody: UpdateCInstancePropsType = {
          id: cInstance?.id,
          name: cInstance.name,
          url: form[C_INSTANCE_FORM_ENUM.URL],
          login: form[C_INSTANCE_FORM_ENUM.LOGIN],
          password: form[C_INSTANCE_FORM_ENUM.PASSWORD],
          active: form[C_INSTANCE_FORM_ENUM.ACTIVE],
          syncTypes: cInstance.syncTypes,
          structureType: form[C_INSTANCE_FORM_ENUM.STRUCTURE_TYPE],
        };

        updateCInstance(requestBody).then(handleUpdate);
      }
    },
    [cInstance?.id, cInstance?.name, cInstance?.syncTypes, handleUpdate, updateCInstance],
  );

  return useMemo(
    () => [
      {
        label: t('1c_synchronization_settings'),
        key: C_INSTANCE_SETTINGS_TAB,
        children: (
          <div className={s.contentSettings}>
            <CInstanceForm onSubmit={handleEdit} cInstance={cInstance} />
          </div>
        ),
      },
      {
        label: t('1c_imported_organizations'),
        key: C_INSTANCE_IMPORTED_ORGANIZATIONS_TAB,
        children: <ImportedOrganizationsTable cInstance={cInstance} />,
      },
      {
        label: t('1c_synchronization_history'),
        key: C_INSTANCE_SYNCHRONIZATION_HISTORY_TAB,
        children: (
          <div>
            <Settings1cInstanceSyncHistory
              onChange={handleTableChange(setRequestBody)}
              paginationConfig={{
                current: requestBody.page,
                pageSize: requestBody.size,
                total: synclogData?.totalCount,
              }}
              dataSource={synclogData?.synclogs}
              loading={isSynclogFetching}
            />
          </div>
        ),
      },
    ],
    [cInstance, handleEdit, isSynclogFetching, requestBody, setRequestBody, synclogData],
  );
};

export default useTabItemsHook;
