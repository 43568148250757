import { useUpdateCommunityMeetMutation } from 'services/communities/communitiesApiService';

import {
  CommunityMeetsFormResponse,
  prepareCommunityMeetFormPayload,
} from 'containers/community-meet-form/communityMeetForm.utils';

interface Props {
  communityId: string;
  meetingId: string;
}

export const useHandleFinish = (props: Props) => {
  const { communityId, meetingId } = props;

  const [updateCommunityMeet, { isLoading }] = useUpdateCommunityMeetMutation();

  const handleFinish = (formResponse: CommunityMeetsFormResponse) => {
    const payload = prepareCommunityMeetFormPayload(formResponse);

    updateCommunityMeet({ communityId, meetingId, payload });
  };

  return { handleFinish, isUpdatingCommunityMeet: isLoading };
};
