import { t } from 'tools/i18n';

import { Form } from 'gazprom-ui-lib';

import CommunityArticleCategories from 'containers/community-article-categories';
import CommunityManagementFormMedia from 'containers/community-management-form/community-management-form-media';

import s from './Form.module.scss';
import Publication from './containers/publication';
import Text from './containers/text';
import Title from './containers/title';
import { COMMUNITY_NEWS_FORM_ENUM } from './form.utils';

const NewsForm = () => {
  return (
    <div className={s.container}>
      <Title />
      <Text />
      <CommunityManagementFormMedia
        fieldName={COMMUNITY_NEWS_FORM_ENUM.COVER}
        label={t('common_cover')}
      />
      <CommunityArticleCategories formFieldKey={COMMUNITY_NEWS_FORM_ENUM.CATEGORIES} />
      <Publication />
      {/* Поле для того, чтобы сеттить поле status, когда работаем с suggested */}
      <Form.Item name={COMMUNITY_NEWS_FORM_ENUM.STATUS} hidden />
    </div>
  );
};

export default NewsForm;
