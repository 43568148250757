import { useParams } from 'react-router-dom';
import { t } from 'tools/i18n';

import { useGetTaskByIdQuery } from 'services/1c/cApiService';
import { LeaderType } from 'services/1c/cApiService.types';

import { Flex, Typography } from 'gazprom-ui-lib';

import DocumentsViewPerson from '../documents-view-person';
import s from './DocumentsViewParticipants.module.scss';
import ChangeParticipant from './containers/change-participant';
import { getParticipantStatusConfig } from './documentsViewParticipants.utils';

interface Props {
  data: LeaderType;
  shouldRenderChangeButton?: boolean;
}

const DocumentsViewParticipants = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetTaskByIdQuery(id!);

  const { approvers, currentKedoStatus, employee } = data ?? {};
  const { firstLeader, functionalLeader, leaders, check1cExecutors, prepare1cExecutors, signer } =
    approvers ?? {};
  const { state } = currentKedoStatus ?? {};
  const isFinalStatus = state === 'done' || state === 'error' || state === 'continue';

  const renderLeader = (props: Props) => {
    const { shouldRenderChangeButton = true, data } = props;
    const { date, type, icon } = getParticipantStatusConfig(data);
    const isChangeButtonVisible =
      !isFinalStatus &&
      (data.status === 'WAITING' || data.status === null) &&
      shouldRenderChangeButton;

    return (
      <Flex justify="space-between">
        <DocumentsViewPerson
          date={date}
          type={type}
          icon={icon}
          key={data.employeeId}
          firstName={data.person.firstName}
          lastName={data.person.lastName}
          position={data.position}
          employeeId={data.employeeId}
        />
        {isChangeButtonVisible && (
          <ChangeParticipant employerId={employee?.employer.id} executorId={data.executorId} />
        )}
      </Flex>
    );
  };

  return (
    <>
      {firstLeader && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_first_leader')}</Typography.Title>
          {renderLeader({ data: firstLeader })}
        </div>
      )}
      {!!check1cExecutors?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_check1c_executors')}</Typography.Title>
          {check1cExecutors.map((executor) =>
            renderLeader({ data: executor, shouldRenderChangeButton: false }),
          )}
        </div>
      )}
      {!!leaders?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_common_leaders')}</Typography.Title>
          {leaders.map((leader) => renderLeader({ data: leader }))}
        </div>
      )}
      {functionalLeader && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_functional_leaders')}</Typography.Title>
          {renderLeader({ data: functionalLeader })}
        </div>
      )}
      {!!prepare1cExecutors?.length && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_prepare1c_executors')}</Typography.Title>
          {prepare1cExecutors.map((executor) =>
            renderLeader({ data: executor, shouldRenderChangeButton: false }),
          )}
        </div>
      )}
      {signer && (
        <div className={s.leadersContainer}>
          <Typography.Title level={5}>{t('documents_view_signer_leaders')}</Typography.Title>
          {renderLeader({ data: signer })}
        </div>
      )}
    </>
  );
};

export default DocumentsViewParticipants;
