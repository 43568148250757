import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { sortDocumentsFiles } from 'utils/files.utils';

import { useGetTaskByIdQuery } from 'services/1c/cApiService';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import FileDownload from 'pages/documents/containers/file-download';
import FileInfo from 'pages/documents/containers/file-info';
import { getFileKey, renderSign } from 'pages/documents/documents.utils';

import { CONTENT_DISPOSITION_HEADER } from 'constants/headerNames';
import { FileType } from 'types/file.types';

import s from './DocumentsViewFiles.module.scss';
import FileDownloadButton from './containers';
import { findKey, getArchiveUrl, getFileUrl } from './documentsViewFiles.utils';

const DocumentsViewFiles = () => {
  const { id } = useParams();
  const { data } = useGetTaskByIdQuery(id!);

  if (!data?.files.length) {
    return null;
  }

  const isTaskEmployerCanceled = data?.currentKedoStatus?.status === 'CANCEL_EMPLOYER';

  const renderFile = (file: FileType) => {
    const { fileKey, fileKeyWithStamp, fileName, signs, type, dateDoc, numberDocPrint } = file;
    const isSigned = !!signs?.length;
    const isOrderType = type === 'ORDER';

    const fileKeyFinal = getFileKey({ fileKey, fileKeyWithStamp });

    const archiveUrl = getArchiveUrl(id!, fileKey);
    const fileDownloadUrl = getFileUrl(id!, fileKeyFinal);

    const chiefSign = isOrderType && signs.find(findKey(data?.employee.person.id ?? ''));

    const addSpace = isOrderType && chiefSign;

    const renderSigns = () => {
      return signs?.map(renderSign);
    };

    const shouldRenderButton = file.type !== null && !isTaskEmployerCanceled;
    const shouldDisableButton = file.type !== null && !isSigned;

    return (
      <>
        <Flex
          justify="space-between"
          align="center"
          gap="12"
          className={cn(s.download, { [s.signed]: isSigned })}>
          <Flex align="center" gap="12">
            <Icon name="docTypeEmpty" className={s.icon} />
            <Flex vertical gap="4" className={s.fileDescription}>
              <Typography.Text weight="500" size="14" type={isSigned ? 'success' : 'primary'}>
                {fileName}
              </Typography.Text>
              <FileInfo
                dateDoc={dateDoc}
                numberDocPrint={numberDocPrint}
                isOrderType={isOrderType}
              />
              {renderSigns()}
            </Flex>
          </Flex>
          {!isTaskEmployerCanceled && (
            <FileDownload
              isSigned={isSigned}
              fileName={fileName}
              fileDownloadUrl={fileDownloadUrl}
              headersFileNameKey={CONTENT_DISPOSITION_HEADER}
            />
          )}
        </Flex>
        {shouldRenderButton && (
          <FileDownloadButton
            archiveUrl={archiveUrl}
            shouldDisableButton={shouldDisableButton}
            addSpace={!!addSpace}
          />
        )}
      </>
    );
  };

  return (
    <div className={s.fileWrapper}>{[...data.files].sort(sortDocumentsFiles).map(renderFile)}</div>
  );
};

export default DocumentsViewFiles;
