import { ReactNode } from 'react';

import s from './EventFormTarget.module.scss';
import Gender from './containers/gender';
import Groups from './containers/groups';
import Notification from './containers/notification';

interface Props {
  actions?: ReactNode;
  disabled?: boolean;
  treeIsLoading?: boolean;
}
const EventFormTarget = (props: Props) => {
  const { actions, disabled, treeIsLoading } = props;

  return (
    <div>
      <div className={s.wrapper}>
        <Notification disabled={disabled} />
        <Gender disabled={disabled} />
      </div>
      <Groups disabled={disabled} treeIsLoading={treeIsLoading} />
      {actions}
    </div>
  );
};

export default EventFormTarget;
