import { useEffect } from 'react';

import dayjs from 'dayjs';
import { useDebounce } from 'utils/useDebounce.hook';

import { useLazyGetTaskAssignmentsQuery } from 'services/1c/cApiService';
import { GetTaskAssignmentsRequestType } from 'services/1c/cApiService.types';

import { Form, FormInstance } from 'gazprom-ui-lib';

import { TASK_ASSIGNMENT_TYPE_ENUM } from 'types/taskAssignment.types';

import { FormResponseType } from './documentsSigns.utils';

interface Props {
  form: FormInstance<FormResponseType>;
}

const useGetData = (props: Props) => {
  const { form } = props;

  const formData: FormResponseType | undefined = Form.useWatch([], form);

  const [getTaskAssignments, { data, isFetching, isLoading, isUninitialized }] =
    useLazyGetTaskAssignmentsQuery();

  const { debouncedValue: debouncedFormData, isDebouncing } = useDebounce(formData);

  useEffect(() => {
    if (debouncedFormData) {
      const { filters, range, order, property } = debouncedFormData;

      let modifiedFilters: Pick<GetTaskAssignmentsRequestType, 'statuses'> = {};

      if (filters?.statuses?.length) {
        modifiedFilters.statuses = [];

        if (filters.statuses.includes('pending')) {
          modifiedFilters.statuses.push('WAITING');
        }

        if (filters.statuses.includes('executed')) {
          modifiedFilters.statuses.push('APPROVED');
          modifiedFilters.statuses.push('REJECTED');
        }
      }

      const initialRequestBody: GetTaskAssignmentsRequestType = {
        ...modifiedFilters,
        page: debouncedFormData.page,
        size: debouncedFormData.size,
        types: [
          TASK_ASSIGNMENT_TYPE_ENUM.EMPLOYMENT_SIGN_HEAD,
          TASK_ASSIGNMENT_TYPE_ENUM.SIGN_HEAD,
          TASK_ASSIGNMENT_TYPE_ENUM.SIGN_HEAD_NO_REFUSE,
          TASK_ASSIGNMENT_TYPE_ENUM.CANCEL_ORG_REQUEST,
        ],
        onlyActive: true,
      };

      if (order && property) {
        initialRequestBody.sort = [{ order, property }];
      }

      if (range) {
        initialRequestBody.from = dayjs.utc(range[0]).format();
        initialRequestBody.to = dayjs.utc(range[1]).format();
      }

      getTaskAssignments(initialRequestBody);
    }
  }, [debouncedFormData, getTaskAssignments]);

  return {
    data,
    isFetching: isDebouncing || isFetching,
    isLoading: isUninitialized || isLoading,
  };
};

export default useGetData;
