import { Flex } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

const Filters = () => {
  return (
    <FiltersV2.TableFilters>
      <Flex align="items">
        <FiltersV2.Search />
      </Flex>
    </FiltersV2.TableFilters>
  );
};

export default Filters;
