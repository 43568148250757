import { t } from 'tools/i18n';

import FileField from 'containers/file-field';
import { HANDBOOK_INSTRUCTION_ENUM } from 'containers/handbook-instruction-form/handbookInstructionForm.utils';

const ImageFile = () => {
  return (
    <FileField
      maxSize={20}
      availableTypes={AVAILABLE_TYPES}
      formItemConfig={{
        label: t('common_file'),
        name: HANDBOOK_INSTRUCTION_ENUM.FILE,
      }}
      previewConfig={{
        showDelete: true,
        showDownload: true,
      }}
      uploadConfig={{
        description: t('validation_upload_an_image_restrictions_guide'),
      }}
    />
  );
};

const AVAILABLE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];

export default ImageFile;
