import { t } from 'tools/i18n';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import { CommunityManagementSubscribersEnumType } from '../tabs/tabs.utils';
import { useGetFiltersData } from './useGetFiltersData.hook';

type UseFilterItemsProps = {
  tabType: CommunityManagementSubscribersEnumType;
};

export const useFilterItems = ({ tabType }: UseFilterItemsProps) => {
  const { subscribersDivisions, subscribersEmployers } = useGetFiltersData();

  const filterItems = [
    {
      key: 'employerIds',
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          optionFilterProp="label"
          showSearch
          options={subscribersEmployers}
          searchProps={{
            placeholder: t('common_search'),
          }}
        />
      ),
    },
    {
      key: 'divisionIds',
      label: <FilterHeader valueKey="divisionIds">{t('common_department')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          showSearch
          optionFilterProp="label"
          valueKey="divisionIds"
          options={subscribersDivisions}
          searchProps={{
            placeholder: t('common_search'),
          }}
        />
      ),
    },
  ];

  return tabType === 'SUBSCRIBERS' ? filterItems : [filterItems[0]];
};
