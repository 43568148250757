import { t } from 'tools/i18n';
import { formRules, normalizeTextField } from 'utils/formRules';

import { Form, Input, TextArea } from 'gazprom-ui-lib';

import { EVENT_FORM_ENUM, FORM_LABELS } from '../../../eventForm.utils';

interface Props {
  cityLabel?: string;
  cityPlaceholder?: string;
  locationLabel?: string;
}

const Place = (props: Props) => {
  const {
    cityLabel = FORM_LABELS[EVENT_FORM_ENUM.CITY],
    cityPlaceholder = t('common_enter_text'),
    locationLabel = FORM_LABELS[EVENT_FORM_ENUM.LOCATION],
  } = props;
  return (
    <>
      <Form.Item
        name={EVENT_FORM_ENUM.CITY}
        rules={[formRules.required]}
        label={cityLabel}
        normalize={normalizeTextField}>
        <Input placeholder={cityPlaceholder} />
      </Form.Item>
      <Form.Item
        label={locationLabel}
        normalize={normalizeTextField}
        name={EVENT_FORM_ENUM.LOCATION}
        rules={[formRules.required, { max: 120 }]}>
        <TextArea placeholder={t('common_enter_address')} maxLength={120} showCount />
      </Form.Item>
    </>
  );
};

export default Place;
