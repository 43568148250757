import { useNavigate } from 'react-router-dom';
import { getSurveySettlementByIdRoute } from 'routes/surveys/list';
import { useVisibility } from 'utils/useVisibility.hook';

import {
  useCreateSurveySettlementMutation,
  useGetSurveysListQuery,
  usePublishSurveySettlementMutation,
  useUnpublishSurveySettlementMutation,
  useUpdateSurveySettlementMutation,
} from 'services/surveys/surveysApiService';

import { handleRequest } from 'gazprom-ui-lib';

import { SurveySettlementFormResponse } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/containers/survey-settlement-form/surveySettlementForm.utils';

import { SurveySettlementCreateOrUpdateType, SurveysListInfo } from 'types/surveys.types';

import { useGetSurvey } from './useGetSurvey.hook';

export const useFormHandlers = () => {
  const { survey } = useGetSurvey();
  const { isPublish, id: surveyId } = survey || {};

  const isUpdate = !!surveyId;
  const navigate = useNavigate();

  const [createSurvey, { isLoading: isCreating }] = useCreateSurveySettlementMutation();
  const [updateSurvey, { isLoading: isUpdating }] = useUpdateSurveySettlementMutation();

  const [publish, { isLoading: isPublishing }] = usePublishSurveySettlementMutation();
  const [unpublish, { isLoading: isUnpublishing }] = useUnpublishSurveySettlementMutation();

  const [isPublishModalOpen, { open: handleOpenPublishModal, close: handleClosePublishModal }] =
    useVisibility();

  const { data: { surveysInfo = {} } = {} } = useGetSurveysListQuery();

  const handleFinish = (formResponse: SurveySettlementFormResponse) => {
    const payload = preparePayload({ formResponse, surveysInfo });

    if (isUpdate) {
      updateSurvey({ surveyId, payload });
    } else {
      createSurvey(payload).then(
        handleRequest({
          onSuccess: (data) => data?.id && navigate(getSurveySettlementByIdRoute(data.id)),
        }),
      );
    }
  };

  const handlePublish = () => {
    const mutation = isPublish ? unpublish : publish;

    if (surveyId) {
      mutation({ surveyId }).then(handleRequest({ onSuccess: handleClosePublishModal }));
    }
  };

  const isSubmittingForm = isCreating || isUpdating || isPublishing || isUnpublishing;

  return {
    handleFinish,
    handlePublish,
    isSubmittingForm,
    isPublishModalOpen,
    handleOpenPublishModal,
    handleClosePublishModal,
  };
};

interface PreparePayloadProps {
  formResponse: SurveySettlementFormResponse;
  surveysInfo: Record<string, SurveysListInfo>;
}

const preparePayload = (props: PreparePayloadProps): SurveySettlementCreateOrUpdateType => {
  const { formResponse, surveysInfo } = props;

  const { anketologId, notificationInterval, ...rest } = formResponse;
  const anketaData = surveysInfo[anketologId];

  return {
    ...anketaData,
    anketologId,
    ...(notificationInterval && { notificationInterval: Number(notificationInterval) }),
    isPublish: false,
    ...rest,
  };
};
