import { useNavigate } from 'react-router-dom';
import { SURVEYS_SETTLEMENT_ADD_ROUTE } from 'routes/surveys/list';
import { t } from 'tools/i18n';

import { Button } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';

const Header = () => {
  const navigate = useNavigate();
  const handleNavigate = () => navigate(SURVEYS_SETTLEMENT_ADD_ROUTE);

  return (
    <ContentHeader>
      <ContentHeader.Title>{t('surveys_settlement')}</ContentHeader.Title>
      <ContentHeader.Actions>
        <Button onClick={handleNavigate} leftIcon="plus">
          {t('common_add_survey')}
        </Button>
      </ContentHeader.Actions>
    </ContentHeader>
  );
};

export default Header;
