import { ReactNode } from 'react';

import { Form } from 'gazprom-ui-lib';

import WithLoader from 'containers/wrappers/with-loader';

import NotificationInterval from 'pages/surveys/containers/notification-interval';

import s from './SurveySettlementForm.module.scss';
import Anketa from './containers/anketa';
import Settlements from './containers/settlements';
import {
  SURVEY_SETTLEMENT_FORM_NAME,
  SurveySettlementFormResponse,
} from './surveySettlementForm.utils';
import { useGetFormData } from './useGetFormData.hook';
import { useSetFormData } from './useSetFormData.hook';

interface Props {
  handleFinish: (formResponse: SurveySettlementFormResponse) => void;
  formActions: ReactNode;
}

const SurveySettlementForm = (props: Props) => {
  const { handleFinish, formActions } = props;

  const [form] = Form.useForm<SurveySettlementFormResponse>();
  const { isLoading, survey } = useGetFormData();
  useSetFormData({ form });

  return (
    <WithLoader isLoading={isLoading}>
      <Form
        disabled={!!survey?.isPublish}
        className={s.wrapper}
        onFinish={handleFinish}
        form={form}
        name={SURVEY_SETTLEMENT_FORM_NAME}>
        <Anketa />
        <NotificationInterval
          fieldName="notificationInterval"
          initialValue={survey?.notificationInterval}
        />
        <Settlements />

        {formActions}
      </Form>
    </WithLoader>
  );
};

export default SurveySettlementForm;
