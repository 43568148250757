import cn from 'classnames';
import { t } from 'tools/i18n';

import { Icon, IconProps, Tag, TagProps, Typography } from 'gazprom-ui-lib';

import s from './PublishStatus.module.scss';

interface Props {
  isPublish: boolean;
}

const PublishStatus = (props: Props) => {
  const { isPublish } = props;

  const iconName: IconProps['name'] = isPublish ? 'ok' : 'time';
  const type: TagProps['type'] = isPublish ? 'success' : 'neutral';
  const text = t(isPublish ? 'common_published_si' : 'surveys_appointment_waiting');

  return (
    <Tag
      type={type}
      className={cn(s.tag, { [s.published]: isPublish, [s.notPublished]: !isPublish })}>
      <Icon name={iconName} />
      <Typography.Text weight="500" size="14">
        {text}
      </Typography.Text>
    </Tag>
  );
};

export default PublishStatus;
