import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { DOCUMENTS_MACHINE_AUTHORITIES_ROUTE } from 'routes/documents/list';
import { t } from 'tools/i18n';

import {
  useActivatePowerOfAttorneyMutation,
  useDeactivatePowerOfAttorneyMutation,
  useGetPowerOfAttorneyByIdQuery,
} from 'services/power-of-attorney-private/powerOfAttorneyPrivateApiService';

import { Button, Flex, Icon, Switch, SwitchProps, Typography } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import DocumentView from 'containers/document-view';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './DocumentsMachineAuthoritiesDetails.module.scss';

const DocumentsMachineAuthoritiesDetails = () => {
  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(DOCUMENTS_MACHINE_AUTHORITIES_ROUTE);
  };

  const {
    data: machineAuthority,
    isFetching: machineAuthorityIsFetching,
    isError,
    refetch,
  } = useGetPowerOfAttorneyByIdQuery(id ?? skipToken);

  const [activateMachineAuthority, { isLoading: isActivateLoading }] =
    useActivatePowerOfAttorneyMutation();
  const [deactivateMachineAuthority, { isLoading: isDeactivateLoading }] =
    useDeactivatePowerOfAttorneyMutation();

  const handleKedoChange: SwitchProps['onChange'] = async (status) => {
    if (id) {
      if (status) {
        await activateMachineAuthority(id);
      } else {
        await deactivateMachineAuthority(id);
      }
      refetch();
    }
  };

  const {
    principalName,
    principalInn,
    principalKpp,
    principalOgrn,
    representativeInnFl,
    representativeSnils,
    representativeSurname,
    representativeName,
    representativeMiddleName,
    isActive,
  } = machineAuthority ?? {};

  // TODO - implement archive downloading when BE ready
  const handleDownloadContainer = () => {};

  return (
    <WithOverflow>
      <ContentHeader align="flex-start" showBorder>
        <ContentHeader.Title goBackLink={DOCUMENTS_MACHINE_AUTHORITIES_ROUTE}>
          {t('documents_machine_authorities_view')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button leftIcon="download" onClick={handleDownloadContainer}>
            {t('common_download_container')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={machineAuthorityIsFetching}>
        <DocumentView isError={isError}>
          <DocumentView.Content>
            <Flex vertical gap="24" fullWidth>
              <Flex vertical gap="16">
                <Flex gap="8" align="center">
                  <Icon name="info" color="var(--color-primary-6)" />
                  <Typography.Title level={4}>{t('common_information')}</Typography.Title>
                </Flex>
              </Flex>
              <Flex vertical gap="16">
                <Flex gap="8" align="center">
                  <Icon name="work" color="var(--color-primary-6)" />
                  <Typography.Title level={4}>
                    {t('documents_machine_authorities_principal')}
                  </Typography.Title>
                </Flex>
                <Flex vertical>
                  <Typography.Title level={5}>{principalName}</Typography.Title>
                  <Flex gap="4" align="center">
                    <Typography.Title level={5}>{t('common_inn')}: </Typography.Title>
                    <Typography.Text size="14">{principalInn}</Typography.Text>
                  </Flex>
                  <Flex gap="4" align="center">
                    <Typography.Title level={5}>{t('common_kpp')}: </Typography.Title>
                    <Typography.Text size="14">{principalKpp}</Typography.Text>
                  </Flex>
                  <Flex gap="4" align="center">
                    <Typography.Title level={5}>{t('common_ogrn')}: </Typography.Title>
                    <Typography.Text size="14">{principalOgrn}</Typography.Text>
                  </Flex>
                  {/* TODO: when BE ready */}
                  {/*<Flex gap="4" align="center">*/}
                  {/*  <Typography.Title level={5}>{t('common_legal_address')}: </Typography.Title>*/}
                  {/*  <Typography.Text size="14">-</Typography.Text>*/}
                  {/*</Flex>*/}
                  {/*<Flex gap="4" align="center">*/}
                  {/*  <Typography.Title level={5}>{t('common_phone')}: </Typography.Title>*/}
                  {/*  <Typography.Text size="14">-</Typography.Text>*/}
                  {/*</Flex>*/}
                  {/*<Flex gap="4" align="center">*/}
                  {/*  <Typography.Title level={5}>*/}
                  {/*    {t('documents_machine_authorities_doc_name')}:{' '}*/}
                  {/*  </Typography.Title>*/}
                  {/*  <Typography.Text size="14">-</Typography.Text>*/}
                  {/*</Flex>*/}
                  {/*<Flex gap="4" align="center">*/}
                  {/*  <Typography.Title level={5}>{t('common_signatory')}: </Typography.Title>*/}
                  {/*  <Typography.Text size="14">-</Typography.Text>*/}
                  {/*</Flex>*/}
                </Flex>
              </Flex>
              <Flex vertical gap="16">
                <Flex gap="8" align="center">
                  <Icon name="accountCircle" color="var(--color-primary-6)" />
                  <Typography.Title level={4}>
                    {t('documents_machine_authorities_authorized')}
                  </Typography.Title>
                </Flex>
                <Flex vertical>
                  <Typography.Title level={5}>
                    {`${representativeSurname} ${representativeName} ${representativeMiddleName}`}
                  </Typography.Title>
                  <Flex gap="4" align="center">
                    <Typography.Title level={5}>{t('common_inn_fl')}: </Typography.Title>
                    <Typography.Text size="14">{representativeInnFl}</Typography.Text>
                  </Flex>
                  <Flex gap="4" align="center">
                    <Typography.Title level={5}>{t('common_snils')}: </Typography.Title>
                    <Typography.Text size="14">{representativeSnils}</Typography.Text>
                  </Flex>
                  {/* TODO: when BE ready */}
                  {/*<Flex gap="4" align="center">*/}
                  {/*  <Typography.Title level={5}>{t('common_birth_date')}: </Typography.Title>*/}
                  {/*  <Typography.Text size="14">-</Typography.Text>*/}
                  {/*</Flex>*/}
                  {/*<Flex gap="4" align="center">*/}
                  {/*  <Typography.Title level={5}>{t('common_citizenship')}: </Typography.Title>*/}
                  {/*  <Typography.Text size="14">-</Typography.Text>*/}
                  {/*</Flex>*/}
                </Flex>
              </Flex>
              <Flex align="center" gap="8" fullWidth={false}>
                <Switch
                  value={isActive}
                  onChange={handleKedoChange}
                  loading={isActivateLoading || isDeactivateLoading}
                />
                <Typography.Text>
                  {t('documents_machine_authorities_kedo_available')}
                </Typography.Text>
              </Flex>
            </Flex>
          </DocumentView.Content>
          <Flex align="center" gap="8">
            <Button type="secondary" className={s.closeButton} onClick={handleGoBack} fullWidth>
              {t('common_close')}
            </Button>
          </Flex>
        </DocumentView>
      </WithLoader>
    </WithOverflow>
  );
};

export default DocumentsMachineAuthoritiesDetails;
