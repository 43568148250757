import { EmployeeType } from './employee.types';
import { RoleType } from './roles.types';

export type RolesPersonType = {
  email: string;
  employers: { id: string; name: string }[];
  firstName: string;
  id: string;
  isAdmin: boolean;
  lastName: string;
  patronymic: string;
  phone: string;
  fioGenitive: string;
  refreshCode: boolean;
};

export type RolesPersonFullType = {
  phone: string;
  email: string;
  ssoRegistered: boolean;
  confirm: boolean;
  active: boolean;
  id: string;
  lastName: string;
  patronymic: string;
  firstName: string;
  updatedAt: string;
  employees: EmployeeType[];
  roles: RoleType[];
  fioGenitive: string;
  refreshCode: boolean;
};

export type PersonType = {
  email: string;
  middleName: string;
  surname: string;
  name: string;
  personId: string;
  employers?: {
    id: string;
    name: string;
  }[];
};

export type CPersonType = {
  active: boolean;
  birthDate: string;
  createDate: string;
  email: string;
  firstName: string;
  gender: string;
  id: string;
  informationAddress: string;
  inn: string;
  lastName: string;
  patronymic: string;
  phone: string;
  residentialAddress: string;
  snilsHash: null | string;
  updateDate: null | string;
  validFrom: null | string;
  validTill: null | string;
};

export type KedoPersonType = {
  id: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  ssoRegistered: boolean;
};

export enum REFRESH_CODE_TYPE {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
}
