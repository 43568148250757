import { Dispatch, SetStateAction } from 'react';

import { t } from 'tools/i18n';

import { Flex } from 'gazprom-ui-lib';

import SegmentedButtons from 'components/segmented-buttons';
import FiltersContainer from 'containers/filters';
import FiltersV2 from 'containers/filters-v2';
import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterCollapse from 'containers/filters/filter-collapse';
import FilterCustomWrapper from 'containers/filters/filter-custom-wrapper';
import FilterHeader from 'containers/filters/filter-header';

import { SUGGESTED_TAB_SEGMENTS, SuggestedTabSegments } from '../../suggestedTab.utils';
import s from './Filters.module.scss';
import { COMMUNITY_SUGGESTED_STATUSES } from './filters.utils';
import { useFilterItems } from './useFilterItems.hook';

interface Props {
  activeSegment: SuggestedTabSegments;
  setActiveSegment: Dispatch<SetStateAction<SuggestedTabSegments>>;
}

const Filters = (props: Props) => {
  const { activeSegment, setActiveSegment } = props;
  const filterItems = useFilterItems();
  return (
    <FiltersV2.TableFilters>
      <Flex justify="space-between" align="center" fullWidth>
        <Flex gap="32" align="center">
          <SegmentedButtons
            segments={SUGGESTED_TAB_SEGMENTS}
            activeSegment={activeSegment}
            handleSegmentChange={setActiveSegment}
            className={s.segmentButton}
            flexProps={{ className: s.segmentedWrapper }}
            buttonProps={{ onlyIcon: true }}
            inactiveSegmentType="text"
          />
          <FiltersV2.Search />
        </Flex>
        <FiltersContainer
          showClearAll
          overlay={
            <>
              <FilterCollapse items={filterItems} />

              <FilterCustomWrapper>
                <FilterHeader valueKey="statuses">{t('common_status')}</FilterHeader>
                <FilterCheckboxGroup
                  usePadding={false}
                  valueKey="statuses"
                  options={COMMUNITY_SUGGESTED_STATUSES}
                />
              </FilterCustomWrapper>
            </>
          }
        />
      </Flex>
    </FiltersV2.TableFilters>
  );
};

export default Filters;
