import { MediaType } from './media.types';

export type CommunityMeetCreateOrUpdateType = CommunityMeetBase & {
  categories: string[]; // Категории статьи (UUIDs)
  cover: { link: string };
  status?: COMMUNITY_ENTITY_STATUS; // Статус статьи
};

export type CommunityMeetType = CommunityMeetBase & {
  id: string; // Уникальный идентификатор статьи
  communityId: string; // Уникальный идентификатор сообщества
  type: 'NEWS' | 'MEETING'; // Тип статьи
  sourceType: COMMUNITY_ENTITY_SOURCE_TYPE; // Источник статьи
  commentCount: number; // Количество комментариев
  viewCount: number; // Количество просмотров
  likeCount: number; // Количество лайков
  creator: EmployeeExtendedInfoType; // Уникальный идентификатор создателя статьи
  editor: EmployeeExtendedInfoType; // Уникальный идентификатор редактора статьи
  createdAt: string; // Дата создания статьи (ISO)
  updatedAt: string; // Дата последнего обновления статьи (ISO)
  categories: IdNameCategory[]; // Список категорий
  countMembers: number; // Количество участников
  cover: MediaType; // Обложка к новости
  status: COMMUNITY_ENTITY_STATUS; // Статус статьи
  code: string; // Код статьи
};

type CommunityMeetBase = {
  repeatEvery?: COMMUNITY_MEET_REPEAT_INTERVAL; // Периодичность
  weekDays?: { days: COMMUNITY_MEET_WEEK_DAYS[] }; // Настройки периодичности
  meetDate: string; // Дата мероприятия (ISO)
  city: string; // Город
  location: string; // Локация
  requiresDocs: boolean; // Требуются ли документы
  maxEmployees?: number; // Максимум участников
  registrationActive: boolean; // Регистрация активна
  isSpecial: boolean; // Признак важной даты мероприятия
  title: string; // Заголовок статьи
  text: string; // Текст статьи
  media?: MediaType[]; // Медиа данные к статье
  publishFrom?: string; // Начало публикации (ISO)
  publishTill?: string; // Конец публикации (ISO)
  isPublish: boolean; // Флаг публикации
};

export enum COMMUNITY_ENTITY_SOURCE_TYPE {
  MOBILE = 'MOBILE',
  MODERATOR = 'MODERATOR',
}

export enum COMMUNITY_ENTITY_STATUS {
  MODERATION = 'MODERATION',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum COMMUNITY_MEET_REPEAT_INTERVAL {
  EVERY_DAY = 'EVERY_DAY',
  EVERY_YEAR = 'EVERY_YEAR',
  EVERY_MONTH = 'EVERY_MONTH',
  EVERY_WEEK = 'EVERY_WEEK',
}

export enum COMMUNITY_MEET_WEEK_DAYS {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

type IdNameCategory = {
  id: string; // UUID
  name: string; // Название категории
};

export type CommunityMeetParticipantType = {
  personId: string; // UUID
  lastName: string;
  firstName: string;
  patronymic: string;
  phone: string;
  email: string;
};

export type EmployeeExtendedInfoType = {
  id: string; // UUID of employment record
  employmentType: string; // Тип занятости
  person: PersonFioType;
  employer: EmployerNameType;
  division: DivisionNameType;
  position: PositionNameType;
  staff: StaffNameType;
};

type PersonFioType = {
  id: string; // UUID of person
  lastName: string; // Фамилия человека
  firstName: string; // Имя человека
  patronymic: string; // Отчество человека
};

type EmployerNameType = {
  id: string; // UUID of employer
  name: string; // Название работодателя
};

type DivisionNameType = {
  id: string; // UUID of division
  name: string; // Название подразделения
};

type PositionNameType = {
  id: string; // UUID of position
  name: string; // Название должности
};

type StaffNameType = {
  id: string; // UUID of staff unit
  name: string; // Название штатной единицы
};
