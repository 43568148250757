import { t } from 'tools/i18n';

import FilterCheckboxGroup from 'containers/filters/filter-checkbox-group';
import FilterHeader from 'containers/filters/filter-header';

import { useGetFiltersData } from './useGetFiltersData.hook';

export const useFilterItems = () => {
  const { subscribersEmployers } = useGetFiltersData();

  return [
    {
      key: 'employerIds',
      label: <FilterHeader valueKey="employerIds">{t('common_organization')}</FilterHeader>,
      children: (
        <FilterCheckboxGroup
          valueKey="employerIds"
          optionFilterProp="label"
          showSearch
          options={subscribersEmployers}
          searchProps={{
            placeholder: t('common_search'),
          }}
        />
      ),
    },
  ];
};
