export const AVAILABLE_DOCUMENT_TYPES_ENUM = {
  ADD_CONTRACT: 'ADD_CONTRACT',
  SIGN_PERSON: 'SIGN_PERSON',
  SIGN_TWO_SIDES: 'SIGN_TWO_SIDES',
  PERS_DATA_AGREEMENT: 'PERS_DATA_AGREEMENT',
};

export const DOCUMENT_TYPES = [
  {
    value: AVAILABLE_DOCUMENT_TYPES_ENUM.ADD_CONTRACT,
    label: 'Дополнительное соглашение',
  },
  {
    value: AVAILABLE_DOCUMENT_TYPES_ENUM.SIGN_PERSON,
    label: 'Документ на подпись сотрудником',
  },
  {
    value: AVAILABLE_DOCUMENT_TYPES_ENUM.SIGN_TWO_SIDES,
    label: 'Документ на подпись организацией и сотрудником',
  },
  {
    value: AVAILABLE_DOCUMENT_TYPES_ENUM.PERS_DATA_AGREEMENT,
    label: 'Согласие на обработку персональных данных',
  },
];

export enum ADDITIONAL_DOCUMENTS_ENUM {
  NAME = 'name',
  DOC_NUMBER = 'docNumber',
  DOC_DATE = 'docDate',
  DOC_TYPE = 'docType',
  SGNER_ID = 'signerId',
  FILE = 'file',
  KEDO_DOCUMENT_UUID = 'kedoDocumentUuid',
}

export type AddDocumentFormResponse = {
  [ADDITIONAL_DOCUMENTS_ENUM.NAME]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.DOC_NUMBER]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.DOC_DATE]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.DOC_TYPE]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.SGNER_ID]: string;
  [ADDITIONAL_DOCUMENTS_ENUM.FILE]: {
    name: string;
    link: string;
    size: number;
  };
  [ADDITIONAL_DOCUMENTS_ENUM.KEDO_DOCUMENT_UUID]: string;
};
