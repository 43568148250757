import { useState } from 'react';

import { t } from 'tools/i18n';
import { useDebounce } from 'utils/useDebounce.hook';

import { Flex, Icon, Typography } from 'gazprom-ui-lib';

import MultiTagsSelect from 'components/form/multi-tags-select';

import { SURVEY_SETTLEMENT_FORM_ENUM } from '../../surveySettlementForm.utils';
import { useGetFormData } from '../../useGetFormData.hook';
import s from './Settlement.module.scss';

const Settlements = () => {
  const [search, setSearch] = useState('');
  const { debouncedValue: debouncedSearch } = useDebounce(search);
  const { settlementOptions } = useGetFormData({
    ...(debouncedSearch && { settlementName: debouncedSearch }),
  });

  return (
    <Flex vertical gap="8" className={s.formItemMargin}>
      <Flex gap="4" align="center">
        <Typography.Text size="16" weight="500">
          {t('common_settlement')}
        </Typography.Text>
        <Typography.Text type="secondary">{t('common_optional')}</Typography.Text>
      </Flex>

      <MultiTagsSelect
        isRequired={false}
        onSearch={setSearch}
        searchValue={search}
        className={s.noMargin}
        title=""
        placeholder={t('common_select_second')}
        name={SURVEY_SETTLEMENT_FORM_ENUM.SETTLEMENTS}
        options={settlementOptions}
      />

      <Flex gap="8" align="center">
        <Icon name="info" color="var(--color-primary-6)" />
        <Typography.Text type="secondary">
          {t('surveys_settlements_field_subtitle')}
        </Typography.Text>
      </Flex>
    </Flex>
  );
};

export default Settlements;
