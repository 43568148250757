import { t } from 'tools/i18n';

import { Button, ModalDefault } from 'gazprom-ui-lib';

import { useGetSurvey } from 'pages/surveys/surveys-settlement/surveys-settlement-add-update/useGetSurvey.hook';

interface Props {
  open: boolean;
  onCancel: () => void;
  onActionClick: () => void;
  isLoading: boolean;
}

const PublishModal = (props: Props) => {
  const { open, onActionClick, onCancel, isLoading } = props;
  const { survey } = useGetSurvey();

  const isPublishModal = survey?.isPublish ? true : false;

  const title = t(isPublishModal ? 'surveys_recall_title' : 'common_confirm_publish');
  const description = t(
    isPublishModal ? 'surveys_ubpublish_description' : 'common_confirm_publish_description',
  );

  return (
    <ModalDefault open={open} onCancel={onCancel} width={320} closable>
      <ModalDefault.Icon type="danger" name="error" />
      <ModalDefault.Title>{title}</ModalDefault.Title>
      <ModalDefault.Description>{description}</ModalDefault.Description>
      <ModalDefault.Actions>
        <Button
          loading={isLoading}
          disabled={isLoading}
          fullWidth
          type="primary"
          onClick={onActionClick}>
          {t('common_accept')}
        </Button>
      </ModalDefault.Actions>
    </ModalDefault>
  );
};

export default PublishModal;
