import { useState } from 'react';

import { skipToken } from '@reduxjs/toolkit/query';
import { useNavigate, useParams } from 'react-router-dom';
import { SALARY_PROJECT_ROUTE } from 'routes/salary-project/list';
import { t } from 'tools/i18n';

import {
  useDeleteSalaryProjectMutation,
  useGetSalaryProjectByIdQuery,
  useUpdateSalaryProjectMutation,
} from 'services/private/privateApiService';
import { UpdateSalaryProjectsPropsType } from 'services/private/privateApiService.types';

import { Button, Flex, ModalDefault, handleRequest } from 'gazprom-ui-lib';

import ContentHeader from 'containers/content-header';
import SalaryProjectForm from 'containers/salary-project-form';
import {
  FORM_ENUM,
  FormResponseType,
  SALARY_PROJECT_FORM,
} from 'containers/salary-project-form/salaryProjectForm.utils';
import WithLoader from 'containers/wrappers/with-loader';
import WithOverflow from 'containers/wrappers/with-overflow';

import s from './SalaryProjectDetails.module.scss';

const SalaryProjectDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const { data, isFetching } = useGetSalaryProjectByIdQuery(id ?? skipToken);
  const [update, { isLoading: updateIsLoading }] = useUpdateSalaryProjectMutation();
  const [deleteProject, { isLoading: deleteIsLoading }] = useDeleteSalaryProjectMutation();

  const handleSubmit = (formResponse: FormResponseType) => {
    if (id) {
      const body: UpdateSalaryProjectsPropsType = {
        id,
        name: formResponse[FORM_ENUM.NAME],
        bannerImg: formResponse[FORM_ENUM.COVER],
        description: formResponse[FORM_ENUM.DESCRIPTION],
        employerId: formResponse[FORM_ENUM.ORG],
        kedoStatementId: formResponse[FORM_ENUM.STATEMENT],
        sort: Number(formResponse[FORM_ENUM.SORT]),
        isPublish: !!formResponse[FORM_ENUM.PUBLISHED],
      };

      if (formResponse[FORM_ENUM.LINK]) {
        body.tariffLink = formResponse[FORM_ENUM.LINK];
      }

      update(body).then(
        handleRequest({
          onSuccess: () => navigate(SALARY_PROJECT_ROUTE),
        }),
      );
    }
  };

  const handleOpenCloseDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const handleDelete = () => {
    if (id) {
      deleteProject(id).then(
        handleRequest({
          onSuccess: () => navigate(SALARY_PROJECT_ROUTE),
        }),
      );
    }
  };

  const isLoading = updateIsLoading || deleteIsLoading;

  return (
    <WithOverflow>
      <ContentHeader showBorder align="flex-start">
        <ContentHeader.Title goBackLink={SALARY_PROJECT_ROUTE}>
          {t('salary_project_add')}
        </ContentHeader.Title>
        <ContentHeader.Actions>
          <Button
            leftIcon="ok"
            htmlType="submit"
            form={SALARY_PROJECT_FORM}
            loading={updateIsLoading}>
            {t('common_save')}
          </Button>
        </ContentHeader.Actions>
      </ContentHeader>
      <WithLoader isLoading={isFetching}>
        <Flex vertical fullWidth className={s.formWrapper}>
          <SalaryProjectForm
            handleSubmit={handleSubmit}
            handleDelete={handleOpenCloseDeleteModal}
            loading={isLoading}
            initialData={data}
          />
        </Flex>
      </WithLoader>
      <ModalDefault
        open={showDeleteModal}
        onCancel={handleOpenCloseDeleteModal}
        width={320}
        closable>
        <ModalDefault.Icon name="bin" type="danger" />
        <ModalDefault.Title>{t('common_accept_remove')}</ModalDefault.Title>
        <ModalDefault.Description>
          {t('salary_project_delete_description')}
        </ModalDefault.Description>
        <ModalDefault.Actions>
          <Button danger ghost fullWidth leftIcon="bin" loading={isLoading} onClick={handleDelete}>
            {t('common_delete')}
          </Button>
        </ModalDefault.Actions>
      </ModalDefault>
    </WithOverflow>
  );
};

export default SalaryProjectDetails;
