import { useState } from 'react';

import cn from 'classnames';
import { t } from 'tools/i18n';
import downloadHandler from 'utils/downloadHandler';

import { Button } from 'gazprom-ui-lib';

import s from './FileDownloadButton.module.scss';

interface Props {
  archiveUrl: string;
  shouldDisableButton: boolean;
  addSpace: boolean;
}

const FileDownloadButton = (props: Props) => {
  const { archiveUrl, shouldDisableButton, addSpace } = props;
  const [isDownloading, setIsDownloading] = useState(false);

  const handleFileDownload = async (archiveUrl: string) => {
    setIsDownloading(true);
    downloadHandler({
      url: archiveUrl,
      fileName: t('common_archive_name_fallback'),
      headersFileNameKey: 'filename',
    }).finally(() => {
      setIsDownloading(false);
    });
  };

  return (
    <Button
      loading={isDownloading}
      onClick={() => handleFileDownload(archiveUrl)}
      disabled={shouldDisableButton}
      leftIcon="download"
      type="secondary"
      className={cn(s.archiveButton, { [s.space]: addSpace })}>
      {t('common_download_container')}
    </Button>
  );
};

export default FileDownloadButton;
