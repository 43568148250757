import { t } from 'tools/i18n';

import { Button, Checkbox, Icon, Modal, Typography } from 'gazprom-ui-lib';

import useCInstanceConnection from './cInstanceSynchronization.hook';
import s from './cInstanceSynchronizationModal.module.scss';

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  syncTypes: string[];
  instanceId: string;
}

const CInstanceSynchronizationModal = (props: Props) => {
  const { isOpen, onCancel, instanceId, syncTypes } = props;

  const {
    syncTypeOptions,
    selectedConnectionTypes,
    handleConnectionTypeSelection,
    handleSubmit,
    isLoading,
  } = useCInstanceConnection({
    instanceId,
    syncTypes,
    onCancel,
  });

  return (
    <Modal open={isOpen} onCancel={onCancel} width={320}>
      <div className={s.container}>
        <button className={s.close} onClick={onCancel}>
          <Icon name="delete" size={14} />
        </button>
        <div className={s.icon}>
          <Icon name="importStart" size={40} color="var(--color-neutral-1)" />
        </div>
        <div className={s.text}>
          <Typography.Title level={3}>{t('1c_instance_start_sync')}</Typography.Title>
          <Typography.Text size="16" weight="400" type="secondary">
            {t('1c_instance_select_sync_type')}
          </Typography.Text>
        </div>

        <Checkbox.Group
          className={s.checkboxGroup}
          column
          value={selectedConnectionTypes}
          onChange={handleConnectionTypeSelection}
          options={syncTypeOptions}></Checkbox.Group>

        <Button
          className={s.startButton}
          disabled={!selectedConnectionTypes.length}
          leftIcon="web"
          fullWidth
          type="primary"
          loading={isLoading}
          onClick={handleSubmit}>
          {t('common_start')}
        </Button>
      </div>
    </Modal>
  );
};

export default CInstanceSynchronizationModal;
