import dayjs from 'dayjs';
import { t } from 'tools/i18n';

import { Icon, Typography } from 'gazprom-ui-lib';

import s from './CreationInfo.module.scss';

interface Props {
  createdDate?: string;
  createdBy?: string;
}

const CreationInfo = (props: Props) => {
  const { createdDate, createdBy } = props;

  if (!createdBy && !createdDate) {
    return <></>;
  }

  const formattedDate = dayjs.utc(createdDate).local().format('DD.MM.YYYY, HH:mm');

  const hasCreatedByInfo = !!createdBy?.trim().length;

  return (
    <div className={s.wrapper}>
      <div className={s.infoWrapper}>
        <Icon name="calendar" />
        <div className={s.infoText}>
          <Typography.Text type="secondary" size="14">
            {t('common_created')}:
          </Typography.Text>
          <Typography.Text type="secondary" size="14">
            {formattedDate}
          </Typography.Text>
        </div>
      </div>
      {hasCreatedByInfo && (
        <div className={s.infoWrapper}>
          <Icon name="accountCircle" size={16} />
          <div className={s.infoText}>
            <Typography.Text type="secondary" size="14">
              {t('common_author')}:
            </Typography.Text>
            <Typography.Text type="secondary" size="14">
              {createdBy}
            </Typography.Text>
          </div>
        </div>
      )}
    </div>
  );
};

export default CreationInfo;
