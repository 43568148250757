import { Flex } from 'gazprom-ui-lib';

import FiltersV2 from 'containers/filters-v2';

import PositionSelect from '../position-select';
import s from './Filters.module.scss';

interface Props {
  employerId?: string;
}

const Filters = (props: Props) => {
  const { employerId } = props;

  return (
    <FiltersV2.TableFilters>
      <Flex className={s.wrapper} align="center" justify="space-between">
        <FiltersV2.Search />
        <PositionSelect employerId={employerId} />
      </Flex>
    </FiltersV2.TableFilters>
  );
};

export default Filters;
