import { useParams } from 'react-router-dom';

import { useGetSurveySettlementByIdQuery } from 'services/surveys/surveysApiService';

export const useGetSurvey = () => {
  const { id: surveyId } = useParams<{ id: string }>();

  const { data: survey, isLoading } = useGetSurveySettlementByIdQuery(
    {
      surveyId: surveyId!,
    },
    { skip: !surveyId },
  );

  const revisionId = survey?.revisionId;

  return { survey, isLoading, revisionId };
};
